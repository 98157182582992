import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {
  CurrencyNumberFormat,
  getAge,
  getDateFromYYYYMMDD,
  validateSSN,
} from 'src/common/utils';
import {
  ALL_FORM_TYPES,
  CPA_CENTER_ACTIVE_YEAR,
  TaxProfileSubsection,
} from 'src/constants/constants';
import {TaxForm} from 'src/store/taxForms/taxForms.reducer';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {IRSCategory} from 'src/store/transactions/transactions.constants';
import {selectDeductionSummary} from 'src/store/deductionSummary/deductionSummary.selector';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.constants';
import {
  CHILD_RELATIONS,
  DEPENDENT_CREDITS_OPTIONS,
  DEPENDENT_CREDITS_QUESTION_TYPE,
  PARENT_RELATIONS,
  RELATIVE_RELATIONS,
} from '../Credits.constants';
import {selectTaxProfileUserSideStatus} from 'src/store/taxProfileStatus/taxProfileStatus.selector';
import {TaxProfileUserSideStatus} from 'src/store/taxProfileStatus/taxProfileStatus.reducer';

export const useDependentCredits = () => {
  const {loaded: taxFormsLoaded, taxForms} = useSelector(
    selectTaxFormsReducer,
  );

  const {taxProfileUserSideStatus, loaded: taxProfileStatusLoaded} =
    useSelector(selectTaxProfileUserSideStatus);

  const isLoading = !taxFormsLoaded || !taxProfileStatusLoaded;
  const showDependentCredits =
    taxProfileStatusLoaded &&
    ![
      TaxProfileUserSideStatus.NOT_APPLICABLE,
      TaxProfileUserSideStatus.NOT_INITIATED,
    ].includes(
      taxProfileUserSideStatus[TaxProfileSubsection.DependentCredits]
        ?.status ?? TaxProfileUserSideStatus.NOT_APPLICABLE,
    );

  const dependentsTaxForm = showDependentCredits
    ? taxForms.filter((tf) => tf.formType === ALL_FORM_TYPES.DEPENDENT)
    : [];

  const deductionSummary = useSelector(selectDeductionSummary);

  const childCareExpense = useMemo(() => {
    return deductionSummary.sch_3_category_wise_data
      .filter(
        ({category_display_name}) =>
          category_display_name === IRSCategory.ChildAndDependentCare,
      )
      .reduce((prev, deduction) => {
        return prev + (deduction?.amount ?? 0);
      }, 0);
  }, [deductionSummary]);

  // assumes dependent details are not pending
  const isDependentCreditEligible = (dependent: TaxForm) => {
    return dependent.formData[TaxFormFieldIds.DEPENDENT_US_CITIZEN];
  };

  // assumes isDepedentEligible is giving true for the dependent
  const isChildTaxCreditEligible = (dependentData: TaxForm, age: number) => {
    return (
      CHILD_RELATIONS.includes(
        dependentData.formData[TaxFormFieldIds.DEPENDENT_RELATION] ?? '',
      ) &&
      age <= 13 &&
      validateSSN(dependentData.formData[TaxFormFieldIds.DEPENDENT_SSN]) &&
      dependentData.formData[TaxFormFieldIds.MORE_THAN_HALF_YEAR_SUPPORT]
    );
  };

  // assumes isDepedentEligible is giving true for the dependent
  const isChildCareCreditEligible = (dependentData: TaxForm, age: number) => {
    return (
      CHILD_RELATIONS.includes(
        dependentData.formData[TaxFormFieldIds.DEPENDENT_RELATION] ?? '',
      ) &&
      age <= 17 &&
      validateSSN(dependentData.formData[TaxFormFieldIds.DEPENDENT_SSN]) &&
      dependentData.formData[TaxFormFieldIds.MORE_THAN_HALF_YEAR_SUPPORT]
    );
  };

  // assumes isDepedentEligible is giving true for the dependent
  const isOtherDependentCreditEligible = (
    dependentData: TaxForm,
    age: number,
  ) => {
    if (
      CHILD_RELATIONS.includes(
        dependentData.formData[TaxFormFieldIds.DEPENDENT_RELATION] ?? '',
      )
    ) {
      if (
        age <= 17 &&
        dependentData.formData[TaxFormFieldIds.MORE_THAN_HALF_YEAR_SUPPORT]
      ) {
        return true;
      }
      if (
        age <= 19 &&
        dependentData.formData[TaxFormFieldIds.MORE_THAN_HALF_YEAR_SUPPORT] &&
        !dependentData.formData[
          TaxFormFieldIds.IS_FILING_JOINT_TAX_RETURN_THIS_YEAR
        ]
      ) {
        return true;
      }
      if (
        age < 24 &&
        dependentData.formData[TaxFormFieldIds.MORE_THAN_HALF_YEAR_SUPPORT] &&
        !dependentData.formData[
          TaxFormFieldIds.IS_FILING_JOINT_TAX_RETURN_THIS_YEAR
        ] &&
        dependentData.formData[TaxFormFieldIds.IS_FULL_TIME_STUDENT] &&
        !dependentData.formData[TaxFormFieldIds.DID_EARN_MORE_THAN_4700]
      ) {
        return true;
      }
      if (
        dependentData.formData[TaxFormFieldIds.MORE_THAN_HALF_YEAR_SUPPORT] &&
        !dependentData.formData[
          TaxFormFieldIds.IS_FILING_JOINT_TAX_RETURN_THIS_YEAR
        ] &&
        !dependentData.formData[TaxFormFieldIds.DID_EARN_MORE_THAN_4700]
      ) {
        return true;
      }

      return false;
    }

    if (
      (PARENT_RELATIONS.includes(
        dependentData.formData[TaxFormFieldIds.DEPENDENT_RELATION] ?? '',
      ) ||
        RELATIVE_RELATIONS.includes(
          dependentData.formData[TaxFormFieldIds.DEPENDENT_RELATION] ?? '',
        )) &&
      dependentData.formData[TaxFormFieldIds.MORE_THAN_HALF_YEAR_SUPPORT] &&
      !dependentData.formData[
        TaxFormFieldIds.IS_FILING_JOINT_TAX_RETURN_THIS_YEAR
      ] &&
      !dependentData.formData[TaxFormFieldIds.DID_EARN_MORE_THAN_4700]
    ) {
      return true;
    }

    if (
      dependentData.formData[
        TaxFormFieldIds.LIVED_WITH_TAX_PAYER_FOR_WHOLE_YEAR
      ] &&
      !dependentData.formData[
        TaxFormFieldIds.IS_FILING_JOINT_TAX_RETURN_THIS_YEAR
      ] &&
      !dependentData.formData[TaxFormFieldIds.DID_EARN_MORE_THAN_4700]
    ) {
      return true;
    }
  };

  // assumes dependent details are not pending
  const getEligibleDependentCredit = (dependentData: TaxForm) => {
    if (!isDependentCreditEligible(dependentData)) {
      return DEPENDENT_CREDITS_OPTIONS.NONE;
    }

    const age = getAge(
      getDateFromYYYYMMDD(
        dependentData.formData[TaxFormFieldIds.DEPENDENT_BIRTHDATE],
      ),
    );

    if (isChildTaxCreditEligible(dependentData, age)) {
      return DEPENDENT_CREDITS_OPTIONS.CHILD_CARE_AND_CHILD_TAX_CREDIT;
    }
    if (isChildCareCreditEligible(dependentData, age)) {
      return DEPENDENT_CREDITS_OPTIONS.ONLY_CHILD_TAX_CREDIT;
    }
    if (isOtherDependentCreditEligible(dependentData, age)) {
      return DEPENDENT_CREDITS_OPTIONS.ONLY_OTHER_DEPENDENT_CREDIT;
    }

    return DEPENDENT_CREDITS_OPTIONS.NONE;
  };

  const getDependentQuestionType = (dependent: TaxForm, age: number) => {
    if (
      CHILD_RELATIONS.includes(
        dependent.formData[TaxFormFieldIds.DEPENDENT_RELATION] ?? '',
      )
    ) {
      if (
        age <= 17 &&
        validateSSN(dependent.formData[TaxFormFieldIds.DEPENDENT_SSN])
      ) {
        return DEPENDENT_CREDITS_QUESTION_TYPE.CHILD_LESS_THAN_17;
      }

      if (age <= 19) {
        return DEPENDENT_CREDITS_QUESTION_TYPE.CHILD_LESS_THAN_19;
      }

      if (age <= 24) {
        return DEPENDENT_CREDITS_QUESTION_TYPE.CHILD_LESS_THAN_24;
      }

      return DEPENDENT_CREDITS_QUESTION_TYPE.CHILD;
    }

    if (
      PARENT_RELATIONS.includes(
        dependent.formData[TaxFormFieldIds.DEPENDENT_RELATION] ?? '',
      )
    ) {
      return DEPENDENT_CREDITS_QUESTION_TYPE.PARENT;
    }

    if (
      RELATIVE_RELATIONS.includes(
        dependent.formData[TaxFormFieldIds.DEPENDENT_RELATION] ?? '',
      )
    ) {
      return DEPENDENT_CREDITS_QUESTION_TYPE.RELATIVE;
    }

    return DEPENDENT_CREDITS_QUESTION_TYPE.OTHER_DEPENDENT;
  };

  const getDepedentCreditQuestions = (
    dependentQuestionType: keyof typeof DEPENDENT_CREDITS_QUESTION_TYPE,
    dependentName: string,
    year: number,
  ) => {
    switch (dependentQuestionType) {
      case DEPENDENT_CREDITS_QUESTION_TYPE.CHILD_LESS_THAN_17:
        return [
          {
            questionId: TaxFormFieldIds.MORE_THAN_HALF_YEAR_SUPPORT,
            title: `Did ${dependentName} live with you or received your support while in school/college for at least 6 months?`,
          },
        ];
      case DEPENDENT_CREDITS_QUESTION_TYPE.CHILD_LESS_THAN_19:
        return [
          {
            questionId: TaxFormFieldIds.MORE_THAN_HALF_YEAR_SUPPORT,
            title: `Did ${dependentName} live with you or received your support while in school/college for at least 6 months?`,
          },
          {
            questionId: TaxFormFieldIds.IS_FILING_JOINT_TAX_RETURN_THIS_YEAR,
            title: `Is ${dependentName} married & filing a joint tax return this year?`,
          },
        ];
      case DEPENDENT_CREDITS_QUESTION_TYPE.CHILD_LESS_THAN_24:
        return [
          {
            questionId: TaxFormFieldIds.MORE_THAN_HALF_YEAR_SUPPORT,
            title: `Did ${dependentName} live with you or received your support while in school/college for at least 6 months?`,
          },
          {
            questionId: TaxFormFieldIds.IS_FILING_JOINT_TAX_RETURN_THIS_YEAR,
            title: `Is ${dependentName} married & filing a joint tax return this year?`,
          },
          {
            questionId: TaxFormFieldIds.IS_FULL_TIME_STUDENT,
            title: `Was ${dependentName} a full time student in ${year}?`,
          },
          {
            questionId: TaxFormFieldIds.DID_EARN_MORE_THAN_4700,
            title: `Did ${dependentName} earn more than $4700 in ${year}?`,
          },
        ];
      case DEPENDENT_CREDITS_QUESTION_TYPE.CHILD:
        return [
          {
            questionId: TaxFormFieldIds.MORE_THAN_HALF_YEAR_SUPPORT,
            title: `Did ${dependentName} live in your house for at least 6 months?`,
          },
          {
            questionId: TaxFormFieldIds.IS_FILING_JOINT_TAX_RETURN_THIS_YEAR,
            title: `Is ${dependentName} married & filing a joint tax return this year?`,
          },
          {
            questionId: TaxFormFieldIds.DID_EARN_MORE_THAN_4700,
            title: `Did ${dependentName} earn more than $4700 in ${year}?`,
          },
        ];
      case DEPENDENT_CREDITS_QUESTION_TYPE.PARENT:
        return [
          {
            questionId: TaxFormFieldIds.MORE_THAN_HALF_YEAR_SUPPORT,
            title: `Did ${dependentName} live in your house or a care facility paid by you for a minimum of 6 months?`,
          },
          {
            questionId: TaxFormFieldIds.IS_FILING_JOINT_TAX_RETURN_THIS_YEAR,
            title: `Is ${dependentName} married & filing a joint tax return this year?`,
          },
          {
            questionId: TaxFormFieldIds.DID_EARN_MORE_THAN_4700,
            title: `Did ${dependentName} earn more than $4700 in ${year}?`,
          },
        ];
      case DEPENDENT_CREDITS_QUESTION_TYPE.RELATIVE:
        return [
          {
            questionId: TaxFormFieldIds.MORE_THAN_HALF_YEAR_SUPPORT,
            title: `Did ${dependentName} live in your house for a minimum of 6 months?`,
          },
          {
            questionId: TaxFormFieldIds.IS_FILING_JOINT_TAX_RETURN_THIS_YEAR,
            title: `Is ${dependentName} married & filing a joint tax return this year?`,
          },
          {
            questionId: TaxFormFieldIds.DID_EARN_MORE_THAN_4700,
            title: `Did ${dependentName} earn more than $4700 in ${year}?`,
          },
        ];
      case DEPENDENT_CREDITS_QUESTION_TYPE.OTHER_DEPENDENT:
        return [
          {
            questionId: TaxFormFieldIds.LIVED_WITH_TAX_PAYER_FOR_WHOLE_YEAR,
            title: `Did ${dependentName} stay with you all of ${year}?`,
          },
          {
            questionId: TaxFormFieldIds.IS_FILING_JOINT_TAX_RETURN_THIS_YEAR,
            title: `Is ${dependentName} married & filing a joint tax return this year?`,
          },
          {
            questionId: TaxFormFieldIds.DID_EARN_MORE_THAN_4700,
            title: `Did ${dependentName} earn more than $4700 in ${year}?`,
          },
        ];
    }
  };

  const getDependentCreditQuestionsToShow = (dependent: TaxForm) => {
    const age = getAge(
      getDateFromYYYYMMDD(
        dependent.formData[TaxFormFieldIds.DEPENDENT_BIRTHDATE],
      ),
    );
    const dependentName =
      dependent.formData[TaxFormFieldIds.DEPENDENT_FIRST_NAME];
    const dependentQuestionType = getDependentQuestionType(dependent, age);

    return getDepedentCreditQuestions(
      dependentQuestionType,
      dependentName,
      CPA_CENTER_ACTIVE_YEAR,
    );
  };

  const getDependentCreditsInfo = (dependent: TaxForm) => {
    const eligibleCredits = getEligibleDependentCredit(dependent);

    if (
      eligibleCredits ===
      DEPENDENT_CREDITS_OPTIONS.CHILD_CARE_AND_CHILD_TAX_CREDIT
    ) {
      return [
        {
          label: 'Child care credit',
          value: true,
        },
        {
          label: 'Child tax credit',
          value: true,
        },
        {
          label: 'Child care expenses',
          value: CurrencyNumberFormat(childCareExpense),
        },
      ];
    }

    if (eligibleCredits === DEPENDENT_CREDITS_OPTIONS.ONLY_CHILD_TAX_CREDIT) {
      return [
        {
          label: 'Child tax credit',
          value: true,
        },
      ];
    }

    if (
      eligibleCredits === DEPENDENT_CREDITS_OPTIONS.ONLY_OTHER_DEPENDENT_CREDIT
    ) {
      return [
        {
          label: 'Other dependent credit',
          value: true,
        },
      ];
    }

    return [];
  };

  return {
    isLoading,
    dependentsTaxForm,
    getDependentCreditsInfo,
    getDependentCreditQuestionsToShow,
  };
};
