import {useContext, useEffect, useState} from 'react';
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgRotate from 'lightgallery/plugins/rotate';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import {IconButton, Menu, MenuItem, Typography} from '@mui/material';
import {
  CPA_CENTER_ACTIVE_YEAR,
  VALID_DOCUMENT_STATUS,
  themmeColor,
} from 'src/constants/constants';
import {getMergedPDF} from 'src/appApi';
import MenuIcon from '@mui/icons-material/Menu';
import DocumentTypeSwitchOverlay from 'src/CpaCenterUserInfo/CpaCenterDocumentsOCR/DocumentSwitcher/DocumentViewer/DocumentTypeSwitchOverlay';
import {useParams} from 'react-router-dom';
import DocumentsContext from 'src/CpaCenterUserInfo/CpaCenterDocumentsOCR/DocumentsContext';
import {Document, Page} from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {makeStyles} from '@mui/styles';
import {ZoomIn, ZoomOut} from '@material-ui/icons';
import FullScreenLoading from 'src/common/FullScreenLoading';

const useStyles = makeStyles({
  documentViewerContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  documentViewer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 70px)',
    width: '100%',
  },
  imageRenderer: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
  },
  pdfRenderer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
  },
  pdfPageRenderer: {
    display: 'flex',
    margin: '10px 0px',
    background: themmeColor.backgroundOffWhite,
  },
  footer: {
    height: '70px',
    width: '100%',
    display: 'flex',
    padding: '10px',
    justifyContent: 'flex-end',
  },
  mergeDocuments: {
    backgroundColor: themmeColor.darkBlue,
    padding: '5px 10px',
    borderRadius: 6,
  },
  zoomContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '10px',
  },
});

interface ImageRendererProps {
  uri: string;
  canvasRef: any;
  imageScale: number;
  setImageScale: any;
  onZoomClick: (shouldZoomOut: boolean) => void;
}

const ImageRenderer = ({
  uri,
  canvasRef,
  imageScale,
  setImageScale,
  onZoomClick,
}: ImageRendererProps) => {
  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const canvasContext = canvas?.getContext('2d');
      canvasContext.clearRect(0, 0, canvas.width, canvas.height);

      const background = new Image();
      background.src = uri;

      // Make sure the image is loaded first otherwise nothing will draw.
      background.onload = () => {
        canvas.width = background.width * imageScale;
        canvas.height = background.height * imageScale;
        canvasContext.drawImage(background, 0, 0);
      };
    }
    setImageScale(1);
  }, [uri]);

  const styles = useStyles({});

  return (
    <>
      <div className={styles.zoomContainer}>
        <div onClick={(e) => onZoomClick(true)}>
          <ZoomOut />
        </div>
        <Typography>{imageScale}</Typography>
        <div onClick={(e) => onZoomClick(false)}>
          <ZoomIn />
        </div>
      </div>
      <div className={styles.imageRenderer} id="image-renderer">
        <LightGallery speed={500} plugins={[lgZoom, lgRotate, lgThumbnail]}>
          <a href={uri}>
            <canvas id="canvas" ref={canvasRef} />
          </a>
        </LightGallery>
      </div>
    </>
  );
};

const DocumentViewer = () => {
  const {flyfin_user_id, docTypeId, docId} = useParams();
  const {
    docsData,
    fetchDocuments,
    canvasRef,
    canvasPDFRef,
    uri,
    setURI,
    imageScale,
    setImageScale,
  } = useContext(DocumentsContext);

  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [documentSwitchOverlay, setDocumentSwitchOverlay] = useState(false);
  const [showPDF, setShowPDF] = useState(true);
  const [loading, setLoading] = useState(true);
  const [numPages, setNumPages] = useState(null);
  const [pdfScale, setPdfScale] = useState(1.3);

  const documents = docsData?.filter(
    (doc_data) => doc_data.documentTypeId.toString() === docTypeId,
  )?.[0]?.documents;
  const currentDocument = documents?.filter(
    (document) => document.documentId.toString() === docId,
  )?.[0];

  const handleMenuClose = () => setIsOpen(false);

  const handleMenuOpen = (e: any) => {
    setAnchorEl(e.currentTarget);
    setIsOpen(true);
  };

  const handleDocumentTypeSwitchOverlay = () => {
    setDocumentSwitchOverlay(true);
    setIsOpen(false);
  };

  const onPDFDocumentLoadSuccess = ({numPages}) => {
    setNumPages(numPages);
  };

  const onPassword = (callback: any, reason: any) => {
    const callbackProxy = (password: any) => {
      // Cancel button handler
      if (password === null) {
        setShowPDF(false);
      }

      callback(password);
    };

    switch (reason) {
      case 1: {
        const password = prompt('Enter the password to open this PDF file.');
        callbackProxy(password);
        break;
      }
      case 2: {
        const password = prompt('Invalid password. Please try again.');
        callbackProxy(password);
        break;
      }
      default:
    }
  };

  const onZoomClick = (shouldZoomOut: boolean) => {
    const newImageScale = shouldZoomOut
      ? Math.round((imageScale - 0.1) * 10) / 10
      : Math.round((imageScale + 0.1) * 10) / 10;
    const canvas = canvasRef.current;

    if (canvas) {
      const canvasContext = canvas?.getContext('2d');
      canvasContext.clearRect(0, 0, canvas.width, canvas.height);
      const background = new Image();
      background.src = uri;

      // Make sure the image is loaded first otherwise nothing will draw.
      background.onload = () => {
        canvas.width = background.width * newImageScale;
        canvas.height = background.height * newImageScale;
        canvasContext.scale(newImageScale, newImageScale);
        canvasContext.drawImage(background, 0, 0);
      };
    }
    setImageScale(newImageScale);
  };

  useEffect(() => {
    currentDocument?.documentURI
      ?.then((resp) => {
        setURI(resp.data);
      })
      .catch((e) => {
        alert(`Something went wrong ${e}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [docTypeId, docId]);

  const styles = useStyles({});

  if (!currentDocument) return null;

  return (
    <div className={styles.documentViewerContainer}>
      <div className={styles.documentViewer}>
        {!loading ? (
          currentDocument.isImage ? (
            <ImageRenderer
              uri={uri}
              canvasRef={canvasRef}
              imageScale={imageScale}
              setImageScale={setImageScale}
              onZoomClick={onZoomClick}
            />
          ) : (
            <>
              <div className={styles.zoomContainer}>
                <div
                  onClick={(e) =>
                    setPdfScale((prev) => Math.round((prev - 0.1) * 10) / 10)
                  }>
                  <ZoomOut />
                </div>
                <Typography>{pdfScale}</Typography>
                <div
                  onClick={(e) =>
                    setPdfScale((prev) => Math.round((prev + 0.1) * 10) / 10)
                  }>
                  <ZoomIn />
                </div>
              </div>
              <div className={styles.pdfRenderer} id="pdf-renderer">
                {showPDF && (
                  <Document
                    file={uri}
                    onLoadSuccess={onPDFDocumentLoadSuccess}
                    onPassword={onPassword}>
                    {Array.from(new Array(numPages), (el, index) => (
                      <div
                        className={styles.pdfPageRenderer}
                        id={`Page-${index + 1}`}>
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                          canvasRef={(ref) =>
                            (canvasPDFRef.current[index + 1] = ref)
                          }
                          scale={pdfScale}
                        />
                      </div>
                    ))}
                  </Document>
                )}
              </div>
            </>
          )
        ) : (
          <FullScreenLoading open={loading} />
        )}
      </div>
      {currentDocument.status !== VALID_DOCUMENT_STATUS.IGNORED && (
        <div className={styles.footer}>
          {flyfin_user_id && (
            <div style={{marginRight: '20px'}}>
              <a
                style={{textDecoration: 'none'}}
                href={getMergedPDF({
                  year: CPA_CENTER_ACTIVE_YEAR,
                  flyfin_user_id: flyfin_user_id,
                })}>
                <div className={styles.mergeDocuments}>
                  <Typography fontWeight={600} color={themmeColor.white}>
                    Merge Documents
                  </Typography>
                </div>
              </a>
            </div>
          )}
          <div>
            <IconButton onClick={handleMenuOpen}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              open={isOpen}
              onClose={handleMenuClose}
              anchorEl={anchorEl}
              MenuListProps={{
                'aria-labelledby': `switch-doc-type-div-${currentDocument.documentId}`,
              }}>
              <MenuItem
                value={'Change currentDocument type'}
                onClick={handleDocumentTypeSwitchOverlay}>
                Change Document Type
              </MenuItem>
            </Menu>
          </div>
        </div>
      )}
      {documentSwitchOverlay && (
        <DocumentTypeSwitchOverlay
          isVisible={documentSwitchOverlay}
          handleClose={() => setDocumentSwitchOverlay(false)}
          document={currentDocument}
          onSuccess={fetchDocuments}
          flyfin_user_id={flyfin_user_id}
        />
      )}
    </div>
  );
};

export default DocumentViewer;
