import React, {useState} from 'react';
import {TableBody, TableCell, TableRow} from '@mui/material';
import {Link} from 'react-router-dom';

import {themmeColor} from 'src/constants/constants';
import {
  BACKEND_RESPONSE_KEYS,
  getCpaCenterV2Step,
} from 'src/CpaCenterList/components/cpaList.utils';
import useIsCpaCenterV2 from 'src/CpaCenterV2/hooks/useIsCpaCenterV2';

const CpaListTableRow = ({
  record,
  tableData,
  routeToUserInfo,
}: {
  record: any;
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <TableRow
      component={Link}
      to={routeToUserInfo()}
      style={{
        backgroundColor: isFocused ? themmeColor.focusedBlue : undefined,
        textDecoration: 'none',
      }}
      onMouseEnter={() => setIsFocused(true)}
      onMouseLeave={() => setIsFocused(false)}>
      {tableData.map((cell) => (
        <TableCell align={'left'}>
          <div>{cell.render(record)}</div>
        </TableCell>
      ))}
    </TableRow>
  );
};

const ListTableBody = ({
  recordsArray,
  tableData,
  onRowClick,
  isUserView = false,
}: {
  recordsArray: Array<any>;
  tableData: any;
  isUserView?: boolean;
}) => {
  const {isCpaCenterV2} = useIsCpaCenterV2();
  return (
    <TableBody>
      {recordsArray.map((record) => (
        <CpaListTableRow
          record={record}
          tableData={tableData}
          routeToUserInfo={() => {
            const flyfin_user_id =
              record[BACKEND_RESPONSE_KEYS.FLYFIN_USER_ID];
            const cpa_ret_status =
              record[BACKEND_RESPONSE_KEYS.CPA_FACING_RETURN_STATUS];
            const params = {
              flyfin_user_id: flyfin_user_id,
              query_id: record?.id,
            };
            let route = onRowClick(params);
            if (isCpaCenterV2 && isUserView) {
              route += `/${getCpaCenterV2Step(cpa_ret_status)}`;
            }
            return route;
          }}
        />
      ))}
    </TableBody>
  );
};

export default ListTableBody;
