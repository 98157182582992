import {useDispatch} from 'react-redux';
import useCurrentUserId from './useCurrentUserId';
import {
  addMessage,
  addThread,
  setThreadStatus,
  updateThreadUnreadMessages,
} from 'src/appApi';
import {ThreadStatus} from 'src/store/threads/threads.reducer';
import {fetchThreads} from 'src/store/threads/threads.actions';
import {CPA_CENTER_ACTIVE_YEAR} from 'src/constants/constants';

export const useThreadsAction = () => {
  const dispatch = useDispatch();
  const {userId} = useCurrentUserId();

  const refreshNotes = async () => {
    await dispatch(fetchThreads(userId));
  };

  const createNewThread = async (comment: string) => {
    await addThread({
      comment,
      fly_user_id: userId,
      year: CPA_CENTER_ACTIVE_YEAR,
    });
    await refreshNotes();
  };

  const postMessageInThread = async (thread_id: number, comment: string) => {
    await addMessage({thread_id, comment});
    await refreshNotes();
  };

  const updateThreadStatus = async (
    thread_id: number,
    status: ThreadStatus,
  ) => {
    await setThreadStatus({status}, {thread_id});
    await refreshNotes();
  };

  const updateUnreadMessages = async (
    thread_id: number,
    unread_messages: number[],
  ) => {
    await updateThreadUnreadMessages({unread_messages}, {thread_id});
    await refreshNotes();
  };

  return {
    refreshNotes,
    createNewThread,
    postMessageInThread,
    updateThreadStatus,
    updateUnreadMessages,
  };
};
