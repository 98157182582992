import {getAllReturns} from 'src/appApi';
import {SET_TAX_RETURNS} from '../actionNames';
import {setAppState} from '../app/app.actions';
import {AppReducerStates, NotificationType} from '../app/app.reducer';
import {TaxReturnsStateType} from './taxReturns.reducer';
import {CPA_CENTER_ACTIVE_YEAR} from 'src/constants/constants';
import {parseSingleReturn} from './taxReturns.utils';

const setTaxReturns = (payload: Pick<TaxReturnsStateType, 'taxReturns'>) => {
  return {
    type: SET_TAX_RETURNS,
    payload,
  };
};

export const fetchTaxReturns = (userId: number) => {
  return async (dispatch: any) => {
    try {
      const {
        // @ts-ignore
        data: {returns},
      } = await getAllReturns({
        fly_user_id: userId,
        year: CPA_CENTER_ACTIVE_YEAR,
      });
      dispatch(setTaxReturns({taxReturns: returns.map(parseSingleReturn)}));
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to get tax returns (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};
