import React, {useEffect, useState} from 'react';
import {getQueriesList, patchCpaQuery} from 'src/appApi';
import {
  CPAQuestionType,
  CPAQuestionTypeName,
  QUERY_STATUS,
  themmeColor,
} from 'src/constants/constants';
import QueriesContext from 'src/UserQueries/QueriesContext';
import {useOutletContext, useSearchParams} from 'react-router-dom';
import {Button, Chip, Table, TableContainer, Typography} from '@mui/material';
import ListTableHeader from 'src/common/Table/ListTableHeader';
import ListTableBody from 'src/common/Table/ListTableBody';
import {
  DEFAULT_PER_PAGE,
  filterFields,
  headerCellId,
  QueryStatusText,
  SORT_DIRECTION,
  sortFields,
  StatusColor,
  SupportedQueryTypes,
} from 'src/UserQueries/Queries.constants';
import NormalReadOnlyCell from 'src/CpaCenterList/components/NormalReadOnlyCell/NormalReadOnlyCell';
import FilterBar, {SearchPanelData} from 'src/common/FilterBar/FilterBar';
import FilterModal from 'src/UserQueries/components/FilterModal';
import CircularProgress from '@mui/material/CircularProgress';
import _ from 'lodash';
import SelectCPACell from 'src/SelectCPACell/SelectCPACell';
import Pagination from 'src/common/Pagination/Pagination';
import {differenceInHours, format} from 'date-fns';
import {ObjectValue} from 'src/types/common.types';
import {BackendQuery} from 'src/types/query.types';
import {TAX_RETURN_STATUS_LABELS} from 'src/CpaCenterList/components/cpaList.utils';

const QueryList = () => {
  const {openDrawer} = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const defaultUserId = searchParams.get(filterFields.FLY_USER_ID);
  const defaultEmail = searchParams.get(filterFields.FLY_USER_EMAIL);
  const defaultName = searchParams.get(filterFields.FLY_USER_NAME);
  const defaultPageCount = searchParams.get(filterFields.PAGE);
  const defaultYear = searchParams.getAll(filterFields.YEAR);
  const defaultStatus = searchParams.getAll(filterFields.STATUS);
  const defaultAssignee = searchParams.getAll(filterFields.ASSIGNEE);
  const defaultFromDate =
    searchParams.get(filterFields.DATE_FROM) ?? '2023-08-1'; // this is intentional (according to requirement)
  const defaultToDate = searchParams.get(filterFields.DATE_TO);
  const defaultSelectedTypes = searchParams.getAll(
    filterFields.TYPE,
  ) as CPAQuestionType[];
  const defaultRatedConversation = searchParams.get(
    filterFields.RATED_CONVERSATION,
  );
  const [sortOrderBy, setSortOrderBy] = useState<
    keyof typeof sortFields | undefined
  >(undefined);
  const [sortDirection, setSortDirection] = useState<
    ObjectValue<typeof SORT_DIRECTION> | undefined
  >(undefined);

  const pageCount = defaultPageCount ? parseInt(defaultPageCount) : 1;

  const [isPrev, setIsPrev] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [recordsArray, setRecordsArray] = useState<Array<Record>>([]);
  const [totalPages, setTotalPages] = useState(1);

  const getIdEmailAndNameFilteringKeys = () => {
    let filters: {
      [filterFields.FLY_USER_ID]?: string;
      [filterFields.FLY_USER_EMAIL]?: string;
      [filterFields.FLY_USER_NAME]?: string;
    } = {};

    if (defaultUserId) filters[filterFields.FLY_USER_ID] = defaultUserId;
    if (defaultEmail) filters[filterFields.FLY_USER_EMAIL] = defaultEmail;
    if (defaultName) filters[filterFields.FLY_USER_NAME] = defaultName;
    return filters;
  };

  const getExtraFilteringKeys = () => {
    const filters = {};

    if (!_.isEmpty(defaultYear)) filters[filterFields.YEAR] = defaultYear;
    if (defaultRatedConversation)
      filters[filterFields.RATED_CONVERSATION] = defaultRatedConversation;
    if (!_.isEmpty(defaultStatus))
      filters[filterFields.STATUS] = defaultStatus;
    if (defaultToDate) filters[filterFields.DATE_TO] = defaultToDate;
    if (defaultFromDate) filters[filterFields.DATE_FROM] = defaultFromDate;
    if (defaultAssignee) filters[filterFields.ASSIGNEE] = defaultAssignee;
    return filters;
  };

  const getFilterParams = () => {
    return {
      [filterFields.TYPE]: !_.isEmpty(defaultSelectedTypes)
        ? defaultSelectedTypes
        : SupportedQueryTypes,
      ...getIdEmailAndNameFilteringKeys(),
      ...getExtraFilteringKeys(),
      [filterFields.PAGE]: pageCount,
    };
  };

  const closeFilterModal = () => setIsFilterModalOpen(false);
  const openFilterModal = () => setIsFilterModalOpen(true);

  const fetchQueriesList = async () => {
    let ordering = null;
    if (sortOrderBy && sortDirection) {
      let sign = sortDirection === SORT_DIRECTION.ASC ? '' : '-';
      if (sortOrderBy === headerCellId.UPDATED_AT)
        sign = sign === '-' ? '' : '-';
      ordering = `${sign}${sortFields[sortOrderBy]}`;
    }
    try {
      setLoading(true);
      const response = await getQueriesList({
        per_page: DEFAULT_PER_PAGE,
        ...(ordering != null ? {ordering} : {}),
        ...getFilterParams(),
      });
      setRecordsArray(response.data.results);
      setIsPrev(!!response.data.previous);
      setIsNext(!!response.data.next);
      setTotalPages(Math.ceil(response.data.count / DEFAULT_PER_PAGE));
      setLoading(false);
    } catch (e) {
      alert('Error while fetching queries!');
    }
  };

  const onClickSearchHandler = (data: SearchPanelData) => {
    setSearchParams((prevSearchParams) => {
      if (data.userId)
        prevSearchParams.set(filterFields.FLY_USER_ID, data.userId);
      else prevSearchParams.delete(filterFields.FLY_USER_ID);

      if (data.email)
        prevSearchParams.set(filterFields.FLY_USER_EMAIL, data.email);
      else prevSearchParams.delete(filterFields.FLY_USER_EMAIL);

      if (data.name)
        prevSearchParams.set(filterFields.FLY_USER_NAME, data.name);
      else prevSearchParams.delete(filterFields.FLY_USER_NAME);

      prevSearchParams.set(filterFields.PAGE, '1');

      return prevSearchParams;
    });
  };

  const onClearFilter = () => {
    setSearchParams({});
    setSortOrderBy(undefined);
    setSortDirection(undefined);
  };

  const filterQueriesOfToday = () => {
    setSearchParams(() => {
      return {
        [filterFields.DATE_FROM]: format(new Date(), 'yyyy-MM-dd'),
      };
    });
  };

  const handleClickHeaderCell = (fieldId: headerCellId) => {
    if (sortOrderBy !== fieldId) {
      setSortOrderBy(fieldId);
      setSortDirection(SORT_DIRECTION.ASC);
      return;
    }
    setSortDirection((prevSortDirection) => {
      return prevSortDirection === SORT_DIRECTION.ASC
        ? SORT_DIRECTION.DESC
        : SORT_DIRECTION.ASC;
    });
  };

  const queriesListColumnComponentMapper = [
    {
      id: headerCellId.FLYFIN_USER_NAME,
      label: 'User Name',
      render: (query: BackendQuery) => (
        <NormalReadOnlyCell value={query.name} />
      ),
    },
    {
      id: headerCellId.TYPE,
      label: 'Type',
      render: (query: BackendQuery) => (
        <NormalReadOnlyCell value={CPAQuestionTypeName[query.type] ?? '-'} />
      ),
    },
    {
      id: headerCellId.YEAR,
      label: 'Year',
      render: (query: BackendQuery) => (
        <NormalReadOnlyCell value={query.app_year} />
      ),
    },
    {
      id: headerCellId.CPA_RETURN_STATUS,
      label: 'Return Status',
      render: (query: BackendQuery) => {
        const currentStatus =
          TAX_RETURN_STATUS_LABELS[query.cpa_facing_ret_status];
        return (
          <div
            style={{
              backgroundColor: currentStatus?.backColor ?? themmeColor.white,
              borderRadius: 24,
            }}>
            <Typography
              style={{color: currentStatus?.textColor ?? themmeColor.black}}>
              {currentStatus?.label ?? 'Not Submitted'}
            </Typography>
          </div>
        );
      },
    },
    {
      id: headerCellId.CREATED_AT,
      label: 'Created at',
      enableSort: true,
      render: (query: BackendQuery) => (
        <NormalReadOnlyCell
          value={format(new Date(query.created_at), 'dd MMM, yyyy')}
        />
      ),
    },
    {
      id: headerCellId.UPDATED_AT,
      enableSort: true,
      label: 'Last updated',
      render: (query: BackendQuery) => {
        const diffInHours = differenceInHours(
          new Date(),
          new Date(query.status_updated_at),
        );
        let value = '';
        let color = themmeColor.grey;
        if (diffInHours > 0) {
          const days = Math.floor(diffInHours / 24);
          const hours = diffInHours % 24;
          if (days > 0) {
            value = `${days} days `;
          }

          if (hours > 0) {
            value = value + `${hours} hours `;
          }
          if (query.status === QUERY_STATUS.OPEN) {
            if (diffInHours > 12) color = themmeColor.flyfinYellow;
            if (diffInHours > 36) color = themmeColor.errorRed;
          }
        } else {
          value = 'less than an hour ';
        }
        value = value + 'ago';
        return <NormalReadOnlyCell value={value} color={color} />;
      },
    },
    {
      id: headerCellId.STATUS,
      label: 'Status',
      render: (query: BackendQuery) => {
        const status = query.status as QUERY_STATUS;
        return (
          <Chip
            label={QueryStatusText[status]}
            sx={{
              backgroundColor: StatusColor[status],
              color: themmeColor.white,
              fontWeight: 'bold',
            }}
          />
        );
      },
    },
    {
      id: headerCellId.ASSIGNEE_EMAIL,
      label: 'Assignee',
      render: (query: BackendQuery) => (
        <SelectCPACell
          placeholder={'Assign'}
          onChange={async (assignedCpa) => {
            try {
              setLoading(true);
              await patchCpaQuery(query.id, {assignee: assignedCpa});
              await fetchQueriesList();
              setLoading(false);
            } catch (e) {
              alert(`Something went wrong ${e}`);
            }
          }}
          value={query.assignee_email}
        />
      ),
    },
    {
      id: headerCellId.CLARITY_RATING,
      label: 'Avg Clarity Rating',
      render: (query: BackendQuery) => (
        <NormalReadOnlyCell value={query.avg_clarity_rating ?? '-'} />
      ),
    },
    {
      id: headerCellId.CORRECTNESS_RATING,
      label: 'Avg Correctness Rating',

      render: (record: BackendQuery) => (
        <NormalReadOnlyCell value={record.avg_correctness_rating ?? '-'} />
      ),
    },
    {
      id: headerCellId.GRAMMAR_RATING,
      label: 'Avg Grammar Rating',
      render: (record: BackendQuery) => (
        <NormalReadOnlyCell value={record.avg_grammar_rating ?? '-'} />
      ),
    },
  ];

  const handleIncrementPage = () => {
    setSearchParams((prev) => {
      prev.set(filterFields.PAGE, String(pageCount + 1));
      return prev;
    });
  };

  const handleDecrementPage = () => {
    if (pageCount <= 1) return;
    setSearchParams((prev) => {
      prev.set(filterFields.PAGE, String(pageCount - 1));
      return prev;
    });
  };

  const handleGoToFirstPage = () => {
    if (pageCount <= 1) return;
    setSearchParams((prev) => {
      prev.set(filterFields.PAGE, String(1));
      return prev;
    });
  };

  const handleGoToLastPage = () => {
    if (pageCount >= totalPages) return;
    setSearchParams((prev) => {
      prev.set(filterFields.PAGE, String(totalPages));
      return prev;
    });
  };

  useEffect(() => {
    fetchQueriesList();
  }, [
    defaultUserId,
    defaultEmail,
    defaultName,
    pageCount,
    defaultRatedConversation,
    defaultYear.toString(),
    defaultStatus.toString(),
    defaultAssignee.toString(),
    defaultSelectedTypes.toString(),
    defaultToDate,
    defaultFromDate,
    sortDirection,
    sortOrderBy,
  ]);

  return (
    <QueriesContext.Provider value={{fetchQueriesList}}>
      <FilterBar
        title={'Queries'}
        disableSearchPanel={isLoading}
        onClearFilter={onClearFilter}
        onClickHamburger={openDrawer}
        onClickSearch={onClickSearchHandler}
        init={{
          userId: defaultUserId ?? '',
          email: defaultEmail ?? '',
          name: defaultName ?? '',
        }}>
        <Button
          onClick={filterQueriesOfToday}
          variant="outlined"
          disabled={isLoading}
          style={{
            color: themmeColor.grey,
            borderColor: themmeColor.grey,
            borderWidth: 1,
            marginLeft: 10,
          }}>
          Today's queries
        </Button>
        <Button
          onClick={openFilterModal}
          variant="outlined"
          disabled={isLoading}
          style={{
            color: themmeColor.grey,
            borderColor: themmeColor.grey,
            borderWidth: 1,
            marginLeft: 10,
          }}>
          Filters
        </Button>
      </FilterBar>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '88vh',
          }}>
          <CircularProgress />
        </div>
      ) : (
        <div style={{width: '100vw', overflow: 'hidden'}}>
          <TableContainer
            style={{maxHeight: '88vh', overflowX: 'auto', overflowY: 'auto'}}>
            <Table stickyHeader>
              <ListTableHeader
                tableData={queriesListColumnComponentMapper}
                onClickSortDirection={handleClickHeaderCell}
                orderBy={sortOrderBy}
                sortDirection={sortDirection}
              />
              <ListTableBody
                recordsArray={recordsArray}
                tableData={queriesListColumnComponentMapper}
                onRowClick={({query_id}) => query_id.toString()}
              />
            </Table>
            {/* PAGINATION */}
            <Pagination
              isPrev={isPrev}
              isNext={isNext}
              isLoading={isLoading}
              pageCount={pageCount}
              totalPages={totalPages}
              onDecrementPage={handleDecrementPage}
              onGotoFirstPage={handleGoToFirstPage}
              onGotoLastPage={handleGoToLastPage}
              onIncrementPage={handleIncrementPage}
            />
          </TableContainer>
        </div>
      )}
      {isFilterModalOpen && (
        <FilterModal isOpen={isFilterModalOpen} onClose={closeFilterModal} />
      )}
    </QueriesContext.Provider>
  );
};

export default QueryList;
