import {TextField, Typography} from '@mui/material';
import React from 'react';
import DSAccordion from 'src/DesignSystem/Accordion/DSAccordion';
import {RETURN_SUMMARY_CATEGORY, themmeColor} from 'src/constants/constants';

interface OcrDataSectionProps {
  sectionName: string;
  data: {[key: string]: any};
  onChange: (key: string, value: any) => void;
}

const OcrDataSection = ({
  sectionName,
  data,
  onChange,
}: OcrDataSectionProps) => {
  return (
    <DSAccordion
      defaultExpanded
      summaryStyle={{backgroundColor: themmeColor.lightYellow}}
      name={sectionName}>
      <div>
        {Object.entries(data).map(([key, value]) => (
          <div style={{marginBottom: '10px'}}>
            <Typography
              style={{
                fontSize: 14,
                marginBottom: 5,
              }}>
              {RETURN_SUMMARY_CATEGORY.hasOwnProperty(key)
                ? RETURN_SUMMARY_CATEGORY[key]
                : key}
            </Typography>
            <TextField
              style={{width: '100%'}}
              type={'number'}
              onChange={(e) => onChange(key, e.target.value)}
              onWheel={(e) => e.target.blur()}
              value={value}
            />
          </div>
        ))}
      </div>
    </DSAccordion>
  );
};

export default OcrDataSection;
