import {Modal, Typography} from '@mui/material';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {themmeColor} from 'src/constants/constants';
import CrossIcon from 'src/icons/CrossIcon';

interface SendToCCHErrorOverlayProps {
  onClose: () => void;
  isOpen: boolean;
  error: string;
}

const SendToCCHErrorOverlay = ({
  onClose,
  isOpen,
  error,
}: SendToCCHErrorOverlayProps) => {
  const formatedErrorArray = error.replaceAll(/\\r/g, '').split(/\\n/g);

  return (
    <PopUp
      onClose={onClose}
      isOpen={isOpen}
      style={{
        width: '80%',
        height: '80%',
        overflow: 'scroll',
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Typography style={{fontSize: 20, fontWeight: 600}}>
          CCH Error
        </Typography>
        <div onClick={onClose} style={{cursor: 'pointer'}}>
          <CrossIcon />
        </div>
      </div>
      <div
        style={{
          borderRadius: 12,
          background: themmeColor.offWhite,
          padding: 20,
          marginTop: 16,
        }}>
        {formatedErrorArray.map((str) => (
          <Typography>{str}</Typography>
        ))}
      </div>
    </PopUp>
  );
};

export default SendToCCHErrorOverlay;
