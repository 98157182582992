import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import {
  CPA_CENTER_ACTIVE_YEAR,
  TAX_DUE_PAYMENT_METHOD,
  themmeColor,
} from 'src/constants/constants';
import {Replay} from '@mui/icons-material';
import {getBankDetails, postCCHData} from 'src/appApi';
import {useParams} from 'react-router-dom';
import _ from 'lodash';
import {CCH_ACTION_BUTTONS} from '../../CpaCenterCCH/cch.utils';

const useStyles = makeStyles({
  methodContainer: {
    display: 'flex',
    backgroundColor: ({color}) => color,
    padding: '10px 32px',
  },
  methodCell: {
    padding: '10px 16px',
  },
  accDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    border: `1px solid ${themmeColor.silver}`,
    borderRadius: 8,
    overflow: 'hidden',
    minWidth: 400,
    marginTop: 24,
    marginLeft: 24,
  },
  accHeader: {
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    backgroundColor: ({color}) => color,
    padding: '12px 24px',
  },
  taxReturn: {
    marginTop: 24,
  },
});

type BankDetailsData = {
  tax_refund_method: string;
  tax_payment_method: string;
  submitted: boolean;
  account_number: string;
  routing_number: string;
  account_type: string;
  phone_number: string;
  tax_return_data: {
    [key: string]: any;
  };
};

const getTaxPaymentMethod = (method) => {
  if (method === TAX_DUE_PAYMENT_METHOD.DIRECT_DEBIT) {
    return 'Direct Debit';
  }
  if (method === TAX_DUE_PAYMENT_METHOD.OTHER) {
    return 'Other payment option';
  }
  if (method === 'DIRECT_DEPOSIT') {
    return 'Direct Deposit';
  }
  return method ?? 'NA';
};

const BankDetails = () => {
  const {flyfin_user_id} = useParams();
  const [bankDetails, setBankDetails] = useState<BankDetailsData>({});
  const [retryLoading, setRetryLoading] = useState(false);

  const fetchBankDetails = async () => {
    try {
      const response = await getBankDetails({
        year: CPA_CENTER_ACTIVE_YEAR,
        fly_user_id: flyfin_user_id,
      });
      setBankDetails(response.data);
    } catch (e) {
      alert(`Something went wrong. ${e}`);
    }
  };

  const onClickRetry = async () => {
    try {
      setRetryLoading(true);
      await postCCHData({
        fly_user_id: flyfin_user_id ?? '',
        year: CPA_CENTER_ACTIVE_YEAR,
        config: {[CCH_ACTION_BUTTONS.BANK_DETAILS]: {should_populate: true}},
      });
      await fetchBankDetails();
    } catch (e) {
      alert(`${e.response.data.error}`);
    } finally {
      setRetryLoading(false);
    }
  };

  useEffect(() => {
    fetchBankDetails();
  }, []);

  const styles = useStyles({
    color: bankDetails.submitted ? '#ECFEFF' : '#FFF9F7',
  });

  const paymentMethods = [
    {
      type: 'Tax refund method',
      method: getTaxPaymentMethod(bankDetails.tax_refund_method),
    },
    {
      type: 'Tax due method',
      method: getTaxPaymentMethod(bankDetails.tax_payment_method),
    },
  ];

  const paymentDetails = [
    {text: 'Account number', value: bankDetails.account_number},
    {text: 'Routing number', value: bankDetails.routing_number},
    {text: 'Account type', value: bankDetails.account_type},
    {text: 'Phone number', value: bankDetails.phone_number},
  ];

  if (_.isEmpty(bankDetails)) {
    return (
      <div style={{display: 'flex', marginTop: 100, justifyContent: 'center'}}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <Grid container columnGap={3.2} className={styles.methodContainer}>
        {paymentMethods.map(({type, method}) => (
          <Grid item className={styles.methodCell}>
            <Typography>{type}</Typography>
            <Typography fontSize={20} fontWeight={'600'} marginTop={0.4}>
              {method}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <div className={styles.accDetailsContainer}>
            <div className={styles.accHeader}>
              <Typography fontWeight={'bold'}>Account Details:</Typography>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <Chip
                  label={bankDetails.submitted ? 'Submitted' : 'Not-submitted'}
                  sx={{
                    backgroundColor: bankDetails.submitted
                      ? themmeColor.successGreen
                      : themmeColor.flyfinOrange,
                    fontSize: 13,
                    fontWeight: 'bold',
                    color: themmeColor.white,
                    marginLeft: 5,
                  }}
                />
                {!bankDetails.submitted && (
                  <Button
                    variant={'text'}
                    style={{marginLeft: 8}}
                    startIcon={
                      retryLoading ? (
                        <CircularProgress size={30} />
                      ) : (
                        <Replay />
                      )
                    }
                    onClick={onClickRetry}>
                    {!retryLoading ? 'Retry' : ''}
                  </Button>
                )}
              </div>
            </div>
            <Divider />
            {paymentDetails.map(({text, value}, index) => (
              <Typography
                marginX={2.4}
                marginTop={index === 0 ? 2.4 : 0}
                marginBottom={2.4}>
                {text}: <span>{value}</span>
              </Typography>
            ))}
          </div>
        </Grid>
        {_.isObject(bankDetails.tax_return_data) && (
          <Grid item xs={6} className={styles.taxReturn}>
            <Typography fontSize={24} fontWeight={'bold'}>
              Tax return amounts
            </Typography>
            {_.map(bankDetails.tax_return_data, (value, key) => {
              return (
                <Typography>
                  {`${key.replaceAll('_', ' ')}: ${value}`}
                </Typography>
              );
            })}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default BankDetails;
