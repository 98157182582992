import {Typography} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {themmeColor, VALID_DOCUMENT_STATUS} from 'src/constants/constants';
import {
  CpaCenterV2Route,
  CpaCenterV2RouteParts,
} from 'src/constants/routeName';
import useCpaCenterV2Navigation from 'src/CpaCenterV2/hooks/useCpaCenterV2Navigation';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import TaxFormDataPopup from 'src/CpaCenterV2/TaxProfileSections/common/TaxFormDataPopup';
import Box from 'src/DesignSystem/Box/Box';
import Tag from 'src/DesignSystem/Tag/Tag';
import ErrorIcon from 'src/icons/ErrorIcon';
import IgnoredIcon from 'src/icons/IgnoredIcon';
import NotesIcon from 'src/icons/NotesIcon';
import RightChveron from 'src/icons/RightChveron';
import Skeleton from 'src/icons/Skeleton';
import StatusPendingIcon from 'src/icons/StatusPendingIcon';
import TickIcon from 'src/icons/TickIcon';
import {ReduxDocumentType} from 'src/store/documents/documents.reducer';
import {TaxForm} from 'src/store/taxForms/taxForms.reducer';

interface DocumentPointerProps {
  doc?: ReduxDocumentType;
  dontShowStatus?: boolean;
  style?: React.CSSProperties;
  paddingBlock?: number;
  border?: boolean;
  taxform?: TaxForm;
}

const DocumentPointer = ({
  doc,
  dontShowStatus = false,
  style = {},
  paddingBlock = 16,
  border = false,
  taxform = undefined,
}: DocumentPointerProps) => {
  const {userId} = useCurrentUserId();
  const navigate = useNavigate();

  const {navigateToDocumentViewer} = useCpaCenterV2Navigation();

  if (!doc && !taxform) {
    return (
      <Box
        backgroundColor={themmeColor.cpaCenterV2Bg}
        style={{justifyContent: 'center', alignItems: 'center', padding: 16, ...style}}
        borderColor={themmeColor.offWhite}>
        <Typography>No attached document</Typography>
      </Box>
    );
  }

  if (!doc && taxform !== undefined) {
    return (
      <TaxFormDataPopup
        backgroundColor={themmeColor.cpaCenterV2Bg}
        taxform={taxform}
        borderColor={themmeColor.silver}
        style={{marginBottom: 4}}
      />
    );
  }

  const {docId, docType, filename, docStatus} = doc;

  const getIcon = () => {
    if (dontShowStatus) {
      return <NotesIcon />;
    }
    switch (docStatus) {
      case VALID_DOCUMENT_STATUS.IGNORED:
        return <IgnoredIcon />;
      case VALID_DOCUMENT_STATUS.PENDING_FOR_REVIEW:
        return <StatusPendingIcon />;
      case VALID_DOCUMENT_STATUS.ACTION_REQUIRED:
        return <ErrorIcon />;
      case VALID_DOCUMENT_STATUS.ACCEPTED:
      case VALID_DOCUMENT_STATUS.REVIEWED_OCR_DATA:
        return <TickIcon />;
    }
    return <Skeleton height={20} width={20} />;
  };

  const onClickDocument = () => {
    navigateToDocumentViewer(docId);
  };
  return (
    <Box
      onClick={onClickDocument}
      hoverColor={themmeColor.focusedBlue}
      hoverEffect
      style={{
        padding: 16,
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBlock,
        ...(border
          ? {
              borderColor: themmeColor.silver,
              borderStyle: 'solid',
              borderWidth: 1,
            }
          : {}),
        ...style,
      }}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <div style={{marginRight: 10}}>{getIcon()}</div>
        <Tag text={docType} />
        <Typography
          style={{
            fontSize: 16,
            marginLeft: 10,
          }}>
          {filename}
        </Typography>
      </div>
      <div style={{alignSelf: 'center', display: 'flex'}}>
        <RightChveron />
      </div>
    </Box>
  );
};

export default DocumentPointer;
