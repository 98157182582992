import {Typography} from '@mui/material';
import React from 'react';
import {useSelector} from 'react-redux';
import {CPA_CENTER_ACTIVE_YEAR, themmeColor} from 'src/constants/constants';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import DSAccordion from 'src/DesignSystem/Accordion/DSAccordion';
import {selectDocuments} from 'src/store/documents/documents.selector';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.constants';
import {TaxForm} from 'src/store/taxForms/taxForms.reducer';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import SimpleKeyValueField from '../../common/SimpleKeyValueField';

interface SingleRentalIncomeProps {
  sectionName: string;
  taxform: TaxForm;
}

const SingleRentalIncome = ({
  sectionName,
  taxform,
}: SingleRentalIncomeProps) => {
  const {documents} = useSelector(selectDocuments);
  const {taxForms} = useSelector(selectTaxFormsReducer);
  const fields = [
    {
      name: 'Who does this income belong to',
      value: taxform.formData[TaxFormFieldIds.INCOME_EARNER],
    },
    {
      name: 'Name of the rental property',
      value: taxform.formData[TaxFormFieldIds.RENTAL_PROPERTY_NAME],
    },
    {
      name: 'Type of Property',
      value: taxform.formData[TaxFormFieldIds.TYPE_OF_PROPERTY],
    },
    {
      name: 'House number and street',
      value: taxform.formData[TaxFormFieldIds.NUMBER_AND_STREET],
    },
    {
      name: 'Apartment/Unit number (if applicable)',
      value: taxform.formData[TaxFormFieldIds.APARTMENT_NUMBER],
    },
    {
      name: 'City',
      value: taxform.formData[TaxFormFieldIds.CITY],
    },
    {
      name: 'State',
      value: taxform.formData[TaxFormFieldIds.STATE],
    },
    {
      name: 'Zip code',
      value: taxform.formData[TaxFormFieldIds.ZIP_CODE],
    },
    {
      name: `Number of days this property was rented out in ${CPA_CENTER_ACTIVE_YEAR}`,
      value: taxform.formData[TaxFormFieldIds.NUMBER_OF_DAYS_RENTED_FOR],
    },
    {
      name: `Total income earned from property in ${CPA_CENTER_ACTIVE_YEAR}`,
      value: taxform.formData[TaxFormFieldIds.INCOME_EARNED],
    },
  ];

  const linkedTaxFormsId: number[] =
    taxform.formData[TaxFormFieldIds.LINKED_MISC_FORMS] ?? [];

  const linkedTaxforms = taxForms.filter((taxform) =>
    linkedTaxFormsId?.includes(taxform.taxFormId),
  );

  return (
    <DSAccordion name={sectionName}>
      {fields.map((field) => (
        <SimpleKeyValueField {...field} />
      ))}
      <Typography
        style={{
          fontSize: 11,
          color: themmeColor.black60,
          marginBottom: 4,
        }}>
        Rental document
      </Typography>
      {linkedTaxforms.map((taxform) => (
        <DocumentPointer
          taxform={taxform}
          doc={documents.find((doc) => doc.docId === taxform.docId)}
          dontShowStatus
          border
        />
      ))}
    </DSAccordion>
  );
};

export default SingleRentalIncome;
