import React, {useEffect, useState} from 'react';
import {Outlet, useOutletContext, useParams} from 'react-router-dom';
import {getQueriesList} from 'src/appApi';
import {
  CPA_CENTER_ACTIVE_YEAR,
  CPAQuestionType,
} from 'src/constants/constants';
import {
  aggregateTaxQueriesActionCount,
  taxQueriesBackendResponseMapper,
} from 'src/CpaCenterUserInfo/CpaCenterTaxQueries/components/taxqueries.utils';
import TaxQueriesContext, {
  Query,
} from 'src/CpaCenterUserInfo/CpaCenterTaxQueries/components/TaxQueriesContext';
import QuerySelector from 'src/CpaCenterUserInfo/CpaCenterTaxQueries/components/QuerySelector';

const CpaCenterTaxQueries = () => {
  const {flyfin_user_id} = useParams();

  const [queriesData, setQueriesData] = useState<Array<Query>>([]);
  const [postingQuery, setPostingQuery] = useState(false);
  const {setActionCounts} = useOutletContext();

  const fetchTaxQueries = async () => {
    if (!flyfin_user_id) return;
    const response = await getQueriesList({
      fly_user_id: flyfin_user_id,
      app_year: [CPA_CENTER_ACTIVE_YEAR, CPA_CENTER_ACTIVE_YEAR + 1],
      type: [CPAQuestionType.TAX_FILING, CPAQuestionType.QTC],
      is_conversation_required: true,
    });

    const parsedResponseData = taxQueriesBackendResponseMapper(
      response.data.results,
    );
    const taxQueriesActionCount =
      aggregateTaxQueriesActionCount(parsedResponseData);
    setActionCounts((prev) => ({...prev, taxQueriesActionCount}));
    setQueriesData(parsedResponseData);
  };

  useEffect(() => {
    fetchTaxQueries();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{display: 'flex', height: '100%', width: '100%'}}>
      <TaxQueriesContext.Provider
        value={{
          queriesData,
          fetchTaxQueries,
          postingQuery,
          setPostingQuery,
        }}>
        <QuerySelector />
        <Outlet />
      </TaxQueriesContext.Provider>
    </div>
  );
};

export default CpaCenterTaxQueries;
