import {configureStore} from '@reduxjs/toolkit';
import {combineReducers} from 'redux';
import * as ActionNames from './actionNames';
import {user, UserStateType} from './user/user.reducer';

import {adminReducer} from 'react-admin';
import {history} from 'src/drfDataProvider';
import {connectRouter} from 'connected-react-router';
import {app, AppReducerType} from './app/app.reducer';
import {
  taxProfile,
  TaxProfileStateType,
} from './taxProfile/taxProfile.reducer';
import {DocumentStateType, documents} from './documents/documents.reducer';
import {queries, QueriesStateType} from './queries/queries.reducer';
import {
  businessDetails,
  BusinessDetailsStateType,
} from './businessDetails/businessDetails.reducer';
import {TaxFormsStateType, taxForms} from './taxForms/taxForms.reducer';
import {
  TransactionStateType,
  transactions,
} from './transactions/transactions.reducer';
import {
  taxProfileStatus,
  TaxProfileStatusStateType,
} from './taxProfileStatus/taxProfileStatus.reducer';
import {
  TaxProfileStateInfoStateType,
  taxProfileStatesInfo,
} from './taxProfileStatesInfo/taxProfileStatesInfo.reducer';
import {
  deductionSummary,
  DeductionSummaryStateType,
} from './deductionSummary/deductionSummary.reducer';
import {cchStatus, CCHStatusStateType} from './cchStatus/cchStatus.reducer';
import {
  highValueDeductions,
  HighValueDeductionStateType,
} from './highValueDeductions/highValueDeductions.reducer';
import {
  applicableDeductions,
  ApplicableDeductionStateType,
} from 'src/store/applicableDeductions/applicableDeductions.reducer';
import {
  taxReturns,
  TaxReturnsStateType,
} from './taxReturns/taxReturns.reducer';
import {threads, ThreadsStateType} from './threads/threads.reducer';
import {efileLogs, EfileLogsStateType} from './efileLogs/efileLogs.reducer';
import {
  bankDetails,
  BankDetailsStateType,
} from './bankDetails/bankDetails.reducer';

const reducers = {
  admin: adminReducer,
  router: connectRouter(history),
  user,
  app,
  taxProfile,
  documents,
  queries,
  businessDetails,
  taxForms,
  transactions,
  taxProfileStatus,
  taxProfileStatesInfo,
  deductionSummary,
  cchStatus,
  highValueDeductions,
  applicableDeductions,
  taxReturns,
  threads,
  efileLogs,
  bankDetails,
};

export type ReduxStateType = {
  user: UserStateType;
  app: AppReducerType;
  taxProfile: TaxProfileStateType;
  documents: DocumentStateType;
  queries: QueriesStateType;
  businessDetails: BusinessDetailsStateType;
  taxForms: TaxFormsStateType;
  transactions: TransactionStateType;
  taxProfileStatus: TaxProfileStatusStateType;
  taxProfileStatesInfo: TaxProfileStateInfoStateType;
  deductionSummary: DeductionSummaryStateType;
  cchStatus: CCHStatusStateType;
  highValueDeductions: HighValueDeductionStateType;
  applicableDeductions: ApplicableDeductionStateType;
  taxReturns: TaxReturnsStateType;
  threads: ThreadsStateType;
  efileLogs: EfileLogsStateType;
  bankDetails: BankDetailsStateType;
};

const mainReducer = combineReducers(reducers);

const rootReducer = (state: ReduxStateType, action: {type: string}) => {
  if (action.type === ActionNames.USER_CHANGED) {
    return mainReducer({}, action);
  } else {
    return mainReducer(state, action);
  }
};

export default configureStore({
  reducer: rootReducer,
});
