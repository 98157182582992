import React, {useState} from 'react';
import {themmeColor} from 'src/constants/constants';
import {Button, Grid, TextField} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import {Typography} from '@material-ui/core';
import {makeStyles} from '@mui/styles';

export type SearchPanelData = {
  userId: string;
  email: string;
  name: string;
}

interface FilterBarProps {
  onClearFilter: () => void;
  disableSearchPanel: boolean;
  onClickHamburger: () => void;
  onClickSearch: (searchData: SearchPanelData) => void;
  init?: Partial<SearchPanelData>
  title: string;
}

const useStyle = makeStyles({
  container: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const FilterBar = ({
  children,
  onClearFilter,
  disableSearchPanel,
  onClickHamburger,
  onClickSearch,
  init = {},
  title = '',
}: React.PropsWithChildren<FilterBarProps>) => {
  const styles = useStyle();

  const [userId, setUserId] = useState<string>(init.userId ?? '');
  const [email, setEmail] = useState<string>(init.email ?? '');
  const [name, setName] = useState<string>(init.name ?? '');

  const _onClickSearch = () => {
    onClickSearch({
      userId,
      email,
      name,
    });
  }
  const clearAllFilters = () => {
    setUserId('');
    setEmail('');
    setName('');
    onClearFilter();
  };

  const handleOnPressEnter = (e: any) => {
    if (e.key === 'Enter') {
      _onClickSearch();
    }
  };
  return (
    <Grid container columnSpacing={2.4} className={styles.container}>
      <Grid item xs={'auto'} className={styles.titleContainer}>
      <IconButton
        color='inherit'
        aria-label='open drawer'
        onClick={onClickHamburger}
      >
        <MenuIcon />
      </IconButton>
      <Typography variant={'h5'} style={{fontWeight: 600, marginLeft: 8}}>
        {title}
      </Typography>
      </Grid>
      <Grid item xs={8} className={styles.inputContainer}>
        <TextField
          label="User ID"
          value={userId}
          type="number"
          onKeyUp={handleOnPressEnter}
          onChange={(e) => setUserId(e.target.value)}
          disabled={disableSearchPanel}
        />
        <div style={{marginRight: 24}} />
        <TextField
          label="Email"
          value={email}
          type="email"
          onKeyUp={handleOnPressEnter}
          onChange={(e) => setEmail(e.target.value)}
          disabled={disableSearchPanel}
        />
        <div style={{marginRight: 24}} />
        <TextField
          label="Name"
          value={name}
          onKeyUp={handleOnPressEnter}
          onChange={(e) => setName(e.target.value)}
          disabled={disableSearchPanel}
        />
        <Button
          onClick={_onClickSearch}
          variant={'contained'}
          disabled={disableSearchPanel}
          style={{
            alignSelf: 'center',
            color: themmeColor.white,
            backgroundColor: themmeColor.black,
            borderWidth: 1,
            marginLeft: 10,
          }}>
          Search
        </Button>
        <Button
          onClick={clearAllFilters}
          variant="outlined"
          disabled={disableSearchPanel}
          style={{
            color: themmeColor.grey,
            borderColor: themmeColor.grey,
            borderWidth: 1,
            marginLeft: 10,
          }}>
          Clear All
        </Button>
      </Grid>

      <Grid item xs={'auto'} className={styles.buttonContainer}>
        {children}
      </Grid>
    </Grid>
  );
};

export default FilterBar;
