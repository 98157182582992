export const USER_CHANGED = 'USER_CHANGED';

export const SET_USER_INFO = 'SET_USER_INFO';

export const SET_APP_STATE = 'SET_APP_STATE';

export const SET_TAX_PROFILE = 'SET_TAX_PROFILE';

export const SET_DOCUMENTS = 'SET_DOCUMENTS';

export const SET_QUERIES = 'SET_QUERIES';

export const SET_BUSINESS_DETAILS = 'SET_BUSINESS_DETAILS';

export const SET_TAX_FORMS = 'SET_TAX_FORMS';

export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';

export const SET_TAX_PROFILE_STATUS = 'SET_TAX_PROFILE_STATUS';

export const SET_TAX_PROFILE_STATES_INFO = 'SET_TAX_PROFILE_STATES_INFO';

export const SET_DEDUCTION_SUMMARY = 'SET_DEDUCTION_SUMMARY';

export const SET_CCH_STATUS = 'SET_CCH_STATUS';

export const SET_HIGH_VALUE_DEDUCTIONS = 'SET_HIGH_VALUE_DEDUCTIONS';

export const SET_APPLICABLE_DEDUCTIONS = 'SET_APPLICABLE_DEDUCTIONS';

export const SET_TAX_RETURNS = 'SET_TAX_RETURNS';

export const SET_THREADS = 'SET_THREADS';

export const SET_EFILE_LOGS = 'SET_EFILE_LOGS';

export const SET_BANK_DETAILS = 'SET_BANK_DETAILS';
