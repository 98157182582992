import {Button, Menu, MenuItem, Typography} from '@mui/material';
import React, {useState} from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CpaAssignConfirmOverlay from 'src/CpaCenterList/components/CPAAssignCell/CpaAssignConfirmOverlay';
import {assignCPAToUser, assignReviewerToUser} from 'src/appApi';
import {
  CPA_CENTER_ACTIVE_YEAR,
  VALID_CPA_EMAILS,
} from 'src/constants/constants';
import {CPA_LIST_COLUMNS} from '../cpaList.utils';

interface CPAAssignCellProps {
  fieldId: CPA_LIST_COLUMNS;
  userId: number;
  value: VALID_CPA_EMAILS;
  isAssigned: boolean;
  fetchList: () => Promise<void>;
}

const CPAAssignCell = ({
  fieldId,
  value,
  isAssigned,
  userId,
  fetchList,
}: CPAAssignCellProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmOverlay, setConfirmOverlay] = useState(false);
  const [currentCpa, setCurrentCpa] = useState(value);
  const [loading, setLoading] = useState(false);

  const stopDrilling = (e: any) => {
    e?.stopPropgation?.();
    e?.preventDefault?.();
  };

  const handleClose = () => setIsOpen(false);

  const updateCpa = async (cpa: VALID_CPA_EMAILS) => {
    setLoading(true);
    try {
      if (fieldId === CPA_LIST_COLUMNS.CPA) {
        await assignCPAToUser(cpa, userId);
      } else {
        await assignReviewerToUser(cpa, userId, CPA_CENTER_ACTIVE_YEAR);
      }
      await fetchList();
      setIsOpen(false);
      setConfirmOverlay(false);
    } catch (e) {
      alert(`Something went wrong ${e.response.data.detail}`);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = (cpa: VALID_CPA_EMAILS) => {
    setCurrentCpa(cpa);
    handleClose();
    if (isAssigned) {
      setConfirmOverlay(true);
      return;
    }
    updateCpa(cpa);
  };

  const handleMenuOpen = (e: any) => {
    setAnchorEl(e.currentTarget);
    setIsOpen(true);
  };

  const CPAs = Object.values(VALID_CPA_EMAILS).filter(
    (v) => v != VALID_CPA_EMAILS.Unassigned,
  );

  return (
    <div onClick={stopDrilling}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '50px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={handleMenuOpen}
        id={`cpa-assign-div-${userId}`}>
        {isAssigned ? (
          <div style={{display: 'flex'}}>
            <Typography>{value}</Typography>
            <KeyboardArrowDownIcon style={{marginLeft: 8}} />
          </div>
        ) : (
          <div>
            <Button>Assign</Button>
          </div>
        )}
      </div>
      <Menu
        id="basic-menu"
        open={isOpen}
        onClose={handleClose}
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': `cpa-assign-div-${userId}`,
        }}>
        {CPAs.map((cpa) => (
          <MenuItem value={cpa} onClick={() => handleUpdate(cpa)}>
            {cpa}
          </MenuItem>
        ))}
      </Menu>
      <CpaAssignConfirmOverlay
        isVisible={confirmOverlay}
        handleClose={() => setConfirmOverlay(false)}
        onContinue={() => updateCpa(currentCpa)}
        loading={loading}
      />
    </div>
  );
};

export default CPAAssignCell;
