import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {
  Datagrid,
  Edit,
  EmailField,
  Filter,
  FunctionField,
  List,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useMutation,
  useNotify,
  useRefresh,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import {getCurrentUserId} from '../authProvider';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {stringify} from 'query-string';
import CustomNotificationButton from './CustomNotificationButton';
import {
  AccountLinkStatusChoices,
  AppInstallStatusChoices,
  CPA_CENTER_ACTIVE_YEAR,
  ROUTES_PLACEHOLDERS,
  TaxFilingStatusChoices,
  themmeColor,
} from '../constants/constants';
import {MissingExpenseStatus} from '../MissingExpenses/MissingExpenses.context';
import {getRefundDetails} from '../appApi';
import {CircularProgress, Modal} from '@mui/material';
import JSONPretty from 'react-json-pretty';
import {RouteName} from '../constants/routeName';
import DeleteUserButton from './components/DeleteUserButton';
import QtcInputsOverlay from './components/QtcInputsOverlay';
import ExportData from 'src/CpaCenterUserInfo/components/ExportData';

var JSONPrettyMon = require('react-json-pretty/dist/monikai');

const moment = require('moment-timezone');

const useStyles = makeStyles((theme) => ({
  customToolbar: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
const ButtonLinkToRelatedExpenses = ({record, source}) => {
  // const translate = useTranslate();
  return record ? (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: '/expenses',
        search: stringify({
          page: 1,
          perPage: 10,
          sort: 'txn_date',
          order: 'DESC',
          filter: JSON.stringify({user_id: record[source]}),
        }),
      }}>
      Expenses
    </Button>
  ) : null;
};

export const getDefaultYears = (filedPrevYear) => {
  const currentYear = new Date().getFullYear();
  const prevYear = currentYear - 1;
  return filedPrevYear
    ? [currentYear.toString()]
    : [currentYear.toString(), prevYear.toString()];
};

export const rowClickToRelatedExpenses = (id, basePath, record) => {
  // const translate = useTranslate();
  const query = {
    page: 1,
    perPage: 250,
    // sort: 'txn_date',
    // order: 'DESC',
    // TODO the default year now is 2020, 2021. This needs to be changed according
    // to the need or when the year changes. Supposed to be current and
    // previous year.
    filter: JSON.stringify({
      user_id: id,
      year: getDefaultYears(record.filed_tax_prev_year),
    }),
  };
  const redirect_path = `/expenses?${stringify(query)}`;
  return redirect_path;
};

const CustomToolbar = (props) => {
  const classes = useStyles();
  return (
    <Toolbar {...props} className={classes.customToolbar}>
      <SaveButton />
      <DeleteUserButton {...props} />
    </Toolbar>
  );
};

const ShadowUserCustomToolbar = (props) => {
  const classes = useStyles();
  return (
    <Toolbar {...props} className={classes.customToolbar}>
      <SaveButton label="Create Mapping" />
    </Toolbar>
  );
};

const SendMonthlyPlanLink = ({...props}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [sendLink, {loading}] = useMutation(
    {
      type: 'send_monthly_plan_hidden_link',
      resource: 'users',
      payload: {fly_user_id: props?.record?.id},
    },
    {
      onSuccess: () => {
        notify('Success!! Deeplink sent');
        refresh();
      },
      onFailure: (error) => {
        const msg = error.body?.display_msg
          ? error.body?.display_msg
          : error.message;
        notify(`Error!! Not able to send deeplink: ${msg}`, 'warning');
      },
    },
  );

  const ctaText =
    props?.record?.monthly_plan_link_sent_count === 0
      ? 'Send Monthly Plan link'
      : 'Send Deeplink again';
  const buttonVariant =
    props?.record?.monthly_plan_link_sent_count === 0
      ? 'outlined'
      : 'contained';

  return (
    <Button
      color="primary"
      variant={buttonVariant}
      onClick={sendLink}
      style={{
        marginLeft: 10,
        color: props.isEligibleForMonthly ? '#34ab22' : undefined,
        borderColor: props.isEligibleForMonthly ? '#34ab22' : undefined,
      }}
      disabled={loading || !props.isEligibleForMonthly}>
      {loading ? 'Builing deeplink...' : ctaText}
    </Button>
  );
};

const CheckMonthlyPlanLink = (props) => {
  const notify = useNotify();

  const [isValid, setIsValid] = useState(false);

  const [checkIsValid, {loading}] = useMutation(
    {
      type: 'is_eligible_for_monthly_plan',
      resource: 'users',
      payload: {fly_user_id: props?.record?.id},
    },
    {
      onSuccess: () => {
        notify('User is eligible for Monthly Plan');
        setIsValid(true);
        props.setIsEligibleForMonthly(true);
      },
      onFailure: (error) => {
        notify(`Error: ${error?.body?.display_msg}`);

        props.setIsEligibleForMonthly(false);
      },
    },
  );

  return (
    <Button
      color="primary"
      variant="outlined"
      onClick={checkIsValid}
      disabled={loading}>
      {isValid
        ? 'This user is Eligible for monthly'
        : 'is eligible for Monthly?'}
    </Button>
  );
};

const GetQtcInputs = (props) => {
  const [showModal, setShowModal] = useState(false);
  const onPress = () => {
    setShowModal(true);
  };

  console.log(props.record);

  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        onClick={onPress}
        style={{
          marginLeft: 10,
          color: '#34ab22',
          borderColor: '#34ab22',
        }}>
        {'Get Qtc inputs'}
      </Button>
      <QtcInputsOverlay
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        flyfinUserId={props.record.id}
        year={CPA_CENTER_ACTIVE_YEAR}
      />
    </>
  );
};

const reviewer_info = (record) => `${record.id} - ${record.name}`;

const UserPanel = (props) => {
  const notify = useNotify();
  const [mutate, {loading}] = useMutation();
  const mapping = (id, shadow_user) =>
    mutate(
      {
        type: 'create_mapping',
        resource: 'app_mirroring',
        payload: {data: {real_user_id: id, shadow_user_id: shadow_user}},
      },
      {
        onSuccess: () => {
          notify('Shadow User mapping successful!');
        },
        onFailure: (error) => {
          console.log(error);
          notify(`Shadow User mapping failed! : ${error}, 'warning'`);
        },
      },
    );
  const [isEligibleForMonthly, setIsEligibleForMonthly] = useState(false);
  return (
    <Grid container {...props}>
      <Grid item xs={3}>
        <Edit title=" " {...props}>
          <SimpleForm
            save={({id, shadow_user}) => {
              mapping(id, shadow_user);
            }}
            toolbar={<ShadowUserCustomToolbar />}
            {...props}>
            <NumberInput source="shadow_user"></NumberInput>
          </SimpleForm>
        </Edit>
      </Grid>
      <Grid item xs={9}>
        <Edit title="" {...props}>
          <SimpleForm redirect={false} toolbar={<CustomToolbar />} {...props}>
            <ReferenceInput
              label="Reviewer"
              source="reviewer"
              reference="users"
              filter={{is_staff: true}}>
              <SelectInput optionText={reviewer_info} allowEmpty={false} />
            </ReferenceInput>
            <div style={{flex: 1, display: 'flex', flexDirection: 'row'}}>
              <CheckMonthlyPlanLink
                setIsEligibleForMonthly={setIsEligibleForMonthly}
                {...props}
              />
              <SendMonthlyPlanLink
                isEligibleForMonthly={isEligibleForMonthly}
                {...props}
              />
              <GetQtcInputs {...props} />
              <div style={{marginLeft: 12}}>
                <ExportData
                  fly_user_id={props.record.id}
                  onSuccess={() => notify('Export data success', 'info')}
                  year={2022}
                />
              </div>
            </div>
          </SimpleForm>
        </Edit>
      </Grid>
    </Grid>
  );
};

const UserFilter = (props) => {
  return (
    <Filter {...props}>
      {process.env.REACT_APP_ENVIRONMENT === 'production' && (
        <ReferenceInput
          label="Reviewer"
          source="reviewer"
          reference="users"
          filter={{is_staff: true}}
          alwaysOn>
          <SelectInput optionText={'name'} allowEmpty={false} />
        </ReferenceInput>
      )}
      <NumberInput source="id" min={1} alwaysOn />
      <TextInput source="email" alwaysOn />
      <TextInput source="name" alwaysOn />
      <SelectInput
        source="account_linked"
        label="Account Linked?"
        choices={AccountLinkStatusChoices}
        alwaysOn
      />
      <SelectInput
        source="app_installed"
        choices={AppInstallStatusChoices}
        alwaysOn
      />
      <SelectInput
        source="tax_filing_status"
        choices={TaxFilingStatusChoices}
        alwaysOn
      />
      <SelectInput
        source="missing_expenses_status"
        choices={Object.values(MissingExpenseStatus).map((v) => ({
          name: v,
          id: v,
        }))}
        alwaysOn
      />
    </Filter>
  );
};

const customDefaultFilters = (reactAppEnvironment, reviewerId) => {
  if (reactAppEnvironment === 'production') {
    return {
      reviewer: reviewerId,
      account_linked: AccountLinkStatusChoices[0]['id'],
      app_installed: AppInstallStatusChoices[0]['id'],
    };
  } else return {};
};

const onClickTaxfilingButton = (e, record) => {
  if (record?.flyfin_tax_filing_id != null) {
    window.open(`#/tax_filing_status/${record.flyfin_tax_filing_id}`, '_self');
  }
  e.stopPropagation();
};

const TaxfilingStatusButton = ({record}) => {
  return (
    <Button
      color="primary"
      variant={record?.flyfin_tax_filing_id == null ? 'text' : 'contained'}
      onClick={(e) => {
        onClickTaxfilingButton(e, record);
      }}>
      {record?.flyfin_tax_filing_id == null
        ? 'Not Initiated'
        : record?.flyfin_tax_filing_status}
    </Button>
  );
};
const MissingExpenseButton = ({record}) => {
  const navigate = useNavigate();
  const route = RouteName.MissingExpenses.replace(
    ROUTES_PLACEHOLDERS.FLYFIN_USER_ID_PLACEHOLDER,
    record.id,
  );

  const handleOnClick = (e) => {
    navigate(route);
    e.stopPropagation();
  };

  return (
    <Button color="primary" onClick={handleOnClick} variant={'contained'}>
      {`${record.missing_expenses_status}`}
    </Button>
  );
};

const RefundDetails = ({record}) => {
  const [isRefundOverlay, setIsRefundOverlay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refundDetails, setRefundDetails] = useState('{}');

  const fly_user_id = record.id;

  const openRefundDetals = (e) => {
    setIsRefundOverlay(true);
    e.stopPropagation();
  };

  const closeRefundDetails = (e) => {
    setIsRefundOverlay(false);
    e.stopPropagation();
  };

  const fetchRefundDetails = async () => {
    try {
      setLoading(true);
      const response = await getRefundDetails({fly_user_id});
      setRefundDetails(JSON.stringify(response.data));
      setLoading(false);
    } catch (e) {
      alert(`Something went wrong ${e}`);
    }
  };

  useEffect(() => {
    if (isRefundOverlay) {
      fetchRefundDetails();
    } else {
      setRefundDetails('{}');
    }
  }, [isRefundOverlay]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Button color="primary" onClick={openRefundDetals} variant={'outlined'}>
        {`Check Refund details`}
      </Button>
      {isRefundOverlay && (
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          open={isRefundOverlay}
          onClose={closeRefundDetails}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: themmeColor.white,
              padding: 10,
            }}>
            {loading ? (
              <div
                style={{
                  minHeight: 200,
                  minWidth: 200,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <CircularProgress />
              </div>
            ) : (
              <JSONPretty
                id={`json-pretty-${fly_user_id}`}
                data={refundDetails}
                theme={JSONPrettyMon}
                style={{fontSize: 20}}
              />
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export const UserList = (props) => {
  const reviewerId = getCurrentUserId();
  return (
    <List
      {...props}
      perPage={50}
      sort={{field: 'created_at', order: 'DESC'}}
      filters={<UserFilter />}
      filterDefaultValues={customDefaultFilters(
        process.env.REACT_APP_ENVIRONMENT,
        reviewerId,
      )}
      bulkActionButtons={<CustomNotificationButton />}>
      <Datagrid rowClick={rowClickToRelatedExpenses} expand={<UserPanel />}>
        <TextField source="id" />
        <EmailField source="email" />
        <TextField source="name" />
        <FunctionField
          source="created_at"
          label="Created At(EST)"
          render={(record) =>
            moment
              .utc(record.created_at)
              .tz('EST')
              .format('DD/MM/YYYY, HH:MM:SS')
          }
        />
        <TextField source="reviewer_name" label="Reviewer" />
        <FunctionField
          label="Unreviewed txns"
          render={(record) => record.unreviewed_txns}
        />
        <TextField source="platform" label="Platform" />
        <FunctionField
          label="Devices"
          render={(record) =>
            `${record.num_devices_enabled}(${record.num_devices_all})`
          }
        />
        <FunctionField
          label="User Accepted(Possible)"
          render={(record) =>
            `${record.user_accepted_txns}(${record.possible_txns})`
          }
        />
        <FunctionField
          label="Transactions"
          render={(record) => record.total_txns}
        />
        <FunctionField
          label="#Acc all(exp)"
          render={(record) =>
            `${record.num_accounts_all}(${record.num_accounts_expired})`
          }
        />
        <TaxfilingStatusButton label="Tax Filing Status" />
        <MissingExpenseButton label="Missing Expense" />
        <RefundDetails label="Refund details" />
        <TextField label="Profession" source="onb_profession" />
        {/* <ButtonLinkToRelatedExpenses source="id" label="expenses" /> */}
      </Datagrid>
    </List>
  );
};
