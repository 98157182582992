import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {getTaxProfile} from 'src/appApi';
import {
  parseTaxProfileBackendResponse,
  sectionOrdering,
} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import GenerateTaxProfileField, {
  GenerateTaxProfileFieldProps,
} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/GenerateTaxProfileField';
import {Typography} from '@mui/material';
import {CPA_CENTER_ACTIVE_YEAR} from 'src/constants/constants';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  taxProfileDetailsContainer: {
    borderBottom: '1px solid black',
    width: '80%',
    height: '100%',
    overflowY: 'scroll',
    padding: '10px',
  },
  lengendContainer: {width: '20%', height: '100%'},
});

const CpaCenterTaxProfile = () => {
  const {flyfin_user_id} = useParams();
  const [taxProfile, setTaxProfile] = useState({});

  const fetchTaxProfile = async () => {
    const response = await getTaxProfile({
      years: CPA_CENTER_ACTIVE_YEAR,
      fly_user_id: flyfin_user_id ?? '',
    });
    setTaxProfile(
      parseTaxProfileBackendResponse(
        response.data[`${CPA_CENTER_ACTIVE_YEAR}`],
      ),
    );
  };

  useEffect(() => {
    fetchTaxProfile();
  }, []);

  const styles = useStyles({});

  return (
    <div className={styles.container}>
      <div className={styles.taxProfileDetailsContainer}>
        {sectionOrdering.map((sectionName) =>
          taxProfile[sectionName] ? (
            <div style={{marginBottom: '20px'}}>
              <h2>{sectionName}</h2>
              {/* @ts-ignore */}
              {taxProfile[sectionName].map(
                (questionObject: GenerateTaxProfileFieldProps) => (
                  <GenerateTaxProfileField {...questionObject} />
                ),
              )}
            </div>
          ) : null,
        )}
      </div>
      <div className={styles.lengendContainer}>
        <Typography fontWeight={'600'}>Legends for dotted boxes</Typography>
        <Typography color={'red'}>Composite Fields</Typography>
        <Typography color={'blue'}>List</Typography>
        <Typography color={'black'}>Composite Fields and List</Typography>
        <Typography color={'Green'}>
          List of objects, object is primitive key-value
        </Typography>
        <Typography color={'pink'}>Nested object</Typography>
      </div>
    </div>
  );
};

export default CpaCenterTaxProfile;
