import {Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isCpaCenterV2StepComplete} from 'src/CpaCenterList/components/cpaList.utils';
import {TAX_FILING_STATUS} from 'src/constants/constants';
import Box from 'src/DesignSystem/Box/Box';
import DSButton from 'src/DesignSystem/Button/Button';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {NotificationType} from 'src/store/app/app.reducer';
import {fetchDocuments} from 'src/store/documents/documents.actions';
import {fetchQueries} from 'src/store/queries/queries.actions';
import {QueryStatus} from 'src/store/queries/queries.reducer';
import {selectQueriesReducer} from 'src/store/queries/queries.selector';
import {fetchTransactions} from 'src/store/transactions/transactions.actions';
import useCurrentStep from '../hooks/useCurrentStep';
import useCurrentUserId from '../hooks/useCurrentUserId';
import useLayout from '../hooks/useLayout';
import UserQueriesSection from './components/UserQueriesSection';
import {getFilteredQueries} from './UserQueries.utils';
import TaxProfileSingleSection from '../TaxProfileSections/common/TaxProfileSummaryFilter/TaxProfileSingleSection';

const UserQueries = () => {
  const dispatch = useDispatch();
  const {userId} = useCurrentUserId();
  const {notify} = useNotify();
  const {cpaFacingRetStatus, markStepComplete, isLoading} = useCurrentStep();
  const [isConfirmPopup, setIsConfirmPopup] = useState(false);
  const {queries} = useSelector(selectQueriesReducer);

  const relevantQueries = getFilteredQueries(queries);

  const isAnyUnresolvedQuery = relevantQueries.some(
    (query) => query.queryStatus !== QueryStatus.RESOLVED,
  );

  const onClosePopup = () => {
    setIsConfirmPopup(false);
  };

  const completeUserQueries = async () => {
    await markStepComplete(TAX_FILING_STATUS.PREP_STARTED);
    onClosePopup();
    notify(
      'Succesfully completed user queries step',
      NotificationType.success,
    );
  };

  const onClickNext = () => {
    if (isAnyUnresolvedQuery) {
      setIsConfirmPopup(true);
      return;
    }
    completeUserQueries();
  };

  const isEnabled =
    isCpaCenterV2StepComplete(
      cpaFacingRetStatus,
      TAX_FILING_STATUS.USER_QUERIES_SENT,
    ) &&
    !isCpaCenterV2StepComplete(
      cpaFacingRetStatus,
      TAX_FILING_STATUS.PREP_STARTED,
    ) &&
    !isLoading;

  useEffect(() => {
    dispatch(fetchQueries({userId}));
    dispatch(fetchDocuments(userId));
    dispatch(fetchTransactions(userId));
  }, []);

  const {
    USER_QUERIES_SECTION_BAR,
    spaceLeftBelowTaxFilingSteps,
    DOCUMENT_BOTTOM_BAR_HEIGHT,
  } = useLayout();

  return (
    <div>
      <div style={{display: 'flex'}}>
        <div style={{width: '70%'}}>
          {/* User Queries */}
          <UserQueriesSection />
        </div>
        <div
          style={{
            width: '30%',
          }}>
          {/* Tax Profile Summary */}
          <Box
            style={{
              height:
                spaceLeftBelowTaxFilingSteps - DOCUMENT_BOTTOM_BAR_HEIGHT,
              overflowY: 'hidden',
            }}>
            <TaxProfileSingleSection />
          </Box>
          <div>
            {isCpaCenterV2StepComplete(
              cpaFacingRetStatus,
              TAX_FILING_STATUS.PREP_STARTED,
            ) ? null : (
              <div
                style={{
                  display: 'flex',
                  height: DOCUMENT_BOTTOM_BAR_HEIGHT,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <DSButton
                  type="primary"
                  text="Start preparing the Return"
                  onClick={onClickNext}
                  disabled={!isEnabled}
                  style={{paddingInline: 80, paddingBlock: 10}}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <PopUp
        style={{width: 600}}
        isOpen={isConfirmPopup}
        primaryButtonTitle="Confirm"
        primaryButtonOnClick={completeUserQueries}
        primaryButtonDisabled={isLoading}
        secondaryButtonTitle={'Close'}
        secondaryButtonOnClick={onClosePopup}
        secondaryButtonDisabled={isLoading}
        onClose={onClosePopup}>
        <Typography style={{fontSize: 20, fontWeight: 600, marginBottom: 12}}>
          Are you sure you want to continue?
        </Typography>
        <Typography style={{marginBottom: 40}}>
          There are still some <strong>unresolved</strong> queries!!
        </Typography>
      </PopUp>
    </div>
  );
};

export default UserQueries;
