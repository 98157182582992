import {getTaxProfile} from 'src/appApi';
import {CPA_CENTER_ACTIVE_YEAR} from 'src/constants/constants';
import {SET_TAX_PROFILE} from '../actionNames';
import { setAppState } from '../app/app.actions';
import { AppReducerStates, NotificationType } from '../app/app.reducer';

const setTaxProfile = (data: any) => {
  return {
    type: SET_TAX_PROFILE,
    payload: data,
  };
};

export const fetchTaxProfile = (userId: number) => {
  return async (dispatch: any) => {
    try {
      const response = await getTaxProfile({
        years: CPA_CENTER_ACTIVE_YEAR,
        fly_user_id: userId,
      });
      dispatch(setTaxProfile(response.data));
    }
    catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to fetch tax profile (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};
