export enum TaxFormFieldIds {
  ALLOCATED_TIPS = 'allocated_tips',
  ACCOUNT_NUMBER = 'account_number',
  AGRICULTURE_PAYMENTS = 'agriculture_payments',
  TXN_IDS = 'txn_ids',
  BOX_12_A = 'box_12_a',
  BOX_12_B = 'box_12_b',
  BOX_12_C = 'box_12_c',
  BOX_12_D = 'box_12_d',
  BOX_2_AMOUNT = 'box_2_amount',
  BOX_2_TRADE_OR_BUSINESS_INCOME = 'box_2_trade_or_business_income',
  BOND_PREMIUM = 'bond_premium',
  BORROWER_ADDRESS = 'borrower_address',
  BORROWER_CITY = 'borrower_city',
  BORROWER_NAME = 'borrower_name',
  BORROWER_STATE = 'borrower_state',
  BORROWER_TIN = 'borrower_tin',
  BORROWER_ZIP_CODE = 'borrower_zip_code',
  CONTROL_NUMBER = 'control_number',
  CROP_INSURANCE_PROCEEDS = 'crop_insurance_proceeds',
  CUSIP_NO = 'cusip_no',
  COLLECTIBLES_GAIN = 'collectibles_gain',
  CASH_LIQUIDATION_DISTRIBUTIONS = 'cash_liquidation_distributions',
  COVERED_INDIVIDUAL_DETAILS = 'covered_individual_details',
  DEPENDENT_CARE_BENEFITS = 'dependent_care_benefits',
  DIRECT_SALES_MTE_5000 = 'direct_sales_mte_5000',
  DIVIDENDS_OR_INTEREST_SUBSTITUTE_PAYMENTS = 'dividends_or_interest_substitute_payments',
  DATE_OF_BIRTH = 'date_of_birth',
  DL_EXPIRY_DATE = 'dl_expiry_date',
  DL_NUMBER = 'dl_number',
  ISSUE_DATE = 'issue_date',
  DOC_NUMBER = 'doc_number',
  IS_SPOUSE = 'is_spouse',
  DISTRIBUTION_CODES = 'distribution_codes',
  DATE_OF_PURCHASE = 'date_of_purchase',
  DATE_PUT_IN_SERVICE = 'date_put_in_service',
  EMPLOYEE_ADDRESS = 'employee_address',
  EMPLOYEE_CITY = 'employee_city',
  EMPLOYEE_FIRST_NAME = 'employee_first_name',
  EMPLOYEE_LAST_NAME = 'employee_last_name',
  EMPLOYEE_SSN = 'employee_ssn',
  EMPLOYEE_STATE = 'employee_state',
  EMPLOYEE_ZIP_CODE = 'employee_zip_code',
  EMPLOYEE_CONTRIBUTIONS = 'employee_contributions',
  EMPLOYER_ADDRESS = 'employer_address',
  EMPLOYER_CITY = 'employer_city',
  EMPLOYER_EIN = 'employer_ein',
  EMPLOYER_NAME = 'employer_name',
  EMPLOYER_STATE = 'employer_state',
  EMPLOYER_STATE_ID_NUMBER = 'employer_state_id_number',
  EMPLOYER_ZIP_CODE = 'employer_zip_code',
  EXCESS_GOLDEN_PARACHUTE_PAYMENTS = 'excess_golden_parachute_payments',
  EARLY_WITHDRAWAL_PENALTY = 'early_withdrawal_penalty',
  EXEMPT_INTEREST_DIVIDENDS = 'exempt_interest_dividends',
  FEDERAL_WITHHELD = 'federal_withheld',
  FISHING_BOAT_PROCEEDS = 'fishing_boat_proceeds',
  FISH_PURCHASED_FOR_RESALE = 'fish_purchased_for_resale',
  FATCA_FILING_REQUIREMENT = 'fatca_filing_requirement',
  FILER_ADDRESS = 'filer_address',
  FILER_CITY = 'filer_city',
  FILER_NAME = 'filer_name',
  FILER_STATE = 'filer_state',
  FILER_ZIP_CODE = 'filer_zip_code',
  FILER_FIN = 'filer_fin',
  FILER_TIN = 'filer_tin',
  GROSS_PROCEEDS_TO_ATTORNEY = 'gross_proceeds_to_attorney',
  GRADUATE_STUDENT = 'graduate_student',
  GROSS_DISTRIBUTION = 'gross_distribution',
  INTEREST_INCOME = 'interest_income',
  INVESTMENT_EXPENSES = 'investment_expenses',
  INCLUDES_AMOUNTS_CURR_YEAR_ACADEMIC = 'includes_amounts_curr_year_academic',
  INSURANCE_CONTRACT_REFUNDS = 'insurance_contract_refunds',
  IRR_ALLOCABLE_AMOUNT = 'irr_allocable_amount',
  IRA_SEP_SIMPLE = 'ira_sep_simple',
  ITEM_NAME = 'item_name',
  ITEM_VALUE = 'item_value',
  INTEREST_PAID = 'interest_paid',
  LOCAL_INCOME_TAX = 'local_income_tax',
  LOCAL_WAGES_TIPS_ETC = 'local_wages_tips_etc',
  LOCALITY_NAME = 'locality_name',
  LOCAL_WITHHELD = 'local_withheld',
  LOCAL_DISTRIBUTION = 'local_distribution',
  LOAN_AMOUNT_PAID = 'loan_amount_paid',
  MEDICARE_TAX_WITHHELD = 'medicare_tax_withheld',
  MEDICARE_WAGES_N_TIPS = 'medicare_wages_n_tips',
  MEDICAL_CARE_PAYMENTS = 'medical_care_payments',
  MARKET_DISCOUNT = 'market_discount',
  MARKET_GAIN = 'market_gain',
  MARKETPLACE_IDENTIFIER = 'marketplace_identifier',
  MARKETPLACE_APN = 'marketplace_apn',
  MORTGAGE_INTEREST_RECEIVED = 'mortgage_interest_received',
  MORTGAGE_ORIGINATION_DATE = 'mortgage_origination_date',
  MORTGAGE_INSURANCE_PREMIUMS = 'mortgage_insurance_premiums',
  MORTGAGE_SECURING_PROPERTY_ADDRESS = 'mortgage_securing_property_address',
  MORTGAGE_TYPE = 'mortgage_type',
  NON_EMPLOYEE_COMPENSATION = 'non_employee_compensation',
  NON_QUALIFIED_PLANS = 'non_qualified_plans',
  NON_QUALIFIED_DEFERRED_COMPENSATION = 'non_qualified_deferred_compensation',
  NON_DIVIDEND_DISTRIBUTIONS = 'non_dividend_distributions',
  NON_CASH_LIQUIDATION_DISTRIBUTIONS = 'non_cash_liquidation_distributions',
  OTHER = 'other',
  OTHER_INCOME = 'other_income',
  OUTSTANDING_MORTGAGE_PRINCIPAL = 'outstanding_mortgage_principal',
  PAYER_ADDRESS = 'payer_address',
  PAYER_CITY = 'payer_city',
  PAYER_NAME = 'payer_name',
  PAYER_STATE = 'payer_state',
  PAYER_TIN = 'payer_tin',
  PAYER_ZIP_CODE = 'payer_zip_code',
  PAID_FOREIGN_TAX = 'paid_foreign_tax',
  POSSESSION = 'possession',
  PRIVATE_ACTIVITY_BOND_INTEREST = 'private_activity_bond_interest',
  PCR_TOTAL_DISTRIBUTION = 'pcr_total_distribution',
  PAYMENT_DATE = 'payment_date',
  PRIOR_YEAR_ADJUSTMENTS = 'prior_year_adjustments',
  POLICY_ISSUER_NAME = 'policy_issuer_name',
  POLICY_START_DATE = 'policy_start_date',
  POLICY_TERMINATION_DATE = 'policy_termination_date',
  PROPERTIES_SECURING_MORTGAGE = 'properties_securing_mortgage',
  PRINCIPAL_RESIDENCE_PURCHASE_POINTS = 'principal_residence_purchase_points',
  QUALIFIED_DIVIDENDS = 'qualified_dividends',
  RECIPIENT_ADDRESS = 'recipient_address',
  RECIPIENT_CITY = 'recipient_city',
  RECIPIENT_NAME = 'recipient_name',
  RECIPIENT_SPOUSE_NAME = 'recipient_spouse_name',
  RECIPIENT_SPOUSE_SSN = 'recipient_spouse_ssn',
  RECIPIENT_SPOUSE_DOB = 'recipient_spouse_dob',
  RECIPIENT_STATE = 'recipient_state',
  RECIPIENT_TIN = 'recipient_tin',
  RECIPIENT_SSN = 'recipient_ssn',
  RECIPIENT_DOB = 'recipient_dob',
  RECIPIENT_ZIP_CODE = 'recipient_zip_code',
  RETIREMENT_PLAN = 'retirement_plan',
  RENTS = 'rents',
  ROYALTIES = 'royalties',
  RECEIVED_TUITION_RELATED_PAYMENT = 'received_tuition_related_payment',
  ROTH_CONTRIBUTION = 'roth_contribution',
  RTAA_PAYMENTS = 'rtaa_payments',
  REFUND_OF_OVERPAID_INTEREST = 'refund_of_overpaid_interest',
  SS_TAX_WITHHELD = 'ss_tax_withheld',
  SS_TIPS = 'ss_tips',
  SS_WAGES = 'ss_wages',
  STATE = 'state',
  STATE_INCOME = 'state_income',
  STATE_INCOME_TAX = 'state_income_tax',
  STATE_WAGES_TIPS_ETC = 'state_wages_tips_etc',
  STATE_WITHHELD = 'state_withheld',
  STATUTORY_EMPLOYEE = 'statutory_employee',
  SECTION_409A_DEFERRALS = 'section_409a_deferrals',
  SECOND_TIN_NOT = 'second_tin_not',
  STATE_ID_NO = 'state_id_no',
  STUDENT_LOAN_INTEREST = 'student_loan_interest',
  STUDENT_LOAN_INTEREST_CHECKBOX = 'student_loan_interest_checkbox',
  STUDENT_NAME = 'student_name',
  STUDENT_ADDRESS = 'student_address',
  STUDENT_CITY = 'student_city',
  STUDENT_STATE = 'student_state',
  STUDENT_TIN = 'student_tin',
  STUDENT_ZIP_CODE = 'student_zip_code',
  SERVICE_PROVIDER_NO = 'service_provider_no',
  STUDENT_HALF_TIME = 'student_half_time',
  SCHOLARSHIPS_GRANTS = 'scholarships_grants',
  SEC_1250_GAIN = 'sec_1250_gain',
  SECTION_1202_GAIN = 'section_1202_gain',
  SECTION_897_DIVIDENDS = 'section_897_dividends',
  SECTION_897_CAPITAL_GAIN = 'section_897_capital_gain',
  SECTION_199A_DIVIDENDS = 'section_199a_dividends',
  SCHOLARSHIPS_GRANTS_ADJUSTMENTS = 'scholarships_grants_adjustments',
  STATE_DISTRIBUTION = 'state_distribution',
  STATE_TAX_REFUND_CREDIT_OFFSET = 'state_tax_refund_credit_offset',
  THIRD_PARTY_SICK_PAY = 'third_party_sick_pay',
  TAX_EXEMPT_INTEREST = 'tax_exempt_interest',
  TREASURY_OBLIGATION_BOND_PREMIUM = 'treasury_obligation_bond_premium',
  TAX_EXEMPT_BOND_PREMIUM = 'tax_exempt_bond_premium',
  TOTAL_ORDINARY_DIVIDENDS = 'total_ordinary_dividends',
  TOTAL_CAPITAL_GAIN = 'total_capital_gain',
  TAXABLE_AMOUNT = 'taxable_amount',
  TAXABLE_AMOUNT_NOT_DETERMINED = 'taxable_amount_not_determined',
  TOTAL_DISTRIBUTION = 'total_distribution',
  TOTAL_EMPLOYEE_CONTRIBUTIONS = 'total_employee_contributions',
  TAXABLE_GRANTS = 'taxable_grants',
  UNEMPLOYMENT_COMPENSATION = 'unemployment_compensation',
  US_BOND_INTEREST = 'us_bond_interest',
  UNREALIZED_APPRECIATION = 'unrealized_appreciation',
  VEHICLE_EXPENSE_TYPE = 'vehicle_expense_type',
  TOTAL_MILES = 'total_miles',
  BUSINESS_MILES = 'business_miles',
  WAGES_TIPS_ETC = 'wages_tips_etc',
  JAN_ENROLLMENT_PREMIUMS = 'jan_enrollment_premiums',
  JAN_SLCSP = 'jan_slcsp',
  JAN_ADVANCE_PAYMENT = 'jan_advance_payment',
  FEB_ENROLLMENT_PREMIUMS = 'feb_enrollment_premiums',
  FEB_SLCSP = 'feb_slcsp',
  FEB_ADVANCE_PAYMENT = 'feb_advance_payment',
  MAR_ENROLLMENT_PREMIUMS = 'mar_enrollment_premiums',
  MAR_SLCSP = 'mar_slcsp',
  MAR_ADVANCE_PAYMENT = 'mar_advance_payment',
  APR_ENROLLMENT_PREMIUMS = 'apr_enrollment_premiums',
  APR_SLCSP = 'apr_slcsp',
  APR_ADVANCE_PAYMENT = 'apr_advance_payment',
  MAY_ENROLLMENT_PREMIUMS = 'may_enrollment_premiums',
  MAY_SLCSP = 'may_slcsp',
  MAY_ADVANCE_PAYMENT = 'may_advance_payment',
  JUN_ENROLLMENT_PREMIUMS = 'jun_enrollment_premiums',
  JUN_SLCSP = 'jun_slcsp',
  JUN_ADVANCE_PAYMENT = 'jun_advance_payment',
  JUL_ENROLLMENT_PREMIUMS = 'jul_enrollment_premiums',
  JUL_SLCSP = 'jul_slcsp',
  JUL_ADVANCE_PAYMENT = 'jul_advance_payment',
  AUG_ENROLLMENT_PREMIUMS = 'aug_enrollment_premiums',
  AUG_SLCSP = 'aug_slcsp',
  AUG_ADVANCE_PAYMENT = 'aug_advance_payment',
  SEPT_ENROLLMENT_PREMIUMS = 'sept_enrollment_premiums',
  SEPT_SLCSP = 'sept_slcsp',
  SEPT_ADVANCE_PAYMENT = 'sept_advance_payment',
  OCT_ENROLLMENT_PREMIUMS = 'oct_enrollment_premiums',
  OCT_SLCSP = 'oct_slcsp',
  OCT_ADVANCE_PAYMENT = 'oct_advance_payment',
  NOV_ENROLLMENT_PREMIUMS = 'nov_enrollment_premiums',
  NOV_SLCSP = 'nov_slcsp',
  NOV_ADVANCE_PAYMENT = 'nov_advance_payment',
  DEC_ENROLLMENT_PREMIUMS = 'dec_enrollment_premiums',
  DEC_SLCSP = 'dec_slcsp',
  DEC_ADVANCE_PAYMENT = 'dec_advance_payment',
  PROPERTY_DESCRIPTION = 'property_description',
  ACQUIRED_DATE = 'acquired_date',
  SOLD_OR_DISPOSED_DATE = 'sold_or_disposed_date',
  PROCEEDS = 'proceeds',
  COST_OR_OTHER_BASIS = 'cost_or_other_basis',
  ACCRUED_MARKET_DISCOUNT = 'accrued_market_discount',
  WASH_SALE_LOSS_DISALLOWED = 'wash_sale_loss_disallowed',
  GAIN_OR_LOSS = 'gain_or_loss',
  PROCEEDS_FROM = 'proceeds_from',
  NON_COVERED_SECURITY = 'non_covered_security',
  REPORTED_TO_IRS = 'reported_to_irs',
  LOSS_NOT_ALLOWED = 'loss_not_allowed',
  REALIZED_PROFIT_LOSS = 'realized_profit_loss',
  PREV_UNREALIZED_PROFIT_LOSS = 'prev_unrealized_profit_loss',
  CURR_UNREALIZED_PROFIT_LOSS = 'curr_unrealized_profit_loss',
  AGGREGATE_PROFIT_LOSS = 'aggregate_profit_loss',
  BASIS_REPORTED_TO_IRS = 'basis_reported_to_irs',
  BARTERING = 'bartering',
  PAYER_IDENTIFICATION_NUMBER = 'payer_identification_number',
  WINNING_REPORTABLE = 'winning_reportable',
  DATE_WON = 'date_won',
  TYPE_OF_WAGER = 'type_of_wager',
  RACE = 'race',
  WINNINGS_FROM_IDENTICAL_WAGERS = 'winnings_from_identical_wagers',
  CASHIER = 'cashier',
  WINNERS_TAX_PAYER_IDENTIFICATION_NUMBER = 'winners_tax_payer_identification_number',
  WINDOW = 'window',
  FIRST_IDENTIFICATION = 'first_identification',
  SECOND_IDENTIFICATION = 'second_identification',
  PAYERS_STATE_IDENTIFICATION_NO = 'payers_state_identification_no',
  STATE_WINNINGS = 'state_winnings',
  LOCAL_WINNINGS = 'local_winnings',
  LOCAL_INCOME_TAX_WITHELD = 'local_income_tax_withheld',
  TRUSTEE_NAME = 'trustee_name',
  EARNINGS_ON_EXCESS_CONT = 'earnings_on_excess_cont',
  FMV_ON_DATE_OF_DEATH = 'fmv_on_date_of_death',
  AMOUNT = 'amount',
  INCOME_EARNER = 'income_earner',
  INDICATE_FILER_IS = 'indicate_filer_is',
  INDICATE_TRANSACTIONS_REPORTED = 'indicate_transactions_reported',
  PSE_NAME = 'pse_name',
  TELEPHONE_NUMBER = 'telephone_number',
  GROSS_AMOUNT_PAYMENT_CARD = 'gross_amount_payment_card',
  CARD_NOT_PRESSENT_TRANSACTIONS = 'card_not_present_transactions',
  MERCHANT_CATEGORY_CODE = 'merchant_category_code',
  NUMBER_OF_PAYMENT_TRANSACTIONS = 'number_of_payment_transactions',
  JAN = 'jan',
  FEB = 'feb',
  MAR = 'mar',
  APR = 'apr',
  MAY = 'may',
  JUNE = 'june',
  JULY = 'july',
  AUG = 'aug',
  SEPT = 'sept',
  OCT = 'oct',
  NOV = 'nov',
  DEC = 'dec',
  LINE_5 = 'line_5',
  BUSINESS_ID = 'business_id',
  BUSINESS_NAME = 'business_name',
  BUSINESS_OWNER_PARTNER_NAME = 'business_owner_partner_name',
  MEMBER_TYPE = 'member_type',
  TAX_RETURN = 'tax_return',
  BUSINESS_EIN = 'business_ein',
  RETURN_FILING_DATE = 'return_filing_date',
  NOTICE_DATE = 'notice_date',
  BUSINESS_ADDRESS = 'business_address',
  INCORPORATION_DATE = 'incorporation_date',
  ACCOUNTING_METHOD = 'accounting_method',
  BUSINESS_ACTIVITY_CODE = 'business_activity_code',
  BUSINESS_ACTIVITY = 'business_activity',
  PRODUCT_OR_SERVICE = 'product_or_service',
  ENTITY_SHAREHOLDERS = 'entity_shareholders',
  INDIVIDUAL_SHAREHOLDERS = 'individual_shareholders',
  S_ELECTION_EFFECTIVE_DATE = 's_election_effective_date',
  BUSINESS_START_DATE = 'business_start_date',
  PARTNERS = 'partners',
  PAYMENT_TYPE = 'payment_type',
  TXN_ID = 'txn_id',
  COUNTY_NAME = 'county_name',
  SCHOOL_DISTRICT_CODE = 'school_district_code',
  DEPENDENT_FIRST_NAME = 'dependent_first_name',
  DEPENDENT_LAST_NAME = 'dependent_last_name',
  DEPENDENT_SSN = 'dependent_social_security_number',
  DEPENDENT_RELATION = 'relationship_to_you',
  DEPENDENT_BIRTHDATE = 'dependent_birthdate',
  DEPENDENT_US_CITIZEN = 'dependent_us_citizen',
  DEPENDENT_ITIN_NUMBER = 'dependent_itin_number',
  DEPENDENT_HAVE_SSN = 'dependent_have_ssn',
  DEPENDENT_ID = 'dependent_id',
  MORE_THAN_HALF_YEAR_SUPPORT = 'more_than_half_year_support',
  LIVED_WITH_YOU_OR_SCHOOL_FOR_HALF_YEAR = 'lived_with_you_or_school_for_half_year',
  LIVED_IN_HOUSECARE_FOR_HALF_YEAR = 'lived_in_house_care_for_half_year',
  IS_STUDENT = 'is_student',
  STUDENT_ELIGIBILITY = 'student_eligibility',
  IS_ADOPTED = 'is_adopted',
  IS_INCAPABLE = 'is_incapable',
  DID_EARN_MORE_THAN_4700 = 'did_earn_more_than_4700',
  IS_FILING_JOINT_TAX_RETURN_THIS_YEAR = 'is_filing_joint_tax_return_this_year',
  IS_FULL_TIME_STUDENT = 'is_full_time_student',
  LIVED_WITH_TAX_PAYER_FOR_WHOLE_YEAR = 'lived_with_tax_payer_for_whole_year',

  TAX_FILING_YEAR = 'tax_filing_year',
  ANNUAL_COVERAGE_INFO = 'annual_coverage_info',
  MAKE_YEAR = 'make_year',
  NUMBER_AND_STREET = 'number_and_street',
  APARTMENT_NUMBER = 'apartment_number',
  CITY = 'city',
  ZIP_CODE = 'zip_code',
  LIVED_FOR = 'lived_for',
  INCOME_EARNED = 'income_earned',
  TYPE_OF_PROPERTY = 'type_of_property',
  NUMBER_OF_DAYS_RENTED_FOR = 'number_of_days_rented_for',
  LINKED_MISC_FORMS = 'linked_misc_forms',
  INCOME_TYPE = 'income_type',
  RENTAL_PROPERTY_NAME = 'rental_property_name',
  LONG_TERM_NET_GAIN = 'long_term_net_gain',
  LONG_TERM_NET_LOSS = 'long_term_net_loss',
  SHORT_TERM_NET_GAIN = 'short_term_net_gain',
  SHORT_TERM_NET_LOSS = 'short_term_net_loss',
  UNDETERMINED_NET_GAIN = 'undetermined_net_gain',
  UNDETERMINED_NET_LOSS = 'undetermined_net_loss',
  ContributionType = 'contribution_type',
}

export enum MortgageTypes {
  HOME = 'HOME',
  OFFICE = 'OFFICE',
  HOME_OFFICE = 'HOME_OFFICE',
}

export enum QuarterlyPaymentType {
  FEDERAL = 'FEDERAL',
  STATE = 'STATE',
  CITY = 'CITY',
  BUSINESS_FEDERAL = 'BUSINESS_FEDERAL',
  BUSINESS_STATE = 'BUSINESS_STATE',
  BUSINESS_CITY = 'BUSINESS_CITY',
}
