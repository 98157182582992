import React from 'react';
import {Button, IconButton, Typography} from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

type Props = {
  pageCount: number;
  isLoading: boolean;
  isPrev: boolean;
  isNext: boolean;
  totalPages: number;
  onIncrementPage: () => void;
  onDecrementPage: () => void;
  onGotoFirstPage: () => void;
  onGotoLastPage: () => void;
};

const Pagination = ({
  pageCount,
  isLoading,
  isPrev,
  isNext,
  totalPages,
  onIncrementPage,
  onGotoLastPage,
  onGotoFirstPage,
  onDecrementPage,
}: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        marginTop: 12,
        marginRight: 6,
      }}>
      <Button
        disabled={isLoading || !isPrev || pageCount <= 1}
        variant="contained"
        onClick={onGotoFirstPage}>
        Skip to First
      </Button>
      <IconButton
        disabled={isLoading || pageCount <= 1 || !isPrev}
        onClick={onDecrementPage}>
        <ArrowLeftIcon />
      </IconButton>
      <Typography
        style={{
          alignItems: 'center',
          display: 'inline-flex',
          marginLeft: 4,
          marginRight: 4,
        }}>
        {pageCount} of {totalPages}
      </Typography>
      <IconButton disabled={isLoading || !isNext} onClick={onIncrementPage}>
        <ArrowRightIcon />
      </IconButton>
      <Button
        disabled={isLoading || !isNext || pageCount >= totalPages}
        variant="contained"
        onClick={onGotoLastPage}>
        Skip to Last
      </Button>
    </div>
  );
};

export default Pagination;
