import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React, {useContext, useState} from 'react';
import {useParams} from 'react-router-dom';
import {postDocumentStatus, postTaxQuery} from 'src/appApi';
import FullScreenLoading from 'src/common/FullScreenLoading';
import RichTextEditor from 'src/DesignSystem/RichTextEditor/RichTextEditor';
import {
  CPA_CENTER_ACTIVE_YEAR,
  CPAQuestionType,
  themmeColor,
  VALID_DOCUMENT_REASON,
  VALID_DOCUMENT_STATUS,
} from 'src/constants/constants';
import DocumentsContext, {
  Document,
} from 'src/CpaCenterUserInfo/CpaCenterDocumentsOCR/DocumentsContext';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 540,
    padding: 24,
    borderRadius: 16,
    backgroundColor: themmeColor.white,
  },
  buttonsContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    marginTop: 12,
  },
});

interface ActionRequiredOverlayProps {
  isVisible: boolean;
  handleClose: () => void;
  document: Document;
}

const ActionRequiredOverlay = ({
  isVisible,
  handleClose,
  document,
}: ActionRequiredOverlayProps) => {
  const {flyfin_user_id} = useParams();
  const {fetchDocuments} = useContext(DocumentsContext);
  const [loading, setLoading] = useState(false);

  const documentReasonOptions = [
    VALID_DOCUMENT_REASON.DOC_NOT_CLEAR,
    VALID_DOCUMENT_REASON.INFO_MISMATCH,
    VALID_DOCUMENT_REASON.WRONG_DOC,
    VALID_DOCUMENT_REASON.INCOMPLETE_DOC,
  ];

  const [documentReason, setDocumentReason] = useState(
    document.reason ?? null,
  );
  const [selectedFields, setSelectedFields] = useState<Array<string>>([]);
  const [comment, setComment] = useState('');

  const handleDocumentReasonUpdate = (
    newValue: VALID_DOCUMENT_REASON | null,
  ) => setDocumentReason(newValue);

  const handleFieldSelection = (checked: boolean, label: string) => {
    if (!checked) {
      setSelectedFields(selectedFields.filter((field) => field !== label));
    } else {
      setSelectedFields([...selectedFields, label]);
    }
  };

  const onSend = async () => {
    try {
      setLoading(true);
      await postDocumentStatus({
        tfd_info_id: document.documentId ?? -1,
        status: VALID_DOCUMENT_STATUS.ACTION_REQUIRED,
        reason: documentReason ?? undefined,
      });
      await postTaxQuery({
        fly_user_id: flyfin_user_id ?? '',
        query: comment,
        app_year: CPA_CENTER_ACTIVE_YEAR,
        type: CPAQuestionType.TAX_FILING,
        sub_type: documentReason,
        tfd_tag_id: document.documentTypeId,
        tfd_info_id: document.documentId,
        extra_data: {
          info_mismatch_fields: selectedFields,
        },
      });
      await fetchDocuments();
      handleClose();
    } catch (e) {
      alert('something went wrong');
    } finally {
      setLoading(false);
    }
  };

  const styles = useStyles({});

  return (
    <>
      {loading && <FullScreenLoading open={loading} />}
      <Modal
        className={styles.modal}
        open={isVisible}
        disableEnforceFocus
        onClose={handleClose}>
        <div className={styles.modalContainer}>
          <Typography fontWeight={600} fontSize={20}>
            Select the action
          </Typography>
          <Typography color={themmeColor.grey} fontSize={14}>
            Please select appropriate reason for user to act upon
          </Typography>
          <Autocomplete
            style={{width: '500px', marginTop: 20, marginBottom: 16}}
            value={documentReason ?? null}
            onChange={(e, v) => handleDocumentReasonUpdate(v)}
            options={documentReasonOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: {fontSize: 12},
                }}
                label="Reason"
              />
            )}
          />
          <div>
            {documentReason === VALID_DOCUMENT_REASON.INFO_MISMATCH &&
              document.taxProfileFields.map((field) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) =>
                        handleFieldSelection(e.target.checked, field.label)
                      }
                      checked={selectedFields.includes(field.label)}
                    />
                  }
                  label={field.label}
                />
              ))}
          </div>
          <RichTextEditor message={comment} setMessage={setComment} />
          <div className={styles.buttonsContainer}>
            <Button
              disabled={loading}
              onClick={handleClose}
              style={{flex: 0.45}}
              variant="outlined">
              Cancel
            </Button>
            <Button
              disabled={loading || comment.length === 0 || !documentReason}
              onClick={onSend}
              style={{flex: 0.45}}
              variant="contained">
              Send
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ActionRequiredOverlay;
