import {get} from 'lodash';
import {CPA_CENTER_ACTIVE_YEAR} from 'src/constants/constants';
import {TAX_PROFILE_FIELDS} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import {ReduxStateType} from '../store';

export const selectTaxProfileAnswer: (
  state: ReduxStateType,
  questionId: TAX_PROFILE_FIELDS,
) => any = (state, questionId) => {
  return get(
    state.taxProfile[CPA_CENTER_ACTIVE_YEAR].find(
      (item) => item.question_id === questionId,
    ),
    'answer',
    undefined,
  );
};

export const selectTaxProfile = (state: ReduxStateType) =>{
  return state.taxProfile
}
