import {useState} from 'react';
import {
  deleteCpaQueryMessage,
  patchCpaQueryMessage,
  postDraftMessage,
  postMessage,
  postTaxQuery,
} from 'src/appApi';
import {CPA_CENTER_ACTIVE_YEAR} from 'src/constants/constants';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import {
  MessageAuthorType,
  MessageType,
  QuerySubType,
  QueryType,
} from 'src/store/queries/queries.reducer';
import useCurrentUserId from './useCurrentUserId';

const useQueryAction = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {userId} = useCurrentUserId();
  const {notify} = useNotify();

  const createUploadDocumentQuery = async ({
    message,
    title,
    doc_type,
    isDraft,
  }: {
    message: string;
    title?: string;
    doc_type?: string;
    isDraft?: boolean;
  }) => {
    try {
      setIsLoading(true);
      await postTaxQuery({
        fly_user_id: userId,
        description: message,
        doc_type: doc_type,
        type: QueryType.TAX_FILING,
        sub_type: QuerySubType.ADDITIONAL_DOC,
        app_year: CPA_CENTER_ACTIVE_YEAR,
        query: title,
        is_draft: isDraft,
      });
      notify(`Query created`, NotificationType.success);
    } catch (e) {
      notify(`Failed to create query ${e}`, NotificationType.error);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const createTaxProfileQuery = async ({
    message,
    taxFilingSection,
    isDraft,
  }: {
    message: string;
    taxFilingSection: string;
    isDraft?: boolean;
  }) => {
    try {
      setIsLoading(true);
      await postTaxQuery({
        fly_user_id: userId,
        description: message,
        type: QueryType.TAX_FILING,
        sub_type: QuerySubType.TAX_PROFILE_CPA,
        section: taxFilingSection,
        app_year: CPA_CENTER_ACTIVE_YEAR,
        is_draft: isDraft,
      });
      notify(`Query created`, NotificationType.success);
    } catch (e) {
      notify(`Failed to create query ${e}`, NotificationType.error);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const createTaxFilingGeneralQuery = async ({
    message,
    taxFilingSection,
    title,
    isDraft
  }: {
    title?: string;
    taxFilingSection?: string;
    message: string;
    isDraft?: boolean;
  }) => {
    try {
      setIsLoading(true);
      await postTaxQuery({
        fly_user_id: userId,
        description: message,
        type: QueryType.TAX_FILING,
        sub_type: QuerySubType.GENERAL,
        section: taxFilingSection,
        app_year: CPA_CENTER_ACTIVE_YEAR,
        query: title,
        is_draft: isDraft,
      });
      notify(`Query created`, NotificationType.success);
    } catch (e) {
      notify(`Failed to create query ${e}`, NotificationType.error);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const createReuploadDocumentQuery = async ({
    message,
    sub_type,
    doc_type_id,
    doc_id,
    isDraft,
  }: {
    doc_type_id: number;
    doc_id: number;
    message: string;
    sub_type:
      | QuerySubType.DOC_NOT_CLEAR
      | QuerySubType.INCOMPLETE_DOC
      | QuerySubType.WRONG_DOC;
    isDraft?: boolean;
  }) => {
    try {
      setIsLoading(true);
      await postTaxQuery({
        fly_user_id: userId,
        description: message,
        app_year: CPA_CENTER_ACTIVE_YEAR,
        type: QueryType.TAX_FILING,
        sub_type,
        tfd_info_id: doc_id,
        tfd_tag_id: doc_type_id,
        is_draft: isDraft,
      });
      notify(`Query created`, NotificationType.success);
    } catch (e) {
      notify(`Failed to create query ${e}`, NotificationType.error);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const createHighValueDeductionQuery = async ({
    message,
    txn_ids,
    isDraft,
  }: {
    message: string;
    txn_ids: number[];
    isDraft?: boolean;
  }) => {
    try {
      setIsLoading(true);
      await postTaxQuery({
        fly_user_id: userId,
        type: QueryType.TAX_FILING,
        sub_type: QuerySubType.HIGH_VALUE_DEDUCTIONS,
        description: message,
        txn_ids,
        app_year: CPA_CENTER_ACTIVE_YEAR,
        is_draft: isDraft,
      });
      notify(`Query created`, NotificationType.success);
    } catch (e) {
      notify(`Failed to create query ${e}`, NotificationType.error);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const createMessage = async ({
    author_type = MessageAuthorType.CPA,
    queryId,
    is_draft = false,
    message,
    messageType = MessageType.CHAT,
  }: {
    author_type?: MessageAuthorType;
    queryId: number;
    is_draft?: boolean;
    message: string;
    messageType?: MessageType;
  }) => {
    try {
      setIsLoading(true);
      await postMessage({
        author_type,
        cpa_question_id: queryId,
        is_draft,
        message,
        type: messageType,
      });
      notify(`Message sent`, NotificationType.success);
    } catch (e) {
      notify(`Failed to send message ${e}`, NotificationType.error);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const patchMessage = async ({
    messageId,
    message,
  }: {
    messageId: number;
    message: string;
  }) => {
    try {
      setIsLoading(true);
      await patchCpaQueryMessage(messageId, {message});
      notify(`Message updated`, NotificationType.success);
    } catch (e) {
      notify(`Failed to update message ${e}`, NotificationType.error);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const deleteMessage = async ({messageId}: {messageId: number}) => {
    try {
      setIsLoading(true);
      await deleteCpaQueryMessage(messageId);
      notify(`Message deleted`, NotificationType.success);
    } catch (e) {
      notify(`Failed to delete message ${e}`, NotificationType.error);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const sendDraftMessageToUser = async ({messageId}: {messageId: number}) => {
    try {
      setIsLoading(true);
      await postDraftMessage({draft_id: messageId});
      notify(`Message sent to user`, NotificationType.success);
    } catch (e) {
      notify(
        `Failed to send draft message to user ${e}`,
        NotificationType.error,
      );
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    createUploadDocumentQuery,
    createTaxProfileQuery,
    createTaxFilingGeneralQuery,
    createReuploadDocumentQuery,
    createHighValueDeductionQuery,
    createMessage,
    patchMessage,
    deleteMessage,
    sendDraftMessageToUser,
    isLoading,
  };
};
export default useQueryAction;
