import React, {useState} from 'react';
import {Typography} from '@mui/material';
import {
  CPA_ACK_TEMPLATE_NAME,
  CPA_CENTER_ACTIVE_YEAR,
  CPA_TEMPLATE_SAMPLE_MESSAGE,
  themmeColor,
} from 'src/constants/constants';
import DSButton from 'src/DesignSystem/Button/Button';
import Drawer from 'src/DesignSystem/Drawer/Drawer';
import Dropdown from 'src/DesignSystem/Dropdown/Dropdown';
import {postCpaAcknowledgementQuery} from 'src/appApi';
import MessageTextBox from 'src/CpaCenterV2/common/MessageTextBox/MessageTextBox';

interface CpaAcknowledgementDrawerProps {
  isVisible: boolean;
  selectedUsers: number[];
  onClose: () => void;
  onSendSideEffect?: () => void;
}

const CpaAcknowledgementDrawer = ({
  isVisible,
  selectedUsers,
  onClose,
  onSendSideEffect,
}: CpaAcknowledgementDrawerProps) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [cpaAckTemplateName, setCpaAckTemplateName] =
    useState<CPA_ACK_TEMPLATE_NAME | null>(null);

  const onCloseDrawer = () => {
    setMessage('');
    onClose();
  };

  const handleTemplateChange = (value: CPA_ACK_TEMPLATE_NAME) => {
    setCpaAckTemplateName(value);
    setMessage(CPA_TEMPLATE_SAMPLE_MESSAGE[value]);
  };

  const sendMessage = async () => {
    setLoading(true);
    try {
      if (cpaAckTemplateName != null) {
        await postCpaAcknowledgementQuery({
          user_ids: selectedUsers,
          app_year: CPA_CENTER_ACTIVE_YEAR,
          message: message,
          template_name: cpaAckTemplateName,
        });
        alert('Success!! Acknowledgement Message sent');
        onCloseDrawer();
        if (onSendSideEffect) {
          onSendSideEffect();
        }
      } else {
        alert('CPA Acknowledgement Template type is not selected');
      }
    } catch (e) {
      alert('Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      backgroundColor={'white'}
      width={500}
      height={'100vh'}
      isOpen={isVisible}
      direction="left">
      <div
        style={{
          backgroundColor: 'white',
          marginTop: 4,
          padding: 24,
        }}>
        <Typography style={{fontSize: 20, fontWeight: 600}}>
          Send acknowledgement
        </Typography>
        <div>
          <Typography
            style={{fontSize: 14, color: themmeColor.grey, marginBottom: 8}}>
            Selected users will get the following message.
          </Typography>
          {/* CPA Ack Template Dropdown */}
          <div style={{marginBottom: 12}}>
            <Dropdown
              maxWidth
              options={Object.values(CPA_ACK_TEMPLATE_NAME)}
              placeholder={''}
              onChange={handleTemplateChange}
              value={cpaAckTemplateName}
              disabled={loading}
            />
          </div>
          <div style={{marginBlock: 12}}>
            <MessageTextBox
              message={message}
              setMessage={setMessage}
              placeholder={
                'Describe the issues properly so that users understand what to do!'
              }
              isLoading={loading}
              height={200}
              onSend={() => sendMessage()}
              inputMode={
                MessageTextBox.MessageTextBoxInputMode
                  .SEND_MESSAGE_WITH_NO_DRAFT
              }
              sendDisabled={!cpaAckTemplateName}
            />
          </div>
        </div>
        <div
          style={{display: 'flex', justifyContent: 'space-between', flex: 1}}>
          <DSButton
            style={{flex: 1}}
            type="secondary"
            text="Close"
            disabled={loading}
            onClick={onCloseDrawer}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default CpaAcknowledgementDrawer;
