import {getHighValueDeductions} from 'src/appApi';
import {CPA_CENTER_ACTIVE_YEAR} from 'src/constants/constants';
import {SET_HIGH_VALUE_DEDUCTIONS} from '../actionNames';
import {setAppState} from '../app/app.actions';
import {AppReducerStates, NotificationType} from '../app/app.reducer';
import {parseSingleTransaction} from '../transactions/transactions.utils';
import {HighValueDeductionStateType} from './highValueDeductions.reducer';

const setHighValueDeductions = (
  payload: Pick<HighValueDeductionStateType, 'deductions'>,
) => {
  return {
    type: SET_HIGH_VALUE_DEDUCTIONS,
    payload,
  };
};

export const fetchHighValueDeductions = (user_id: number) => {
  return async (dispatch: any) => {
    try {
      const {data} = await getHighValueDeductions({
        user_id,
        app_year: CPA_CENTER_ACTIVE_YEAR,
      });
      const deductions = data.map((backendTxn) =>
        parseSingleTransaction(backendTxn),
      );
      dispatch(setHighValueDeductions({deductions}));
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to fetch high value deductions (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};
