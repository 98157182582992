import {
  CPAQuestionType,
  QUERY_STATUS,
  VALID_CPA_EMAILS,
} from 'src/constants/constants';

export enum headerCellId {
  FLYFIN_USER_NAME = 'name',
  STATUS = 'status',
  TYPE = 'type',
  CREATED_AT = 'created_at',
  CPA_RETURN_STATUS = 'cpa_facing_ret_status',
  UPDATED_AT = 'status_updated_at',
  CLARITY_RATING = 'avg_clarity_rating',
  CORRECTNESS_RATING = 'avg_correctness_rating',
  GRAMMAR_RATING = 'avg_grammar_rating',
  ASSIGNEE_EMAIL = 'assignee_email',
  YEAR = 'app_year',
}

export const DEFAULT_PER_PAGE = 20;

export const SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
} as const;

export const sortFields = {
  [headerCellId.STATUS]: 'status',
  [headerCellId.CREATED_AT]: 'created_at',
  [headerCellId.UPDATED_AT]: 'status_updated_at',
};

export enum filterFields {
  TYPE = 'type',
  YEAR = 'app_year',
  RATED_CONVERSATION = 'rated_conversation',
  FLY_USER_NAME = 'fly_user_name',
  FLY_USER_EMAIL = 'fly_user_email',
  FLY_USER_ID = 'fly_user_id',
  STATUS = 'status',
  ASSIGNEE = 'assignee_email',
  DATE_FROM = 'date_from',
  DATE_TO = 'date_to',
  PAGE = 'page',
}

export const RatingPermissionUserEmails = [
  VALID_CPA_EMAILS.Varsha,
  VALID_CPA_EMAILS.Sachin,
  VALID_CPA_EMAILS.Aman,
  VALID_CPA_EMAILS.Jaideep,
  VALID_CPA_EMAILS.Amber,
  VALID_CPA_EMAILS.Vysak,
  VALID_CPA_EMAILS.Kuldeep,
];

export const QueryStatusText = {
  [QUERY_STATUS.DRAFT]: 'Draft',
  [QUERY_STATUS.OPEN]: 'Open',
  [QUERY_STATUS.RESOLVED]: 'Resolved',
  [QUERY_STATUS.SENT]: 'Sent',
};

export const StatusColor = {
  [QUERY_STATUS.DRAFT]: '#A37724',
  [QUERY_STATUS.OPEN]: '#A94242',
  [QUERY_STATUS.RESOLVED]: '#479758',
  [QUERY_STATUS.SENT]: '#525252',
};

export const SupportedQueryTypes = [
  CPAQuestionType.GENERAL,
  CPAQuestionType.TXN_RELATED,
  CPAQuestionType.QTC,
  CPAQuestionType.TAX_FILING_N_TAX_PROFILE,
];
