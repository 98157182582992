import {getBusinessDetails} from 'src/appApi';
import {CPA_CENTER_ACTIVE_YEAR} from 'src/constants/constants';
import {SET_BUSINESS_DETAILS} from '../actionNames';
import {setAppState} from '../app/app.actions';
import {AppReducerStates, NotificationType} from '../app/app.reducer';
import {BusinessDetails, ShareHolder} from './businessDetails.reducer';

const setBusinessDetails = (payload: any) => {
  return {
    type: SET_BUSINESS_DETAILS,
    payload,
  };
};

export const fetchBusinessDetails = (fly_user_id: number) => {
  return async (dispatch: any) => {
    try {
      const {data} = await getBusinessDetails(
        fly_user_id,
        CPA_CENTER_ACTIVE_YEAR,
      );
      const businesses: BusinessDetails[] = data.map(
        ({
          id,
          name,
          entity_type: entityType,
          is_end_user_reviewed: isEndUserReviewed,
          document_links,
          accounting_method: accountingMethod,
          owner_or_proprietor: ownerOrProprietor,
          is_registered_with_state: isRegisteredWithState,
          signing_authority_id: signingAuthorityId,
          start_date: startDate,
          s_corp_effective_date: sCorpEffectiveDate,
          ein,
          year,
          is_address_same_as_home: isSameAsHomeAddress,
          address: {
            city,
            state,
            zip_code: zipCode,
            number_and_street: numberAndStreet,
            apartment_number: apartmentNumber,
          } = {},
          have_ein: haveEin,
          share_holders,
          incorporation_date: incorporationDate,
          phone_number: phoneNumber,
          professions,
          is_spouse_business_partner,
          is_work_continuing,
          work_stop_date,
        }) => {
          const documentLinks = document_links?.map(
            ({
              doc_link: docLink,
              filename,
              form_type: formType,
              id: docId,
            }) => ({
              docLink,
              filename,
              formType,
              docId,
            }),
          );
          const shareHolders: ShareHolder[] = share_holders?.map(
            ({
              id,
              ssn,
              address: {
                city,
                state,
                zip_code: zipCode,
                number_and_street: numberAndStreet,
                apartment_number: apartmentNumber,
              } = {},
              last_name,
              ownership_pct,
              first_name_and_middle_initial,
              is_another_individual_act_on_behalf,
            }) => {
              return {
                id,
                ssn,
                address: {
                  city,
                  state,
                  zipCode,
                  numberAndStreet,
                  apartmentNumber,
                },
                ownershipPct: ownership_pct,
                firstName: first_name_and_middle_initial,
                isAnotherIndividualActOnBehalf:
                  is_another_individual_act_on_behalf,
                lastName: last_name,
              };
            },
          );
          const business: BusinessDetails = {
            id,
            name,
            entityType,
            isEndUserReviewed,
            documentLinks,
            accountingMethod,
            ownerOrProprietor,
            isRegisteredWithState,
            isSameAsHomeAddress,
            ein,
            year,
            address: {
              city,
              state,
              zipCode,
              numberAndStreet,
              apartmentNumber,
            },
            haveEin,
            shareHolders,
            incorporationDate,
            phoneNumber,
            professions,
            startDate,
            signingAuthorityId,
            sCorpEffectiveDate,
            is_spouse_business_partner,
            is_work_continuing,
            work_stop_date,
          };
          return business;
        },
      );
      dispatch(setBusinessDetails({businesses}));
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to fetch business details (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};
