import {Typography} from '@mui/material';
import React from 'react';
import {useSelector} from 'react-redux';
import {
  CPA_CENTER_ACTIVE_YEAR,
  TaxProfileSubsection,
  themmeColor,
} from 'src/constants/constants';
import {TAX_PROFILE_FIELDS} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import Skeleton from 'src/icons/Skeleton';
import {ReduxStateType} from 'src/store/store';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {
  selectTaxProfile,
  selectTaxProfileAnswer,
} from 'src/store/taxProfile/taxProfile.selector';
import {selectTransactionsReducer} from 'src/store/transactions/transaction.selector';
import SimpleKeyValueField from '../../common/SimpleKeyValueField';
import SubSectionBox from '../../common/SubSectionBox';
import PaymentsTable from './PaymentsTable';
import {
  TaxProfileSummaryVariant,
  useTaxProfileSummaryFilter,
} from '../../common/TaxProfileSummaryFilter/useTaxProfileSummaryFilter';

const PaymentsSection = () => {
  const spouseTaxPayment = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TAX_PROFILE_FIELDS.SPOUSE_QUARTERLY_TAX_PAYMENT,
    ),
  );
  const {loaded: taxProfileLoaded} = useSelector(selectTaxProfile);
  const {loaded: taxFormsLoaded} = useSelector(selectTaxFormsReducer);
  const {loaded: transactionsLoaded} = useSelector(selectTransactionsReducer);
  const {summaryVariant} = useTaxProfileSummaryFilter();

  const isTableDataLoading = !taxFormsLoaded || !transactionsLoaded;

  return (
    <SubSectionBox
      name="Payments"
      taxprofileSubsection={TaxProfileSubsection.Payments}>
      <div
        style={
          summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
            ? {}
            : {display: 'flex', flex: 1}
        }>
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {}
              : {flex: 0.8}
          }>
          <SimpleKeyValueField
            name={`Did you/your spouse make any quarterly tax payments in ${CPA_CENTER_ACTIVE_YEAR}`}
            value={spouseTaxPayment}
            loading={!taxProfileLoaded}
          />
        </div>
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {}
              : {flex: 1.2}
          }>
          <Typography
            style={{
              fontSize: 13,
              color: themmeColor.black60,
              marginBottom: 8,
            }}>
            Payments
          </Typography>
          {isTableDataLoading ? (
            <Skeleton width="34vw" height="17vh" radius={12} />
          ) : (
            <PaymentsTable />
          )}
        </div>
      </div>
    </SubSectionBox>
  );
};

export default PaymentsSection;
