import React, {useState} from 'react';
import {Typography} from '@mui/material';
import useCurrentTaxReturnId from 'src/CpaCenterV2/hooks/useCurrentTaxReturnId';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {Document, Page} from 'react-pdf';
import {useOcrBoundingBox} from 'src/CpaCenterV2/hooks/useOcrBoundingBox';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {CPA_CENTER_ACTIVE_YEAR} from 'src/constants/constants';
import {sendDocForReviewEsign} from 'src/appApi';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import {MANDATORY_JURISDICTION} from 'src/CpaCenterUserInfo/CpaCenterReviewAndEsign/ReviewAndEsign.utils';
import useCpaCenterV2Navigation from 'src/CpaCenterV2/hooks/useCpaCenterV2Navigation';
import {TaxReturnStatus} from 'src/store/taxReturns/taxReturns.reducer';
import {useDispatch} from 'react-redux';
import {fetchTaxReturns} from 'src/store/taxReturns/taxReturns.actions';

interface ReviewReturnPlaceholderPopupProps {
  url: string;
  isOpen: boolean;
  onClose: () => void;
  onSendManually: () => void;
}

const ReviewReturnPlaceholderPopup = ({
  url,
  isOpen,
  onClose,
  onSendManually,
}: ReviewReturnPlaceholderPopupProps) => {
  const {userId} = useCurrentUserId();
  const {returnId, currentReturn} = useCurrentTaxReturnId();
  const dispatch = useDispatch();
  const {canvasPDFRef, numPages, onDocumentLoadSuccess, drawBoundingBox} =
    useOcrBoundingBox();
  const {navigateToTaxReturnDetailedView} = useCpaCenterV2Navigation();

  const [isLoading, setIsLoading] = useState(false);
  const {notify} = useNotify();

  const _onDocumentLoadSuccess = (props: any) => {
    onDocumentLoadSuccess(props);
    setTimeout(() => {
      drawBoundingBox(currentReturn.ocr_data.esign_fields);
    }, 2000);
  };

  const onSendAutomatically = async () => {
    try {
      setIsLoading(true);
      await sendDocForReviewEsign({
        user: userId,
        year: CPA_CENTER_ACTIVE_YEAR,
        filename: currentReturn.document_name,
        esign_fields: currentReturn.ocr_data.esign_fields,
        jurisdiction: MANDATORY_JURISDICTION,
        // sign_now_document_id: signNowId,
        s3_url: url,
      });
      await dispatch(fetchTaxReturns(userId));
      onClose();
      navigateToTaxReturnDetailedView(returnId, TaxReturnStatus.REVIEW_ESIGN);
      notify('Document sent for review esign', NotificationType.success);
    } catch (e) {
      notify(`Failed to send ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };
  if (!currentReturn) {
    return null;
  }
  return (
    <PopUp
      isOpen={isOpen}
      onClose={onClose}
      primaryButtonTitle="Send automatically"
      primaryButtonOnClick={onSendAutomatically}
      primaryButtonDisabled={isLoading}
      secondaryButtonTitle="Send manually"
      secondaryButtonOnClick={onSendManually}
      secondaryButtonDisabled={isLoading}
      tertiaryButtonTitle="Close"
      tertiaryButtonOnClick={onClose}
      tertiaryButtonDisabled={isLoading}>
      <Typography style={{fontWeight: 800, fontSize: 20}}>
        Review the signature & date placeholders
      </Typography>
      <Typography style={{fontSize: 13, marginTop: 4, marginBottom: 2}}>
        Make sure that we are asking user to sign at the correct places.
      </Typography>
      <Typography style={{fontSize: 13, fontWeight: 600, marginBottom: 8}}>
        If there is some issue, upload tax return on “SignNow” and use send
        manually option.
      </Typography>
      <div style={{width: '100%', height: '50vh', overflowY: 'auto'}}>
        <Document file={url} onLoadSuccess={_onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <div
              style={{
                display: 'flex',
                margin: '10px',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Page
                onRenderSuccess={() => console.log('rendered')}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                canvasRef={(ref) => {
                  canvasPDFRef.current[index + 1] = ref;
                }}
                scale={1.2}
              />
            </div>
          ))}
        </Document>
      </div>
      <Typography style={{marginTop: 12}}>
        This document expires on{' '}
        <span style={{fontWeight: 600}}>
          {currentReturn.return_expiry.toDateString()}
        </span>
      </Typography>
    </PopUp>
  );
};

export default ReviewReturnPlaceholderPopup;
