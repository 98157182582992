import {getApplicableDeductions} from 'src/appApi';
import {CPA_CENTER_ACTIVE_YEAR} from 'src/constants/constants';
import {SET_APPLICABLE_DEDUCTIONS} from '../actionNames';
import {setAppState} from '../app/app.actions';
import {AppReducerStates, NotificationType} from '../app/app.reducer';
import {ApplicableDeductionStateType} from './applicableDeductions.reducer';

const setApplicableDeductions = (
  payload: Omit<ApplicableDeductionStateType, 'status' | 'loaded'>,
) => {
  return {
    type: SET_APPLICABLE_DEDUCTIONS,
    payload,
  };
};

export const fetchApplicableDeductions = (userId: number) => {
  return async (dispatch: any) => {
    try {
      const {
        data: {DEDUCTIONS},
      } = await getApplicableDeductions({
        fly_user_id: userId,
        year: CPA_CENTER_ACTIVE_YEAR,
        flow_names: 'DEDUCTIONS',
      });
      const {category_mapping, sch_c_categories} = DEDUCTIONS;
      dispatch(
        setApplicableDeductions({
          sectionWiseMapping: category_mapping,
          allOtherDeductions: sch_c_categories,
        }),
      );
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to fetch applicable deductions (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};
