import React, {useEffect, useRef, useState} from 'react';
import {Outlet, useOutletContext, useParams} from 'react-router-dom';
import {getUserDocuments} from 'src/appApi';
import {CPA_CENTER_ACTIVE_YEAR, themmeColor} from 'src/constants/constants';
import {
  aggregateDocumentsActionCount,
  backendDocumentsResponseMapper,
} from 'src/CpaCenterUserInfo/CpaCenterDocumentsOCR/CpaCenterDocumentOCR.utils';
import DocumentsContext, {
  DocumentType,
} from 'src/CpaCenterUserInfo/CpaCenterDocumentsOCR/DocumentsContext';
import DocumentTypeSwitcher from 'src/CpaCenterUserInfo/CpaCenterDocumentsOCR/DocumentTypeSwitcher/DocumentTypeSwitcher';
import FullScreenLoading from 'src/common/FullScreenLoading';
import {makeStyles} from '@mui/styles';
import CpaActionPane from 'src/CpaCenterUserInfo/CpaCenterDocumentsOCR/CpaActionPane/CpaActionPane';

const useStyles = makeStyles({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    overflowY: 'auto',
  },
  documentContainer: {
    width: '70%',
    height: '100%',
    padding: '10px',
  },
  outletContainer: {
    width: '100%',
    height: 'calc(100% - 70px)',
    overflow: 'hidden',
  },
  cpaActionPaneContainer: {
    float: 'right',
    width: '30%',
    height: '100%',
    borderLeft: `1px solid ${themmeColor.offWhite}`,
    overflowY: 'scroll',
  },
});

const CpaCenterDocumentsOCR = () => {
  const {flyfin_user_id} = useParams();
  const {setActionCounts} = useOutletContext();

  const [documentsData, setDocumentsData] = useState<Array<DocumentType>>([]);
  // what is the use of this ?
  const [requiredDocTypes, setRequiredDocTypes] = useState<Array<string>>([]);
  const [isDocumentLoading, setIsDocumentLoading] = useState(false);
  const [uri, setURI] = useState('');
  const [imageScale, setImageScale] = useState(1);

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const canvasPDFRef = useRef({});
  // this is used for storing the original pdf pages
  // so that we can redraw them after bounding box
  // is drawn on the canvas
  const originalPDFPages = {};
  let prevPage = -1;

  const drawBoundingBox = (
    ocrData: any,
    canvasContext: CanvasRenderingContext2D | null,
    canvasWidth: number,
    canvasHeight: number,
  ) => {
    ocrData.forEach((data) => {
      if (data) {
        canvasContext?.strokeRect(
          data.Left * canvasWidth,
          data.Top * canvasHeight,
          data.Width * canvasWidth,
          data.Height * canvasHeight,
        );
      }
    });
  };

  const drawPDFBBox = (page: number, ocrData: any) => {
    const highlightContainer = document.getElementById(`Page-${page}`);
    // const highlightParentContainer = document.getElementById('pdf-renderer');
    highlightContainer?.scrollIntoView();

    // to remove previously drawn bounding box
    if (prevPage !== -1) {
      const prevCanvas = canvasPDFRef.current[prevPage];
      const prevCanvasContext = prevCanvas.getContext('2d');

      // condition to check for manual zoom and update originalPDFPages
      if (originalPDFPages[prevPage].height === prevCanvas.height) {
        prevCanvasContext.putImageData(originalPDFPages[prevPage], 0, 0);
      } else {
        originalPDFPages[prevPage] = prevCanvasContext.getImageData(
          0,
          0,
          prevCanvas.width,
          prevCanvas.height,
        );
      }
    }

    // logic to draw bounding box
    const canvas = canvasPDFRef.current[page];
    if (canvas) {
      const canvasContext = canvas?.getContext('2d');
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      canvasContext.strokeStyle = 'red';
      canvasContext.lineWidth = 5;

      if (!originalPDFPages[page]) {
        originalPDFPages[page] = canvasContext.getImageData(
          0,
          0,
          canvasWidth,
          canvasHeight,
        );
      }
      prevPage = page;
      drawBoundingBox(ocrData, canvasContext, canvasWidth, canvasHeight);

      // scrolling to the bounding box
      // if (ocrData[1]) {
      //   highlightParentContainer?.scrollTo(
      //     ocrData[1].Left * canvasWidth -
      //       highlightParentContainer?.clientWidth / 4,
      //     ocrData[1].Top * canvasHeight -
      //       highlightParentContainer.clientHeight / 2 +
      //       highlightParentContainer.scrollTop,
      //   );
      // }
    }
  };

  const drawImageBBox = (ocrData: any) => {
    const highlightParentContainer = document.getElementById(`image-renderer`);
    const canvas = canvasRef.current;

    if (canvas) {
      const canvasContext = canvas.getContext('2d');
      canvasContext.clearRect(0, 0, canvas.width, canvas.height);
      const background = new Image();
      background.src = uri;

      // redraw image
      background.onload = () => {
        let currImageScale = imageScale;
        canvas.width = background.width;
        canvas.height = background.height;

        let drawingWidth = background.width * currImageScale;
        let drawingHeight = background.height * currImageScale;

        // checking if bounding box width is
        // big enough for zooming out
        if (ocrData[0] && ocrData[1]) {
          if (
            highlightParentContainer?.clientWidth / 2 <=
              ocrData[0].Width * drawingWidth ||
            highlightParentContainer?.clientWidth / 2 <=
              ocrData[1].Width * drawingWidth ||
            highlightParentContainer?.clientHeight / 2 <=
              ocrData[0].Height * drawingHeight ||
            highlightParentContainer?.clientHeight / 2 <=
              ocrData[1].Height * drawingHeight
          ) {
            currImageScale = 0.5;
            drawingWidth = background.width * currImageScale;
            drawingHeight = background.height * currImageScale;
          }
        }

        canvasContext?.scale(currImageScale, currImageScale);
        canvasContext.drawImage(background, 0, 0);
        canvasContext.beginPath();
        canvasContext.strokeStyle = 'red';
        canvasContext.lineWidth = 3;
        drawBoundingBox(ocrData, canvasContext, canvas.width, canvas.height);

        // scrolling to the bounding box
        if (ocrData[1]) {
          highlightParentContainer?.scrollTo(
            ocrData[1].Left * drawingWidth -
              highlightParentContainer?.clientWidth / 4,
            ocrData[1].Top * drawingHeight -
              highlightParentContainer.clientHeight / 2,
          );
        }
        setImageScale(currImageScale);
      };
    }
  };

  const fetchDocuments = async () => {
    try {
      setIsDocumentLoading(true);

      const response = await getUserDocuments({
        user: flyfin_user_id ?? '',
        year: CPA_CENTER_ACTIVE_YEAR,
      });

      const documentsDataList = backendDocumentsResponseMapper(
        response.data.forms,
      );
      const documentOCRActionCount =
        aggregateDocumentsActionCount(documentsDataList);

      setDocumentsData(documentsDataList);
      setActionCounts((prev) => ({...prev, documentOCRActionCount}));
      setRequiredDocTypes(response.data.required_docs);
      setIsDocumentLoading(false);
    } catch (e) {
      alert(`An error occured ${e}`);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [flyfin_user_id]);

  const styles = useStyles({});

  return isDocumentLoading ? (
    <FullScreenLoading open={isDocumentLoading} />
  ) : (
    <DocumentsContext.Provider
      value={{
        docsData: documentsData,
        fetchDocuments,
        isDocumentLoading,
        setIsDocumentLoading,
        requiredDocTypes,
        uri,
        setURI,
        canvasRef,
        canvasPDFRef,
        drawImageBBox,
        drawPDFBBox,
        imageScale,
        setImageScale,
      }}>
      <div className={styles.container}>
        <div className={styles.documentContainer}>
          <div style={{width: '100%', height: '70px'}}>
            <DocumentTypeSwitcher />
          </div>
          <div className={styles.outletContainer}>
            <Outlet />
          </div>
        </div>
        <div className={styles.cpaActionPaneContainer}>
          <CpaActionPane />
        </div>
      </div>
    </DocumentsContext.Provider>
  );
};

export default CpaCenterDocumentsOCR;
