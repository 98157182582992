import React from 'react';
import {Chip, Grid, Typography} from '@mui/material';
import {
  CPAQuestionType,
  CPAQuestionTypeName,
  QUERY_STATUS,
  themmeColor,
} from 'src/constants/constants';
import {makeStyles} from '@material-ui/core/styles';
import {BackendQuery} from 'src/types/query.types';
import _ from 'lodash';
import {CurrencyNumberFormat} from 'src/common/utils';

type Props = {
  queryData: BackendQuery;
  showStatus?: boolean;
};

const getContainerBackgroundColor = (status) => {
  if (status === QUERY_STATUS.OPEN) return themmeColor.lightFlyfinOrange;
  if (status === QUERY_STATUS.RESOLVED) return themmeColor.lightSuccessGreen;
  return themmeColor.lightYellow;
};

const useStyle = makeStyles({
  headerContainer: {
    backgroundColor: ({status}) => getContainerBackgroundColor(status),
    padding: '12px 16px',
  },
  typeText: {
    fontWeight: 'bold',
  },
  dataTitle: {fontWeight: 'bold', fontSize: 13},
});

const getQueryHeaderData = (queryData: BackendQuery) => {
  if (queryData.type === CPAQuestionType.TXN_RELATED) {
    return [
      {title: 'Date', value: queryData.txn_date},
      {title: 'Merchant name', value: queryData.merchant_name},
      {
        title: 'Transaction type',
        value: `${queryData.txn_category}${queryData.plaid_category}`,
      },
      {title: 'Total expense', value: CurrencyNumberFormat(queryData.amount)},
    ];
  }
  return [
    // todo: get value from queryData
    {
      value: '',
    },
  ];
};

const getStatusChipProps = (status) => {
  if (status === QUERY_STATUS.DRAFT) {
    return {color: themmeColor.white, backgroundColor: themmeColor.darkYellow};
  }
  if (status === QUERY_STATUS.OPEN) {
    return {
      color: themmeColor.white,
      backgroundColor: themmeColor.dashboardRed,
    };
  }
  if (status === QUERY_STATUS.RESOLVED) {
    return {
      color: themmeColor.white,
      backgroundColor: themmeColor.resolvedGreen,
    };
  }
  return {color: themmeColor.white, backgroundColor: themmeColor.sentGrey};
};

const QueryChatHeader = ({queryData, showStatus}: Props) => {
  const styles = useStyle({status: queryData.status});
  const queryStatus = queryData.status.replace('_', ' ');
  return (
    <div className={styles.headerContainer}>
      {showStatus && (
        <Chip
          label={queryStatus}
          sx={{...getStatusChipProps(queryData.status), mb: 0.8}}
        />
      )}
      <Typography className={styles.typeText}>
        {queryData.type === CPAQuestionType.TAX_FILING
          ? queryData.query
          : `${CPAQuestionTypeName[queryData.type]} query`}
      </Typography>
      <Grid
        container
        direction="row"
        columnGap={2}
        rowGap={1}
        justifyContent={'space-between'}
        marginTop={0.8}>
        {getQueryHeaderData(queryData).map(({title, value}) => {
          return (
            <Grid item>
              {!!title && (
                <Typography className={styles.dataTitle}>{title}</Typography>
              )}
              {!!value && <Typography>{value}</Typography>}
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default QueryChatHeader;
