import {SET_TRANSACTIONS} from '../actionNames';
import {ReduxActionType, REDUX_STATUS} from '../types';
import { IRSCategory } from './transactions.constants';

export interface Transaction {
  txnId: number;
  bucket: string;
  classificationStatus: string;
  currentAmount: number;
  expenseSubCategory: string;
  irsCategory: IRSCategory;
  isPending: boolean;
  isReviewed: boolean;
  merchantName: string;
  originalAmount: number;
  taxDeductibleAmount: number;
  txnCategory: string;
  txnDate: string; // YYYY-MM-DD
  updatedClassificationStatus: string;
  updatedExpenseCategory: string;
  updatedMerchantName: string;
}

const initialState = {
  transactions: [] as Transaction[],
  transactionIdMap: {} as {[key: number]: Transaction},
  loaded: false,
  status: REDUX_STATUS.INIT,
};

export const transactions = (
  state = initialState,
  action: ReduxActionType,
) => {
  switch (action.type) {
    case SET_TRANSACTIONS:
      return {
        ...state,
        ...action.payload,
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
      };
  }
  return state;
};


export type TransactionStateType = typeof initialState;
