import {Typography} from '@mui/material';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {postCCHStatus} from 'src/appApi';
import {CPA_CENTER_ACTIVE_YEAR} from 'src/constants/constants';
import DSButton from 'src/DesignSystem/Button/Button';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import Skeleton from 'src/icons/Skeleton';
import {
  CreateQueryDrawerTab,
  NotificationType,
} from 'src/store/app/app.reducer';
import {fetchCCHStatus} from 'src/store/cchStatus/cchStatus.actions';
import {selectCCHStatus} from 'src/store/cchStatus/cchStatus.selector';
import ActionRequired from '../common/ActionRequired/ActionRequired';
import useCurrentUserId from '../hooks/useCurrentUserId';
import CCHSections from './components/CCHSections';
import SendToCCHErrorOverlay from './components/SendToCCHErrorOverlay';

const SentToCCH = () => {
  const {userId} = useCurrentUserId();
  const [selectedSections, setSelectedSections] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorOverlay, setErrorOverlay] = useState<{
    error: string;
    isVisible: boolean;
  }>({error: '', isVisible: false});
  const {notify} = useNotify();
  const {loaded} = useSelector(selectCCHStatus);

  const dispatch = useDispatch();

  const sendToCCH = async () => {
    try {
      setIsLoading(true);
      await postCCHStatus({
        fly_user_id: userId,
        year: CPA_CENTER_ACTIVE_YEAR,
        config: selectedSections,
      });
    } catch (e) {
      if (e?.response?.status === 400) {
        setErrorOverlay({
          error: e?.response?.data?.error_msg,
          isVisible: true,
        });
      }
      notify(`Failed to send to cch ${e}`, NotificationType.error);
    } finally {
      dispatch(fetchCCHStatus(userId));
      setIsLoading(false);
    }
  };
  return (
    <>
      <div
        style={{
          display: 'flex',
          height: '80vh',
          justifyContent: 'space-between',
        }}>
        <div style={{marginLeft: 8, width: '58vw', marginTop: 12}}>
          {!loaded ? (
            <>
              <Skeleton
                style={{marginBottom: 24}}
                height={40}
                width={'100%'}
              />
              <Skeleton
                style={{marginBottom: 12}}
                height={80}
                width={'100%'}
              />
              <Skeleton
                style={{marginBottom: 12}}
                height={80}
                width={'100%'}
              />
              <Skeleton
                style={{marginBottom: 12}}
                height={80}
                width={'100%'}
              />
            </>
          ) : (
            <>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography style={{fontWeight: 800, fontSize: 20}}>
                  Send to CCH
                </Typography>
                <div style={{display: 'flex'}}>
                  <DSButton
                    type="secondary"
                    disabled={isLoading}
                    onClick={() => setSelectedSections({})}
                    text="Clear all"
                    style={{marginRight: 8}}
                  />
                  <DSButton
                    type="primary"
                    disabled={isLoading}
                    onClick={sendToCCH}
                    text="Send"
                  />
                </div>
              </div>
              <div
                style={{
                  overflowY: 'auto',
                  height: 'calc(100% - 30px)',
                  marginTop: 20,
                }}>
                <CCHSections
                  selectedSections={selectedSections}
                  setSelectedSections={setSelectedSections}
                  isLoading={isLoading}
                />
              </div>
            </>
          )}
        </div>
        <div style={{overflowY: 'auto', width: '40vw'}}>
          <ActionRequired
            types={[
              CreateQueryDrawerTab.DOCUMENT,
              CreateQueryDrawerTab.TAX_PROFILE,
              CreateQueryDrawerTab.HIGH_VALUE_DEDUCTIONS,
            ]}
          />
        </div>
      </div>
      <SendToCCHErrorOverlay
        isOpen={errorOverlay.isVisible}
        error={errorOverlay.error}
        onClose={() => setErrorOverlay({error: '', isVisible: false})}
      />
    </>
  );
};

export default SentToCCH;
