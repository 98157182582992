import React, {useEffect, useState} from 'react';
import {Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {exportData, patchTaxFiling, updateCCHClientId} from 'src/appApi';
import {
  COMMON_COMPONENTS_ID,
  CPA_CENTER_ACTIVE_YEAR,
  themmeColor,
} from 'src/constants/constants';
import {CpaCenterV2Route} from 'src/constants/routeName';
import {
  BACKEND_RESPONSE_KEYS,
  TAX_RETURN_STATUS_LABELS,
} from 'src/CpaCenterList/components/cpaList.utils';
import {TAX_FILING_STATUS} from 'src/constants/constants';
import NextActionDatePickerCell from 'src/CpaCenterV2/common/UserSummaryBar/components/NextActionDatePickerCell';
import Box from 'src/DesignSystem/Box/Box';
import BackIcon from 'src/icons/BackIcon';
import NotesIcon from 'src/icons/NotesIcon';
import TaxProfileIcon from 'src/icons/TaxProfileIcon';
import {fetchUserInfo} from 'src/store/user/user.actions';
import {selectUser} from 'src/store/user/user.selector';
import LabelField from './components/LabelField';
import NotesPopUp from './components/NotesPopUp';
import UpdateCCHClientId from './components/UpdateCCHClientId';
import UpdateReturnStatus from './components/UpdateReturnStatus';
import DSButton from 'src/DesignSystem/Button/Button';
import {setAppState} from 'src/store/app/app.actions';
import {AppReducerStates, NotificationType} from 'src/store/app/app.reducer';
import {ReduxStateType} from 'src/store/store';
import {selectAppReducerState} from 'src/store/app/app.selector';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import UploadCloudIcon from 'src/icons/UploadCloudIcon';
import CollapseButton from './components/CollapseButton';
import {USER_COMMS_MODE} from 'src/store/user/user.reducer';
import {Mail, Smartphone} from '@mui/icons-material';

const UserSummaryBar = () => {
  const {
    userId,
    userName,
    email,
    lastStatusChange,
    cchClientId,
    cpaFacingRetStatus,
    nextActionDate,
    taxFilingId,
    notes,
    loaded,
    comms_mode,
    esign_mode,
  } = useSelector(selectUser);
  const isTaxProfileSummaryDrawer = useSelector<ReduxStateType>((state) =>
    selectAppReducerState(state, AppReducerStates.isTaxProfileSummaryDrawer),
  ) as boolean;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(!loaded);
  const [cchPopUp, setCchPopUp] = useState(false);
  const [taxFilingStatusPopUp, setTaxFilingStatusPopUp] = useState(false);
  const [notesPopUp, setNotesPopUp] = useState(false);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const refreshInfo = () => dispatch(fetchUserInfo(userId));

  const {notify} = useNotify();

  useEffect(() => {
    setLoading(!loaded);
  }, [loaded]);

  const onUpdateCchId = async (newId: number) => {
    try {
      setLoading(true);
      await updateCCHClientId(userId, newId);
    } catch (e) {
      alert('Failed to update CCH Id');
    } finally {
      await refreshInfo();
      setLoading(false);
      setCchPopUp(false);
    }
  };
  const onUpdateTaxFilingStatus = async (newId: TAX_FILING_STATUS) => {
    try {
      setLoading(true);
      await patchTaxFiling(
        {tax_filing_id: taxFilingId},
        {[BACKEND_RESPONSE_KEYS.STATUS]: newId},
      );
    } catch (e) {
      alert('Failed to update Return status');
    } finally {
      await refreshInfo();
      setLoading(false);
      setTaxFilingStatusPopUp(false);
    }
  };
  const onUpdateNote = async (newNote: string) => {
    try {
      setLoading(true);
      await patchTaxFiling(
        {tax_filing_id: taxFilingId},
        {[BACKEND_RESPONSE_KEYS.NOTE]: newNote},
      );
    } catch (e) {
      alert('Failed to update Note');
    } finally {
      await refreshInfo();
      setLoading(false);
      setNotesPopUp(false);
    }
  };

  const onExportData = async () => {
    try {
      setLoading(true);
      await exportData({fly_user_id: userId, year: CPA_CENTER_ACTIVE_YEAR});
      notify('Export data success', NotificationType.success);
    } catch (e) {
      notify(`Export data failed ${e}`, NotificationType.error);
    } finally {
      setLoading(false);
    }
  };
  const onClickBack = () => {
    navigate(CpaCenterV2Route.CpaCenterV2List);
  };

  const data = [
    {
      props: {
        label: 'User ID',
        value: userId,
      },
      Component: LabelField,
    },
    {
      props: {
        label: 'Mode',
        value: {
          comms_mode,
          esign_mode,
        },
      },
      Component: ({label, value}) => {
        return (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 50,
                alignItems: 'center',
              }}>
              <Typography
                style={{
                  fontSize: 11,
                  fontWeight: 'bold',
                  letterSpacing: 0.7,
                  textTransform: 'uppercase',
                  color: themmeColor.black60,
                }}>
                {label}
              </Typography>
              {value && (
                <div style={{flexDirection: 'row'}}>
                  {value.comms_mode === USER_COMMS_MODE.APP ? (
                    <Smartphone />
                  ) : (
                    <Mail />
                  )}
                  {value.esign_mode === USER_COMMS_MODE.APP ? (
                    <Smartphone />
                  ) : (
                    <Mail />
                  )}
                </div>
              )}
            </div>
          </>
        );
      },
    },
    {
      props: {
        label: 'Name',
        value: userName,
      },
      Component: LabelField,
    },
    {
      props: {
        label: 'Email address',
        value: email,
      },
      Component: LabelField,
    },
    {
      props: {
        label: 'CCH Client ID',
        value: cchClientId,
        onClick: () => setCchPopUp(true),
      },
      Component: LabelField,
    },
    {
      props: {
        label: 'Recent status change',
        value: lastStatusChange,
      },
      Component: LabelField,
    },
    {
      props: {
        label: 'Return status',
        value: TAX_RETURN_STATUS_LABELS[cpaFacingRetStatus]?.label,
        onClick: () => setTaxFilingStatusPopUp(true),
        backgroundColor:
          TAX_RETURN_STATUS_LABELS[cpaFacingRetStatus]?.backColor,
        valueColor: TAX_RETURN_STATUS_LABELS[cpaFacingRetStatus]?.textColor,
      },
      Component: LabelField,
    },
    {
      props: {
        label: 'Next action date',
        value: nextActionDate,
        taxFilingId,
        isValid: !!nextActionDate,
        onSuccess: refreshInfo,
      },
      Component: (props) => (
        <div style={{display: 'flex', flexDirection: 'column', height: 50}}>
          <Typography
            style={{
              fontSize: 11,
              fontWeight: 'bold',
              letterSpacing: 0.7,
              textTransform: 'uppercase',
              color: themmeColor.black60,
            }}>
            {props.label}
          </Typography>
          <NextActionDatePickerCell {...props} />,
        </div>
      ),
    },
    {
      props: {
        label: 'Notes',
        onClick: () => setNotesPopUp(true),
      },
      Component: ({label, onClick}) => (
        <DSButton
          startIcon={<NotesIcon />}
          text={label}
          disabled={loading}
          onClick={onClick}
          type="secondary"
        />
      ),
    },
    {
      props: {
        label: `Export Data ${CPA_CENTER_ACTIVE_YEAR}`,
        onClick: onExportData,
      },
      Component: ({label, onClick}) => (
        <DSButton
          startIcon={<UploadCloudIcon color={themmeColor.white} />}
          text={label}
          disabled={loading}
          onClick={onClick}
          type="primary"
        />
      ),
    },
    {
      props: {},
      Component: () => (
        <Box
          hoverEffect
          onClick={() =>
            dispatch(
              setAppState(
                AppReducerStates.isTaxProfileSummaryDrawer,
                !isTaxProfileSummaryDrawer,
              ),
            )
          }
          backgroundColor={
            isTaxProfileSummaryDrawer ? themmeColor.black : '#F0F0F0'
          }
          style={{borderColor: themmeColor.silver, padding: 12}}>
          <TaxProfileIcon />
        </Box>
      ),
    },
  ];

  return (
    <div
      id={COMMON_COMPONENTS_ID.CPA_CENTER_V2_USER_SUMMARY_BAR}
      style={{
        position: 'relative',
      }}>
      <Box
        style={{
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingInline: 32,
          paddingBlock: isCollapsed ? 0 : 12,
          flexWrap: 'wrap',
          height: isCollapsed ? 0 : undefined,
          overflow: 'hidden',
        }}
        noBR>
        <Box
          borderColor={themmeColor.silver}
          hoverEffect
          onClick={onClickBack}
          style={{paddingBlock: 12, paddingInline: 4}}
          hoverColor={themmeColor.focusedBlue}>
          <BackIcon />
          <Typography>Back to users list</Typography>
        </Box>
        {data.map(({Component, props}) => (
          <Component {...props} loaded={loaded} />
        ))}
      </Box>
      <CollapseButton
        setIsCollapsed={setIsCollapsed}
        isCollapsed={isCollapsed}
      />
      <UpdateCCHClientId
        key={`cchUpdatePopUp-${cchClientId}-${cchPopUp}`}
        isOpen={cchPopUp}
        onClose={() => setCchPopUp(false)}
        cchClientId={cchClientId}
        loading={loading}
        onUpdate={onUpdateCchId}
      />
      <UpdateReturnStatus
        key={`returnStatus-${cpaFacingRetStatus}-${taxFilingStatusPopUp}`}
        isOpen={taxFilingStatusPopUp}
        onClose={() => setTaxFilingStatusPopUp(false)}
        currentReturnStatusId={cpaFacingRetStatus}
        loading={loading}
        onUpdate={onUpdateTaxFilingStatus}
      />
      <NotesPopUp
        key={`notesPopUp-${notes}-${notesPopUp}`}
        isOpen={notesPopUp}
        onClose={() => setNotesPopUp(false)}
        loading={loading}
        onUpdate={onUpdateNote}
        notes={notes}
      />
    </div>
  );
};

export default UserSummaryBar;
