import {getAllThreadsV2} from 'src/appApi';
import {SET_THREADS} from '../actionNames';
import {setAppState} from '../app/app.actions';
import {AppReducerStates, NotificationType} from '../app/app.reducer';
import {Thread, ThreadsStateType} from './threads.reducer';
import {CPA_CENTER_ACTIVE_YEAR} from 'src/constants/constants';
import {parseSingleThread} from './threads.utils';

const setThreads = (payload: Pick<ThreadsStateType, 'threads'>) => {
  return {
    type: SET_THREADS,
    payload,
  };
};

export const fetchThreads = (userId: number) => {
  return async (dispatch: any) => {
    try {
      const {data: rawThreads} = await getAllThreadsV2({
        fly_user_id: userId,
        year: CPA_CENTER_ACTIVE_YEAR,
      });
      const threads = rawThreads.map(parseSingleThread);
      dispatch(setThreads({threads}));
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to get threads (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};
