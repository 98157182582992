import {Typography} from '@mui/material';
import React, {useState} from 'react';
import useCurrentTaxReturnId from 'src/CpaCenterV2/hooks/useCurrentTaxReturnId';
import DSButton from 'src/DesignSystem/Button/Button';
import OcrDataSection from './components/OcrDataSection';
import {
  OcrDataSectionName,
  TaxReturnStatus,
} from 'src/store/taxReturns/taxReturns.reducer';
import {stateAbbreviationNameMap} from 'src/CpaCenterV2/TaxProfileSections/TaxProfileSections.utils';
import FullScreenLoading from 'src/DesignSystem/FullScreenLoading/FullScreenLoading';
import {useDispatch, useSelector} from 'react-redux';
import {selectUser} from 'src/store/user/user.selector';
import {sendDocForCpaReview} from 'src/appApi';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import {fetchTaxReturns} from 'src/store/taxReturns/taxReturns.actions';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import useCpaCenterV2Navigation from 'src/CpaCenterV2/hooks/useCpaCenterV2Navigation';
import {BACKEND_RESPONSE_KEYS} from 'src/CpaCenterList/components/cpaList.utils';
import {TAX_FILING_STATUS} from 'src/constants/constants';
import useLayout from 'src/CpaCenterV2/hooks/useLayout';

const ReviewSummary = ({url}: {url: string | null}) => {
  const {returnId, currentReturn, taxReturnsLoaded} = useCurrentTaxReturnId();
  const {userId} = useCurrentUserId();
  const {taxFilingId, loaded: userLoaded} = useSelector(selectUser);
  const [federalOcrData, setFederalOcrData] = useState(
    currentReturn.ocr_data[OcrDataSectionName.federal_return_summary],
  );
  const [stateOcrData, setStateOcrData] = useState(
    currentReturn.ocr_data[OcrDataSectionName.states_return_summary],
  );
  const {navigateToTaxReturnDetailedView} = useCpaCenterV2Navigation();
  const {spaceLeftBelowTaxReturnSection, TAX_RETURN_BOTTOM_BAR} = useLayout();

  const [isLoading, setIsLoading] = useState(false);
  const {notify} = useNotify();
  const dispatch = useDispatch();

  const onChangeFederal = (key: any, value: any) => {
    setFederalOcrData((prev) => {
      return {
        ...prev,
        [key]: Number(value),
      };
    });
  };

  const onChangeState = (state: string, key: any, value: any) => {
    setStateOcrData((prev) => {
      return {
        ...prev,
        [state]: {
          ...prev[state],
          [key]: Number(value),
        },
      };
    });
  };

  const onSubmitForCpaReview = async () => {
    try {
      setIsLoading(true);
      await sendDocForCpaReview(
        {tax_filing_id: taxFilingId},
        {
          [BACKEND_RESPONSE_KEYS.STATUS]: TAX_FILING_STATUS.SUMMARY_REVIEWED,
          [OcrDataSectionName.federal_return_summary]: federalOcrData,
          [OcrDataSectionName.states_return_summary]: stateOcrData,
        },
      );
      await dispatch(fetchTaxReturns(userId));
      navigateToTaxReturnDetailedView(returnId, TaxReturnStatus.CPA_REVIEW);
      notify('Sent return for cpa review', NotificationType.success);
    } catch (e) {
      notify(`Failed to send for cpa review ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };
  if (!url) {
    return null;
  }

  return (
    <div>
      <FullScreenLoading open={isLoading} />
      <div style={{display: 'flex'}}>
        <iframe
          title="Review-esign-return"
          src={url}
          style={{width: '70%', height: spaceLeftBelowTaxReturnSection}}
        />
        <div
          style={{
            height: spaceLeftBelowTaxReturnSection,
            paddingInline: 20,
            width: '30%',
          }}>
          <div
            style={{
              height: spaceLeftBelowTaxReturnSection - TAX_RETURN_BOTTOM_BAR,
              overflowY: 'auto',
            }}>
            <OcrDataSection
              sectionName="Federal"
              onChange={onChangeFederal}
              data={federalOcrData}
            />
            {Object.entries(stateOcrData).map(([state, stateData]) => {
              return (
                <OcrDataSection
                  sectionName={`${stateAbbreviationNameMap[state]}`}
                  onChange={(key, value) => onChangeState(state, key, value)}
                  data={stateData}
                />
              );
            })}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flex: 1,
              height: TAX_RETURN_BOTTOM_BAR,
              paddingBlock: 8,
            }}>
            <DSButton
              type="primary"
              onClick={onSubmitForCpaReview}
              style={{width: '100%', paddingInline: 32, paddingBlock: 12}}
              text="Submit for Cpa Review"
              disabled={!taxReturnsLoaded || !userLoaded}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewSummary;
