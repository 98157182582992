import React, {useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {
  CPAQuestionType,
  CPAQuestionTypeName,
  themmeColor,
} from 'src/constants/constants';
import {Button, Divider, Modal, Typography} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {
  filterFields,
  QueryStatusText,
} from 'src/UserQueries/Queries.constants';
import dayjs from 'dayjs';
import {format} from 'date-fns';
import SelectCPACell from 'src/SelectCPACell/SelectCPACell';
import {makeStyles} from '@material-ui/core/styles';

interface FilterModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const BubbleButton = ({
  label,
  onClick,
  selected = false,
  spaceAround,
}: {
  label: string;
  onClick: () => void;
  selected: boolean;
  spaceAround?: boolean;
}) => {
  return (
    <Button
      variant={'outlined'}
      style={{
        borderRadius: 8,
        margin: spaceAround ? 5 : 0,
        textTransform: 'none',
        borderColor: selected ? themmeColor.darkBlue : themmeColor.grey,
        color: selected ? themmeColor.darkBlue : themmeColor.grey,
        borderWidth: selected ? 2 : 1,
        fontWeight: selected ? 'bold' : 'normal',
      }}
      onClick={onClick}>
      {label}
    </Button>
  );
};

const yearList = [2021, 2022, 2023, 2024];

const queryStatus = Object.keys(QueryStatusText).map((status) => ({
  text: QueryStatusText[status],
  value: status,
}));

const queryTypes = Object.keys(CPAQuestionTypeName)
  .filter(
    (type) =>
      type != CPAQuestionType.TAX_PROFILE &&
      type != CPAQuestionType.TAX_FILING,
  )
  .map((type) => ({text: CPAQuestionTypeName[type], value: type}));

const ratingOption = [
  {
    title: 'Yes',
    value: true,
  },
  {
    title: 'No',
    value: false,
  },
];

const useStyles = makeStyles({
  itemContainer: {
    marginTop: 20,
  },
  inputContainer: {
    display: 'flex',
    gap: 10,
    marginTop: 12,
  },
});

const FilterModal = ({onClose}: FilterModalProps) => {
  const styles = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultSelectedYears = searchParams.getAll(filterFields.YEAR);
  const defaultSelectedStatuses = searchParams.getAll(filterFields.STATUS);
  const defaultSelectedTypes = searchParams.getAll(filterFields.TYPE);
  const defaultSelectedRating = searchParams.get(
    filterFields.RATED_CONVERSATION,
  );
  const defaultSelectedFromDate = searchParams.get(filterFields.DATE_FROM);
  const defaultSelectedToDate = searchParams.get(filterFields.DATE_TO);
  const defaultAssignee = searchParams.getAll(filterFields.ASSIGNEE);

  const [filterStates, setFilterStates] = useState({
    [filterFields.YEAR]: defaultSelectedYears ?? [],
    [filterFields.STATUS]: defaultSelectedStatuses ?? [],
    [filterFields.RATED_CONVERSATION]: defaultSelectedRating ?? null,
    [filterFields.DATE_FROM]: defaultSelectedFromDate
      ? dayjs(defaultSelectedFromDate)
      : null,
    [filterFields.DATE_TO]: defaultSelectedToDate
      ? dayjs(defaultSelectedToDate)
      : null,
    [filterFields.ASSIGNEE]: defaultAssignee ?? [],
    [filterFields.TYPE]: defaultSelectedTypes ?? [],
  });

  const handleSingleSelector = (key: filterFields, value: any) => {
    setFilterStates((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleMultiSelector = (key, value) => {
    setFilterStates((prev) => ({
      ...prev,
      [key]: filterStates[key].includes(value)
        ? filterStates[key].filter((date) => date !== value)
        : [...filterStates[key], value],
    }));
  };

  const handleApply = () => {
    setSearchParams((prevParams) => {
      prevParams.delete(filterFields.YEAR);
      prevParams.delete(filterFields.DATE_TO);
      prevParams.delete(filterFields.DATE_FROM);
      prevParams.delete(filterFields.STATUS);
      prevParams.delete(filterFields.RATED_CONVERSATION);
      prevParams.delete(filterFields.ASSIGNEE);
      prevParams.delete(filterFields.PAGE);
      prevParams.delete(filterFields.TYPE);

      if (filterStates[filterFields.YEAR].length > 0) {
        filterStates[filterFields.YEAR].forEach((year) =>
          prevParams.append(filterFields.YEAR, year),
        );
      }
      if (filterStates[filterFields.STATUS].length > 0) {
        filterStates[filterFields.STATUS].forEach((status) =>
          prevParams.append(filterFields.STATUS, status),
        );
      }
      if (filterStates[filterFields.TYPE].length > 0) {
        filterStates[filterFields.TYPE].forEach((status) =>
          prevParams.append(filterFields.TYPE, status),
        );
      }
      if (filterStates[filterFields.DATE_FROM] != null) {
        prevParams.set(
          filterFields.DATE_FROM,
          format(new Date(filterStates[filterFields.DATE_FROM]), 'yyyy-MM-dd'),
        );
      }
      if (filterStates[filterFields.DATE_TO] != null) {
        prevParams.set(
          filterFields.DATE_TO,
          format(new Date(filterStates[filterFields.DATE_TO]), 'yyyy-MM-dd'),
        );
      }
      if (filterStates[filterFields.RATED_CONVERSATION] != null) {
        prevParams.set(
          filterFields.RATED_CONVERSATION,
          filterStates[filterFields.RATED_CONVERSATION],
        );
      }
      if (filterStates[filterFields.ASSIGNEE].length > 0) {
        filterStates[filterFields.ASSIGNEE].forEach((assignee) =>
          prevParams.append(filterFields.ASSIGNEE, assignee),
        );
      }
      return prevParams;
    });
    onClose();
  };

  const handleClearAllFilter = () => {
    setFilterStates({
      [filterFields.YEAR]: [],
      [filterFields.STATUS]: [],
      [filterFields.RATED_CONVERSATION]: null,
      [filterFields.DATE_FROM]: null,
      [filterFields.DATE_TO]: null,
      [filterFields.ASSIGNEE]: [],
      [filterFields.TYPE]: [],
    });
  };

  return (
    <Modal
      style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      open={true}
      onClose={onClose}>
      <div
        style={{
          padding: 20,
          backgroundColor: themmeColor.white,
          width: 500,
          overflow: 'scroll',
          maxHeight: '90vh',
        }}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography fontSize={18} fontWeight={'bold'}>
            Filters
          </Typography>
          <Button
            onClick={handleClearAllFilter}
            style={{color: themmeColor.darkBlue, marginRight: 5}}
            variant="text">
            Clear all
          </Button>
        </div>
        <Divider />
        <div>
          <div className={styles.itemContainer}>
            <Typography fontSize={18}>Query date: </Typography>
            <div className={styles.inputContainer}>
              <div>
                <Typography>From:</Typography>
                <DatePicker
                  format={'DD-MM-YYYY'}
                  value={filterStates[filterFields.DATE_FROM]}
                  maxDate={filterStates[filterFields.DATE_TO]}
                  onChange={(newDate) =>
                    handleSingleSelector(filterFields.DATE_FROM, newDate)
                  }
                />
              </div>
              <div>
                <Typography>To:</Typography>
                <DatePicker
                  format={'DD-MM-YYYY'}
                  value={filterStates[filterFields.DATE_TO]}
                  minDate={filterStates[filterFields.DATE_FROM]}
                  onChange={(newDate) =>
                    handleSingleSelector(filterFields.DATE_TO, newDate)
                  }
                />
              </div>
            </div>
          </div>

          <div className={styles.itemContainer}>
            <Typography fontSize={18}>Year</Typography>
            <div className={styles.inputContainer}>
              {yearList.map((year) => (
                <BubbleButton
                  label={year}
                  onClick={() =>
                    handleMultiSelector(filterFields.YEAR, year.toString())
                  }
                  selected={filterStates[filterFields.YEAR].includes(
                    year.toString(),
                  )}
                />
              ))}
            </div>
          </div>

          <div className={styles.itemContainer}>
            <Typography fontSize={18}>Status</Typography>
            <div className={styles.inputContainer}>
              {queryStatus.map((item) => (
                <BubbleButton
                  label={item.text}
                  onClick={() =>
                    handleMultiSelector(filterFields.STATUS, item.value)
                  }
                  selected={filterStates[filterFields.STATUS].includes(
                    item.value,
                  )}
                />
              ))}
            </div>
          </div>

          <div className={styles.itemContainer}>
            <Typography fontSize={18}>Rated conversation</Typography>
            <div className={styles.inputContainer}>
              {ratingOption.map((rating) => (
                <BubbleButton
                  label={rating.title}
                  onClick={() =>
                    handleSingleSelector(
                      filterFields.RATED_CONVERSATION,
                      rating.value.toString(),
                    )
                  }
                  selected={
                    filterStates[filterFields.RATED_CONVERSATION] ===
                    rating.value.toString()
                  }
                />
              ))}
            </div>
          </div>

          <div className={styles.itemContainer}>
            <Typography fontSize={18}>Assignee</Typography>
            <div className={styles.inputContainer}>
              <SelectCPACell
                multiSelect
                value={filterStates[filterFields.ASSIGNEE]}
                onChange={(cpaList) =>
                  handleSingleSelector(filterFields.ASSIGNEE, cpaList)
                }
              />
            </div>
          </div>

          <div className={styles.itemContainer}>
            <Typography fontSize={18}>Status</Typography>
            <div className={styles.inputContainer}>
              {queryTypes.map((item) => (
                <BubbleButton
                  label={item.text}
                  onClick={() =>
                    handleMultiSelector(filterFields.TYPE, item.value)
                  }
                  selected={filterStates[filterFields.TYPE].includes(
                    item.value,
                  )}
                />
              ))}
            </div>
          </div>

          <Divider sx={{mt: 2}} />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <Button
            onClick={onClose}
            style={{color: themmeColor.errorRed, marginRight: 5}}
            variant="text">
            Cancel
          </Button>
          <Button
            style={{color: themmeColor.black}}
            onClick={handleApply}
            variant="text">
            Apply
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default FilterModal;
