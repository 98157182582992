import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React from 'react';

interface DropdownProps {
  options: Array<any>;
  value: string | null;
  onChange: (newVal: string) => void;
  placeholder: string;
  maxWidth?: boolean;
  disabled?: boolean;
  getOptionLabel?: (option: any) => any;
}

const Dropdown = ({
  options,
  value,
  onChange,
  placeholder,
  maxWidth,
  disabled,
  getOptionLabel = (option: any) => option,
}: DropdownProps) => {
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={options}
      fullWidth={maxWidth}
      getOptionLabel={getOptionLabel}
      value={value}
      disabled={disabled}
      onChange={(e, v) => onChange(v)}
      componentsProps={{ popper: { style: { width: 'fit-content' } } }}
      renderInput={(params) => <TextField {...params} label={placeholder} />}
    />
    // <FormControl fullWidth={maxWidth} sx={{minWidth: 120}}>
    //   <InputLabel id="demo-simple-select-helper-label">{placeholder}</InputLabel>
    //   <Select
    //     labelId="demo-simple-select-helper-label"
    //     id="demo-simple-select-helper"
    //     value={value}
    //     label={placeholder}
    //     onChange={(e) => onChange(e.target.value)}>
    //     <MenuItem value="">
    //       <em>None</em>
    //     </MenuItem>
    //     {options.map((option) => (
    //       <MenuItem value={option}>{option}</MenuItem>
    //     ))}
    //   </Select>
    // </FormControl>
  );
};

export default Dropdown;
