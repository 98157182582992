import React from 'react';
import {
  TaxProfileSection,
  TaxProfileSubsection,
} from 'src/constants/constants';
import ActionRequired from 'src/CpaCenterV2/common/ActionRequired/ActionRequired';
import useLayout from 'src/CpaCenterV2/hooks/useLayout';
import {CreateQueryDrawerTab} from 'src/store/app/app.reducer';
import SectionHeader from '../common/SectionHeader';
import CryptoIncome from './components/CryptoIncome';
import IncomeTypeCard from './components/IncomeTypeCard';
import RentalIncome from './components/RentalIncome';

const Income = () => {
  const incomeTypesSections = [
    {
      name: 'Freelance',
      subsection: TaxProfileSubsection.Freelance,
      Component: IncomeTypeCard,
    },
    {
      name: 'W-2 Income',
      subsection: TaxProfileSubsection.W2Income,
      Component: IncomeTypeCard,
    },
    {
      name: 'Interest income',
      subsection: TaxProfileSubsection.Interest,
      Component: IncomeTypeCard,
    },
    {
      name: 'Rental Income',
      subsection: TaxProfileSubsection.RentalIncome,
      Component: RentalIncome,
    },
    {
      name: 'Crypto Income',
      subsection: TaxProfileSubsection.DigitalAssetIncome,
      Component: CryptoIncome,
    },
    {
      name: 'Dividend income',
      subsection: TaxProfileSubsection.Dividend,
      Component: IncomeTypeCard,
    },
    {
      name: 'Investment income',
      subsection: TaxProfileSubsection.Investment,
      Component: IncomeTypeCard,
    },
    {
      name: 'Gambling income',
      subsection: TaxProfileSubsection.Gambling,
      Component: IncomeTypeCard,
    },
    {
      name: 'Government income',
      subsection: TaxProfileSubsection.Government,
      Component: IncomeTypeCard,
    },
    {
      name: 'Retirement income',
      subsection: TaxProfileSubsection.Retirement,
      Component: IncomeTypeCard,
    },
    {
      name: 'HSA income',
      subsection: TaxProfileSubsection.HSA,
      Component: IncomeTypeCard,
    },
  ];

  const {spaceLeftBelowTaxFilingSteps, TAX_PROFILE_SECTION_HEADER_HEIGHT} =
    useLayout();

  return (
    <div style={{display: 'flex', flex: 1}}>
      <div style={{width: '75vw'}}>
        <SectionHeader
          sectionName="Income"
          section={TaxProfileSection.Income}
        />
        <div
          style={{
            height:
              spaceLeftBelowTaxFilingSteps - TAX_PROFILE_SECTION_HEADER_HEIGHT,
            overflowY: 'auto',
          }}>
          {incomeTypesSections.map(({name, subsection, Component}) => (
            <Component name={name} subsection={subsection} />
          ))}
        </div>
      </div>
      <div
        style={{
          width: '24vw',
          height: spaceLeftBelowTaxFilingSteps,
          padding: 8,
        }}>
        <ActionRequired
          types={[CreateQueryDrawerTab.TAX_PROFILE]}
          dontAllowAdd
          taxProfileSection={TaxProfileSection.Income}
        />
      </div>
    </div>
  );
};

export default Income;
