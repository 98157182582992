import _ from 'lodash';

export enum TAX_PROFILE_FIELDS {
  LEGAL_NAME = 1,
  SSN = 2,
  HOME_ADDRESS = 3,
  CLAIM_YOU_DEPENDENT = 4,
  FILING_STATUS = 5,
  SPOUSE_LEGAL_NAME = 6,
  SPOUSE_SSN = 7,
  SPOUSE_CLAIMED_DEPENDENT = 8,
  DEPENDENT_DETAILS = 9,
  EARNING_STATES = 10,
  INCOME_SOURCES = 11,
  YOUR_N_SPOUSE_INCOME_SOURCES = 12,
  FREELANCE_EARNINGS = 13,
  RETIREMENT_PLAN_CONTRIBUTIONS = 14,
  DEDUCTION_OPTION = 15,
  ITEMIZE_DEDUCTION = 16,
  STANDARD_DEDUCTION = 17,
  ESTIMATED_TAX_PAYMENTS = 18,
  RECEIVED_ADV_CHILD_TAX_CREDITS = 19,
  BUSINESS_DETAILS = 20,
  WORK_TYPE = 21,
  PROFESSION = 22,
  WFH = 23,
  HOME_OFFICE_AREA = 24,
  PHONE_PCT = 25,
  INTERNET_PCT = 26,
  TRAVEL = 27,
  MILES = 28,
  MEALS = 29,
  PERSONAL_DEDUCTIONS = 30,
  FEDERAL_TAX = 31,
  STATE_TAX = 32,
  DEPENDENT = 33,
  HOME_OFFICE_TXNS = 34,
  PHONE_INTERNET_PCT = 35,
  FREELANCE_TIME = 36,
  SPOUSE_FREELANCE_INCOME = 37,
  SPOUSE_W2_INCOME = 38,
  FILED_TAXES = 39,
  INTENT = 40,
  OTHER_SOURCE_INCOME = 41,
  FILED_TAX_EXTENSION = 42,
  TAX_DEADLINE_REMINDER = 43,
  W2_INCOME = 44,
  CAPITAL_GAINS_INCOME = 45,
  INTEREST_INCOME = 46,
  DIVIDENTS_INCOME = 47,
  GOV_BENEFITS_INCOME = 48,
  W2_WORK = 49,
  CPA_ADVICE_ON_CATEGORIES = 50,
  CPA_HELP = 51,
  FEDERAL_W2_WITHHELD = 52,
  STATE_W2_WITHHELD = 53,
  SEPARATE_OFFICE = 54,
  OTHER_PERSONAL_DEDUCTIONS = 55,
  HOME_ADDRESS_AS_BUSINESS_ADDRESS = 56,
  BUSINESS_ADDRESS = 57,
  ANNUAL_INCOME_RANGE = 58,
  CLAIM_DEPRECATION = 59,
  SPOUSE_EMAIL = 60,
  SPOUSE_DOB = 61,
  PHONE_NUMBER = 62,
  BUSINESS_TYPE = 63,
  FEDERAL_MONTHLY_W2_WITHHELD = 64,
  STATE_MONTHLY_W2_WITHHELD = 65,
  FEDERAL_TAX_PAID = 66,
  STATE_TAX_PAID = 67,
  PREV_MONTH_INCOMES = 68,
  LIVING_OUTSIDE_USA = 69,
  MAILING_ADDRESS_OUTSIDE_USA = 70,
  SPOUSE_FREELANCER = 71,
  DOB = 72,
  REASON_FOR_NO_FREELANCE_INCOME = 73,
  SUGGESTED_INCOMES = 74,
  TAX_PROFILE_INCOME_SOURCES = 75,
  INCOMES_TO_ADD_LATER = 76,
  SKIP_LAST_YEAR_1040_REASON = 77,
  LAST_YEAR_1040_DE_CATEGORIES = 78,
  SPOUSE_QUARTERLY_TAX_PAYMENT = 79,
  APPLICABLE_CHILD_CREDITS = 80,
  HAVE_MORTGAGE_FOR_OFFICE = 81,
  PAID_STUDENT_LOAN = 82,
  TOOK_BUSINESS_LOAN = 83,
  TOOK_BNPL_SERVICES = 84,
  PAID_WAGES_EMP_BENEFITS = 85,
  PAID_SELF = 86,
  IRA_CONTRIBUTIONS = 87,
  PAID_HEALTH_INS_PREMIUM = 88,
  IS_HEALTH_INS_COMBINED = 89,
  HEALTH_INS_COMBINED_MONTHS = 90,
  IS_HEALTH_INS_MARKET_PURCHASED = 91,
  HOME_OFFICE_EXPENSE_CHOICE = 92,
  USED_PHONE_INTERNET_FOR_BUSINESS = 93,
  SELF_EDUCATIONAL_CREDITS = 94,
  SPOUSE_EDUCATIONAL_CREDITS = 95,
  IS_STUDENT = 96,
  IS_SPOUSE_STUDENT = 97,
  CLAIMED_HSA_CONTRIBUTIONS = 98,
  CLAIMED_TRADITIONAL_IRA = 99,
  CLAIMED_ROTH_IRA = 100,
  CLAIMED_SEP_SIMPLE_PLANS = 101,
  VEHICLE_USED_FOR_BUSINESS = 102,
  DEPRECIATION_FOUND_IN_LAST_YEAR_1040 = 103,
  CASUALTY_N_THEFT_LOSSES = 104,
  INTEREST_ON_INVESTMENT_LOANS = 105,
  GAMBLING_LOSSES = 106,
  LIVED_IN_USA_IN_TAX_YEAR = 107,
  EARNED_FROM_DIGITAL_ASSETS = 108,
  DONT_HAVE_BUSINESS_DETAILS = 109,
}

export enum FIELD_ID {
  FIRST_NAME = 'first_name_and_middle_initial',
  LAST_NAME = 'last_name',
  SSN = 'ssn',
  STREET_NUMBER = 'number_and_street',
  APARTMENT_NUMBER = 'apartment_number',
  CITY = 'city',
  STATE = 'state',
  ZIP_CODE = 'zip_code',
  SCHOOL_DISTRICT_CODE = 'school_district_code',
  DEP_FIRST_NAME = 'dependent_first_name',
  DEP_LAST_NAME = 'dependent_last_name',
  DEP_SSN = 'dependent_social_security_number',
  DEP_RELATION = 'relationship_to_you',
  DEP_BIRTHDATE = 'dependent_birthdate',
  DEP_US_CITIZEN = 'dependent_us_citizen',
  DEP_ITIN_NUMBER = 'dependent_itin_number',
  DEP_HAVE_SSN = 'dependent_have_ssn',
  BUSINESS_NAME = 'business_name',
  BUSINESS_REGISTERED = 'is_your_business_registered',
  EIN = 'EIN',
  FREELANCE_INCOME = 'freelance_income',
  W2_INCOME = 'w2_income',
  CAPITAL_GAINS_INCOME = 'capital_gains_income',
  INTEREST_INCOME = 'interest_income',
  DIVIDENDS_INCOME = 'dividends_income',
  GOV_BENEFITS_INCOME = 'government_benefits_income',
  OTHER_SOURCE_INCOME = 'other_sources_income',
  HOME_AREA = 'home_area',
  OFFICE_AREA = 'office_area',
  TOTAL_MILES = 'total_miles',
  BUSINESS_MILES = 'business_miles',
  FIRST_QUARTER_TAX = 'first_quarter_tax',
  SECOND_QUARTER_TAX = 'second_quarter_tax',
  THIRD_QUARTER_TAX = 'third_quarter_tax',
  FOURTH_QUARTER_TAX = 'fourth_quarter_tax',
  WORK_TYPE = 'work_type',
  PROFESSION = 'profession',
  FILING_STATUS = 'filing_status',
  SPOUSE_SSN = 'spouse_ssn',
  DEPENDENT = 'dependent',
  WFH = 'wfh',
  TRAVEL = 'travel',
  PHONE_PERCENTAGE = 'phone_percentage',
  INTERNET_PERCENTAGE = 'internet_percentage',
  MEAL = 'meal',
  PERSONAL_DEDUCTION = 'personal_deductions',
  EARNING_STATE = 'earning_state',
  INCOME_SOURCES = 'income_sources',
  FEDERAL_TAX = 'federal_tax',
  STATE_TAX = 'state_tax',
  SPOUSE_FREELANCE_INCOME = 'spouse_freelance_income',
  SPOUSE_W2_INCOME = 'spouse_w2_income',
  W2_WITHHELD = 'w2_withheld',
  DEPENDENT_DETAILS = 'dependent_details',
  MILES = 'miles',
  HOME_OFFICE_AREA = 'home_office_area',
  SPOUSE_EMAIL = 'spouse_email',
  SEPARATE_OFFICE = 'separate_office',
  HOME_ADDRESS_AS_BUSINESS_ADDRESS = 'home_address_as_business_address',
  SPOUSE_DOB = 'spouse_dob',
  PHONE_NUMBER = 'phone_number',
  BUSINESS_TYPE = 'business_type',
  FEDERAL_MONTHLY_W2_WITHHELD = 'federal_monthly_w2_withheld',
  STATE_MONTHLY_W2_WITHHELD = 'state_monthly_w2_withheld',
  FEDERAL_TAX_PAID = 'federal_tax_paid',
  STATE_TAX_PAID = 'state_tax_paid',
  LIVING_OUTSIDE_USA = 'living_outside_usa',
  MAILING_ADDRESS_OUTSIDE_USA = 'mailing_address_outside_usa',
  LIVED_FOR = 'lived_for',
}

export const TAX_PROFILE_FIELD_DISPLAY_NAME = {
  [FIELD_ID.FIRST_NAME]: 'first_name_and_middle_initial',
  [FIELD_ID.LAST_NAME]: 'last_name',
  [FIELD_ID.SSN]: 'ssn',
  [FIELD_ID.STREET_NUMBER]: 'number_and_street',
  [FIELD_ID.APARTMENT_NUMBER]: 'apartment_number',
  [FIELD_ID.CITY]: 'city',
  [FIELD_ID.STATE]: 'state',
  [FIELD_ID.ZIP_CODE]: 'zip_code',
  [FIELD_ID.DEP_FIRST_NAME]: 'dependent_first_name',
  [FIELD_ID.DEP_LAST_NAME]: 'dependent_last_name',
  [FIELD_ID.DEP_SSN]: 'dependent_social_security_number',
  [FIELD_ID.DEP_RELATION]: 'relationship_to_you',
  [FIELD_ID.BUSINESS_NAME]: 'business_name',
  // [FIELD_ID.BUSINESS_REGISTER]: 'is_your_business_registered',
  [FIELD_ID.EIN]: 'EIN',
  [FIELD_ID.FREELANCE_INCOME]: 'freelance_income',
  [FIELD_ID.W2_INCOME]: 'w2_income',
  [FIELD_ID.CAPITAL_GAINS_INCOME]: 'capital_gains_income',
  [FIELD_ID.INTEREST_INCOME]: 'interest_income',
  [FIELD_ID.DIVIDENDS_INCOME]: 'dividends_income',
  [FIELD_ID.GOV_BENEFITS_INCOME]: 'government_benefits_income',
  [FIELD_ID.OTHER_SOURCE_INCOME]: 'other_sources_income',
  [FIELD_ID.HOME_AREA]: 'home_area',
  [FIELD_ID.OFFICE_AREA]: 'office_area',
  [FIELD_ID.TOTAL_MILES]: 'total_miles',
  [FIELD_ID.BUSINESS_MILES]: 'business_miles',
  [FIELD_ID.FIRST_QUARTER_TAX]: 'first_quarter_tax',
  [FIELD_ID.SECOND_QUARTER_TAX]: 'second_quarter_tax',
  [FIELD_ID.THIRD_QUARTER_TAX]: 'third_quarter_tax',
  [FIELD_ID.FOURTH_QUARTER_TAX]: 'fourth_quarter_tax',
  [FIELD_ID.WORK_TYPE]: 'work_type',
  [FIELD_ID.PROFESSION]: 'profession',
  [FIELD_ID.FILING_STATUS]: 'filing_status',
  [FIELD_ID.SPOUSE_SSN]: 'spouse_ssn',
  [FIELD_ID.DEPENDENT]: 'dependent',
  [FIELD_ID.WFH]: 'wfh',
  [FIELD_ID.TRAVEL]: 'travel',
  [FIELD_ID.PHONE_PERCENTAGE]: 'phone_percentage',
  [FIELD_ID.INTERNET_PERCENTAGE]: 'internet_percentage',
  [FIELD_ID.MEAL]: 'meal',
  [FIELD_ID.PERSONAL_DEDUCTION]: 'personal_deductions',
  [FIELD_ID.EARNING_STATE]: 'earning_state',
  [FIELD_ID.INCOME_SOURCES]: 'income_sources',
  [FIELD_ID.FEDERAL_TAX]: 'federal_tax',
  [FIELD_ID.STATE_TAX]: 'state_tax',
  [FIELD_ID.SPOUSE_FREELANCE_INCOME]: 'spouse_freelance_income',
  [FIELD_ID.SPOUSE_W2_INCOME]: 'spouse_w2_income',
  [FIELD_ID.W2_WITHHELD]: 'w2_withheld,',
};

export enum ANSWER_TYPE {
  // Key-value, value will be string or number
  OBJECT_KEY_VALUE = 'OBJECT_KEY_VALUE',

  // Key-value, value could be string, number or list
  OBJECT_KEY_VALUE_LIST = 'OBJECT_KEY_VALUE_LIST',

  // List of string or number
  LIST = 'LIST',

  // Single number
  INTEGER = 'INTEGER',

  // Single string
  STRING = 'STRING',

  // Single boolean
  BOOLEAN = 'BOOLEAN',

  // List of objects, value of objects are string, number, or boolean (primitives)
  LIST_OBJECT_KEY_VALUE = 'LIST_OBJECT_KEY_VALUE',

  // Nested Objects key value
  NESTED_OBJECT_KEY_VALUE = 'NESTED_OBJECT_KEY_VALUE',
}

export enum TAX_PROFILE_SECTION {
  BASIC_DETAILS = 'Basic details',
  PROFESSION = 'Profession',
  TAX_FILING_INFO = 'Tax filing info',
  INCOME_DETAILS = 'Income details',
  EXPENSES_AND_DEDUCTION = 'Expenses and deduction',
  TAX_PAYMENTS = 'Tax Payments',
  OTHER = 'Other',
}

export const sectionOrdering: Array<TAX_PROFILE_SECTION> = [
  TAX_PROFILE_SECTION.BASIC_DETAILS,
  TAX_PROFILE_SECTION.PROFESSION,
  TAX_PROFILE_SECTION.TAX_FILING_INFO,
  TAX_PROFILE_SECTION.INCOME_DETAILS,
  TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
  TAX_PROFILE_SECTION.TAX_PAYMENTS,
  TAX_PROFILE_SECTION.OTHER,
];

export const TAX_PROFILE_STRUTURE = {
  [TAX_PROFILE_FIELDS.LEGAL_NAME]: {
    question_id: TAX_PROFILE_FIELDS.LEGAL_NAME,
    section: TAX_PROFILE_SECTION.BASIC_DETAILS,
    answer_type: ANSWER_TYPE.OBJECT_KEY_VALUE,
    tax_profile_fields_key_name: 'LEGAL_NAME',
    answered: false,
    answer: {
      [FIELD_ID.FIRST_NAME]: '',
      [FIELD_ID.LAST_NAME]: '',
    },
  },
  [TAX_PROFILE_FIELDS.SSN]: {
    question_id: TAX_PROFILE_FIELDS.SSN,
    section: TAX_PROFILE_SECTION.BASIC_DETAILS,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'SSN',
    display_name: 'ssn',
    answered: false,
    answer: '',
  },
  [TAX_PROFILE_FIELDS.HOME_ADDRESS]: {
    question_id: TAX_PROFILE_FIELDS.HOME_ADDRESS,
    section: TAX_PROFILE_SECTION.BASIC_DETAILS,
    answer_type: ANSWER_TYPE.OBJECT_KEY_VALUE_LIST,
    tax_profile_fields_key_name: 'HOME_ADDRESS',
    answered: false,
    answer: {
      [FIELD_ID.STREET_NUMBER]: '',
      [FIELD_ID.APARTMENT_NUMBER]: '',
      [FIELD_ID.STATE]: '',
      [FIELD_ID.CITY]: '',
      [FIELD_ID.ZIP_CODE]: '',
    },
  },
  [TAX_PROFILE_FIELDS.CLAIM_YOU_DEPENDENT]: {
    question_id: TAX_PROFILE_FIELDS.CLAIM_YOU_DEPENDENT,
    section: TAX_PROFILE_SECTION.TAX_FILING_INFO,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'CLAIM_YOU_DEPENDENT',
    display_name: 'CLAIM_YOU_DEPENDENT',
    answered: false,
    answer: '',
  },
  [TAX_PROFILE_FIELDS.FILING_STATUS]: {
    question_id: TAX_PROFILE_FIELDS.FILING_STATUS,
    section: TAX_PROFILE_SECTION.TAX_FILING_INFO,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'FILING_STATUS',
    display_name: FIELD_ID.FILING_STATUS,
    answered: false,
    answer: '',
  },
  [TAX_PROFILE_FIELDS.SPOUSE_LEGAL_NAME]: {
    question_id: TAX_PROFILE_FIELDS.SPOUSE_LEGAL_NAME,
    section: TAX_PROFILE_SECTION.TAX_FILING_INFO,
    answer_type: ANSWER_TYPE.OBJECT_KEY_VALUE,
    tax_profile_fields_key_name: 'SPOUSE_LEGAL_NAME',
    answered: false,
    answer: {
      [FIELD_ID.FIRST_NAME]: '',
      [FIELD_ID.LAST_NAME]: '',
    },
  },
  [TAX_PROFILE_FIELDS.SPOUSE_SSN]: {
    question_id: TAX_PROFILE_FIELDS.SPOUSE_SSN,
    section: TAX_PROFILE_SECTION.TAX_FILING_INFO,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'SPOUSE_SSN',
    display_name: FIELD_ID.SSN,
    answered: false,
    answer: '',
  },
  [TAX_PROFILE_FIELDS.SPOUSE_CLAIMED_DEPENDENT]: {
    question_id: TAX_PROFILE_FIELDS.SPOUSE_CLAIMED_DEPENDENT,
    section: TAX_PROFILE_SECTION.TAX_FILING_INFO,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'SPOUSE_CLAIMED_DEPENDENT',
    display_name: 'SPOUSE_CLAIMED_DEPENDENT',
    answered: false,
    answer: '',
  },
  [TAX_PROFILE_FIELDS.DEPENDENT_DETAILS]: {
    question_id: TAX_PROFILE_FIELDS.DEPENDENT_DETAILS,
    section: TAX_PROFILE_SECTION.TAX_FILING_INFO,
    answer_type: ANSWER_TYPE.LIST_OBJECT_KEY_VALUE,
    tax_profile_fields_key_name: 'DEPENDENT_DETAILS',
    display_name: 'DEPENDENT_DETAILS',
    answered: false,
    answer: [],
  },
  [TAX_PROFILE_FIELDS.EARNING_STATES]: {
    question_id: TAX_PROFILE_FIELDS.EARNING_STATES,
    section: TAX_PROFILE_SECTION.INCOME_DETAILS,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'EARNING_STATES',
    display_name: 'EARNING_STATES',
    answered: false,
    answer: [],
  },
  [TAX_PROFILE_FIELDS.INCOME_SOURCES]: {
    question_id: TAX_PROFILE_FIELDS.INCOME_SOURCES,
    section: TAX_PROFILE_SECTION.INCOME_DETAILS,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'INCOME_SOURCES',
    display_name: 'INCOME_SOURCES',
    answered: false,
    answer: '',
  },
  [TAX_PROFILE_FIELDS.YOUR_N_SPOUSE_INCOME_SOURCES]: {
    question_id: TAX_PROFILE_FIELDS.YOUR_N_SPOUSE_INCOME_SOURCES,
    section: TAX_PROFILE_SECTION.INCOME_DETAILS,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'YOUR_N_SPOUSE_INCOME_SOURCES',
    display_name: 'YOUR_N_SPOUSE_INCOME_SOURCES',
    answered: false,
    answer: [],
  },
  [TAX_PROFILE_FIELDS.FREELANCE_EARNINGS]: {
    question_id: TAX_PROFILE_FIELDS.FREELANCE_EARNINGS,
    section: TAX_PROFILE_SECTION.INCOME_DETAILS,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'FREELANCE_EARNINGS',
    display_name: 'FREELANCE_EARNINGS',
    answered: false,
    answer: 0,
  },
  [TAX_PROFILE_FIELDS.RETIREMENT_PLAN_CONTRIBUTIONS]: {
    question_id: TAX_PROFILE_FIELDS.RETIREMENT_PLAN_CONTRIBUTIONS,
    section: TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'RETIREMENT_PLAN_CONTRIBUTIONS',
    display_name: 'RETIREMENT_PLAN_CONTRIBUTIONS',
    answered: false,
    answer: [],
  },
  [TAX_PROFILE_FIELDS.DEDUCTION_OPTION]: {
    question_id: TAX_PROFILE_FIELDS.DEDUCTION_OPTION,
    section: TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'DEDUCTION_OPTION',
    display_name: 'DEDUCTION_OPTION',
    answered: false,
    answer: '',
  },
  [TAX_PROFILE_FIELDS.ITEMIZE_DEDUCTION]: {
    question_id: TAX_PROFILE_FIELDS.ITEMIZE_DEDUCTION,
    section: TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'ITEMIZE_DEDUCTION',
    display_name: 'ITEMIZE_DEDUCTION',
    answered: false,
    answer: [],
  },
  [TAX_PROFILE_FIELDS.STANDARD_DEDUCTION]: {
    question_id: TAX_PROFILE_FIELDS.STANDARD_DEDUCTION,
    section: TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'STANDARD_DEDUCTION',
    display_name: 'STANDARD_DEDUCTION',
    answered: false,
    answer: [],
  },
  [TAX_PROFILE_FIELDS.ESTIMATED_TAX_PAYMENTS]: {
    question_id: TAX_PROFILE_FIELDS.ESTIMATED_TAX_PAYMENTS,
    section: TAX_PROFILE_SECTION.TAX_PAYMENTS,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'ESTIMATED_TAX_PAYMENTS',
    display_name: 'ESTIMATED_TAX_PAYMENTS',
    answered: false,
    answer: [],
  },
  [TAX_PROFILE_FIELDS.RECEIVED_ADV_CHILD_TAX_CREDITS]: {
    question_id: TAX_PROFILE_FIELDS.RECEIVED_ADV_CHILD_TAX_CREDITS,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'RECEIVED_ADV_CHILD_TAX_CREDITS',
    display_name: 'RECEIVED_ADV_CHILD_TAX_CREDITS',
    answered: false,
    answer: '',
  },
  [TAX_PROFILE_FIELDS.BUSINESS_DETAILS]: {
    question_id: TAX_PROFILE_FIELDS.BUSINESS_DETAILS,
    section: TAX_PROFILE_SECTION.PROFESSION,
    answer_type: ANSWER_TYPE.OBJECT_KEY_VALUE,
    tax_profile_fields_key_name: 'BUSINESS_DETAILS',
    answered: false,
    answer: {
      [FIELD_ID.BUSINESS_NAME]: '',
      [FIELD_ID.BUSINESS_REGISTER]: '',
      [FIELD_ID.EIN]: '',
    },
  },
  [TAX_PROFILE_FIELDS.WORK_TYPE]: {
    question_id: TAX_PROFILE_FIELDS.WORK_TYPE,
    section: TAX_PROFILE_SECTION.PROFESSION,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'WORK_TYPE',
    display_name: FIELD_ID.WORK_TYPE,
    answered: false,
    answer: [],
  },
  [TAX_PROFILE_FIELDS.PROFESSION]: {
    question_id: TAX_PROFILE_FIELDS.PROFESSION,
    section: TAX_PROFILE_SECTION.PROFESSION,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'PROFESSION',
    display_name: FIELD_ID.PROFESSION,
    answered: false,
    answer: [],
  },
  [TAX_PROFILE_FIELDS.PHONE_INTERNET_PCT]: {
    question_id: TAX_PROFILE_FIELDS.PHONE_INTERNET_PCT,
    section: TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'PHONE_INTERNET_PCT',
    display_name: 'PHONE_INTERNET_PCT',
    answered: false,
    answer: 0,
  },
  [TAX_PROFILE_FIELDS.WFH]: {
    question_id: TAX_PROFILE_FIELDS.WFH,
    section: TAX_PROFILE_SECTION.PROFESSION,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'WFH',
    display_name: FIELD_ID.WFH,
    answered: false,
    answer: '',
  },
  [TAX_PROFILE_FIELDS.HOME_OFFICE_AREA]: {
    question_id: TAX_PROFILE_FIELDS.HOME_OFFICE_AREA,
    section: TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
    answer_type: ANSWER_TYPE.OBJECT_KEY_VALUE,
    tax_profile_fields_key_name: 'HOME_OFFICE_AREA',
    answered: false,
    answer: {
      [FIELD_ID.HOME_AREA]: 0,
      [FIELD_ID.OFFICE_AREA]: 0,
    },
  },
  [TAX_PROFILE_FIELDS.PHONE_PCT]: {
    question_id: TAX_PROFILE_FIELDS.PHONE_PCT,
    section: TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'PHONE_PCT',
    display_name: FIELD_ID.PHONE_PERCENTAGE,
    answered: false,
    answer: 0,
  },
  [TAX_PROFILE_FIELDS.INTERNET_PCT]: {
    question_id: TAX_PROFILE_FIELDS.INTERNET_PCT,
    section: TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'INTERNET_PCT',
    display_name: FIELD_ID.INTERNET_PERCENTAGE,
    answered: false,
    answer: 0,
  },
  [TAX_PROFILE_FIELDS.TRAVEL]: {
    question_id: TAX_PROFILE_FIELDS.TRAVEL,
    section: TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'TRAVEL',
    display_name: FIELD_ID.TRAVEL,
    answered: false,
    answer: [],
  },
  [TAX_PROFILE_FIELDS.MILES]: {
    question_id: TAX_PROFILE_FIELDS.MILES,
    section: TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
    answer_type: ANSWER_TYPE.OBJECT_KEY_VALUE,
    tax_profile_fields_key_name: 'MILES',
    answered: false,
    answer: {
      [FIELD_ID.TOTAL_MILES]: 0,
      [FIELD_ID.BUSINESS_MILES]: 0,
    },
  },
  [TAX_PROFILE_FIELDS.MEALS]: {
    question_id: TAX_PROFILE_FIELDS.MEALS,
    section: TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'MEALS',
    display_name: FIELD_ID.MEAL,
    answered: false,
    answer: '',
  },
  [TAX_PROFILE_FIELDS.PERSONAL_DEDUCTIONS]: {
    question_id: TAX_PROFILE_FIELDS.PERSONAL_DEDUCTIONS,
    section: TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'PERSONAL_DEDUCTIONS',
    display_name: FIELD_ID.PERSONAL_DEDUCTION,
    answered: false,
    answer: [],
  },
  [TAX_PROFILE_FIELDS.FEDERAL_TAX]: {
    question_id: TAX_PROFILE_FIELDS.FEDERAL_TAX,
    section: TAX_PROFILE_SECTION.TAX_PAYMENTS,
    answer_type: ANSWER_TYPE.OBJECT_KEY_VALUE,
    tax_profile_fields_key_name: 'FEDERAL_TAX',
    answered: false,
    answer: {},
  },
  [TAX_PROFILE_FIELDS.STATE_TAX]: {
    question_id: TAX_PROFILE_FIELDS.STATE_TAX,
    section: TAX_PROFILE_SECTION.TAX_PAYMENTS,
    answer_type: ANSWER_TYPE.NESTED_OBJECT_KEY_VALUE,
    tax_profile_fields_key_name: 'STATE_TAX',
    answered: false,
    answer: {},
  },
  [TAX_PROFILE_FIELDS.DEPENDENT]: {
    question_id: TAX_PROFILE_FIELDS.DEPENDENT,
    section: TAX_PROFILE_SECTION.TAX_FILING_INFO,
    answer_type: ANSWER_TYPE.BOOLEAN,
    tax_profile_fields_key_name: 'DEPENDENT',
    display_name: FIELD_ID.DEPENDENT,
    answered: false,
    answer: '',
  },
  [TAX_PROFILE_FIELDS.HOME_OFFICE_TXNS]: {
    question_id: TAX_PROFILE_FIELDS.HOME_OFFICE_TXNS,
    section: TAX_PROFILE_SECTION.EXPENSES_AND_DEDUCTION,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'HOME_OFFICE_TXNS',
    display_name: 'HOME_OFFICE_TXNS',
    answered: false,
    answer: [],
  },
  [TAX_PROFILE_FIELDS.FREELANCE_TIME]: {
    question_id: TAX_PROFILE_FIELDS.FREELANCE_TIME,
    section: TAX_PROFILE_SECTION.PROFESSION,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'FREELANCE_TIME',
    display_name: 'FREELANCE_TIME',
    answered: false,
    answer: '',
  },
  [TAX_PROFILE_FIELDS.W2_INCOME]: {
    question_id: TAX_PROFILE_FIELDS.W2_INCOME,
    section: TAX_PROFILE_SECTION.INCOME_DETAILS,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'W2_INCOME',
    display_name: FIELD_ID.W2_INCOME,
    answered: false,
    answer: 0,
  },
  [TAX_PROFILE_FIELDS.SPOUSE_FREELANCE_INCOME]: {
    question_id: TAX_PROFILE_FIELDS.SPOUSE_FREELANCE_INCOME,
    section: TAX_PROFILE_SECTION.INCOME_DETAILS,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'SPOUSE_FREELANCE_INCOME',
    display_name: FIELD_ID.SPOUSE_FREELANCE_INCOME,
    answered: false,
    answer: 0,
  },
  [TAX_PROFILE_FIELDS.SPOUSE_W2_INCOME]: {
    question_id: TAX_PROFILE_FIELDS.SPOUSE_W2_INCOME,
    section: TAX_PROFILE_SECTION.INCOME_DETAILS,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'SPOUSE_W2_INCOME',
    display_name: FIELD_ID.SPOUSE_W2_INCOME,
    answered: false,
    answer: 0,
  },
  [TAX_PROFILE_FIELDS.FILED_TAXES]: {
    question_id: TAX_PROFILE_FIELDS.FILED_TAXES,
    section: TAX_PROFILE_SECTION.TAX_FILING_INFO,
    answer_type: ANSWER_TYPE.BOOLEAN,
    tax_profile_fields_key_name: 'FILED_TAXES',
    display_name: 'FILED_TAXES',
    answered: false,
    answer: '',
  },
  [TAX_PROFILE_FIELDS.INTENT]: {
    question_id: TAX_PROFILE_FIELDS.INTENT,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'INTENT',
    display_name: 'INTENT',
    answered: false,
    answer: [],
  },
  [TAX_PROFILE_FIELDS.OTHER_SOURCE_INCOME]: {
    question_id: TAX_PROFILE_FIELDS.OTHER_SOURCE_INCOME,
    section: TAX_PROFILE_SECTION.INCOME_DETAILS,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'OTHER_SOURCE_INCOME',
    display_name: FIELD_ID.OTHER_SOURCE_INCOME,
    answered: false,
    answer: 0,
  },
  [TAX_PROFILE_FIELDS.FILED_TAX_EXTENSION]: {
    question_id: TAX_PROFILE_FIELDS.FILED_TAX_EXTENSION,
    section: TAX_PROFILE_SECTION.TAX_FILING_INFO,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'FILED_TAX_EXTENSION',
    display_name: 'FILED_TAX_EXTENSION',
    answered: false,
    answer: '',
  },
  [TAX_PROFILE_FIELDS.TAX_DEADLINE_REMINDER]: {
    question_id: TAX_PROFILE_FIELDS.TAX_DEADLINE_REMINDER,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'TAX_DEADLINE_REMINDER',
    display_name: 'TAX_DEADLINE_REMINDER',
    answered: false,
    answer: '',
  },
  [TAX_PROFILE_FIELDS.CAPITAL_GAINS_INCOME]: {
    question_id: TAX_PROFILE_FIELDS.CAPITAL_GAINS_INCOME,
    section: TAX_PROFILE_SECTION.INCOME_DETAILS,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'CAPITAL_GAINS_INCOME',
    display_name: FIELD_ID.CAPITAL_GAINS_INCOME,
    answered: false,
    answer: 0,
  },
  [TAX_PROFILE_FIELDS.INTEREST_INCOME]: {
    question_id: TAX_PROFILE_FIELDS.INTEREST_INCOME,
    section: TAX_PROFILE_SECTION.INCOME_DETAILS,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'INTEREST_INCOME',
    display_name: FIELD_ID.INTEREST_INCOME,
    answered: false,
    answer: 0,
  },
  [TAX_PROFILE_FIELDS.DIVIDENTS_INCOME]: {
    question_id: TAX_PROFILE_FIELDS.DIVIDENTS_INCOME,
    section: TAX_PROFILE_SECTION.INCOME_DETAILS,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'DIVIDENTS_INCOME',
    display_name: FIELD_ID.DIVIDENDS_INCOME,
    answered: false,
    answer: 0,
  },
  [TAX_PROFILE_FIELDS.GOV_BENEFITS_INCOME]: {
    question_id: TAX_PROFILE_FIELDS.GOV_BENEFITS_INCOME,
    section: TAX_PROFILE_SECTION.INCOME_DETAILS,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'GOV_BENEFITS_INCOME',
    display_name: FIELD_ID.GOV_BENEFITS_INCOME,
    answered: false,
    answer: 0,
  },
  [TAX_PROFILE_FIELDS.FEDERAL_W2_WITHHELD]: {
    question_id: TAX_PROFILE_FIELDS.FEDERAL_W2_WITHHELD,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'FEDERAL_W2_WITHHELD',
    display_name: 'FEDERAL_W2_WITHHELD',
    answered: false,
    answer: 0,
  },
  [TAX_PROFILE_FIELDS.STATE_W2_WITHHELD]: {
    question_id: TAX_PROFILE_FIELDS.STATE_W2_WITHHELD,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.INTEGER,
    tax_profile_fields_key_name: 'STATE_W2_WITHHELD',
    display_name: 'STATE_W2_WITHHELD',
    answered: false,
    answer: 0,
  },
  [TAX_PROFILE_FIELDS.SEPARATE_OFFICE]: {
    question_id: TAX_PROFILE_FIELDS.SEPARATE_OFFICE,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.BOOLEAN,
    tax_profile_fields_key_name: 'SEPARATE_OFFICE',
    display_name: 'SEPARATE_OFFICE',
    answered: false,
    answer: false,
  },
  [TAX_PROFILE_FIELDS.OTHER_PERSONAL_DEDUCTIONS]: {
    question_id: TAX_PROFILE_FIELDS.OTHER_PERSONAL_DEDUCTIONS,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.LIST,
    tax_profile_fields_key_name: 'OTHER_PERSONAL_DEDUCTIONS',
    display_name: 'OTHER_PERSONAL_DEDUCTIONS',
    answered: false,
    answer: [],
  },
  [TAX_PROFILE_FIELDS.HOME_ADDRESS_AS_BUSINESS_ADDRESS]: {
    question_id: TAX_PROFILE_FIELDS.HOME_ADDRESS_AS_BUSINESS_ADDRESS,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.BOOLEAN,
    tax_profile_fields_key_name: 'HOME_ADDRESS_AS_BUSINESS_ADDRESS',
    display_name: 'HOME_ADDRESS_AS_BUSINESS_ADDRESS',
    answered: false,
    answer: false,
  },
  [TAX_PROFILE_FIELDS.BUSINESS_ADDRESS]: {
    question_id: TAX_PROFILE_FIELDS.BUSINESS_ADDRESS,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.OBJECT_KEY_VALUE_LIST,
    tax_profile_fields_key_name: 'BUSINESS_ADDRESS',
    display_name: 'BUSINESS_ADDRESS',
    answered: false,
    answer: {},
  },
  [TAX_PROFILE_FIELDS.ANNUAL_INCOME_RANGE]: {
    question_id: TAX_PROFILE_FIELDS.ANNUAL_INCOME_RANGE,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'ANNUAL_INCOME_RANGE',
    display_name: 'ANNUAL_INCOME_RANGE',
    answered: false,
    answer: '',
  },
  [TAX_PROFILE_FIELDS.CLAIM_DEPRECATION]: {
    question_id: TAX_PROFILE_FIELDS.CLAIM_DEPRECATION,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.BOOLEAN,
    tax_profile_fields_key_name: 'CLAIM_DEPRECATION',
    display_name: 'CLAIM_DEPRECATION',
    answered: false,
    answer: false,
  },
  [TAX_PROFILE_FIELDS.SPOUSE_EMAIL]: {
    question_id: TAX_PROFILE_FIELDS.SPOUSE_EMAIL,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'SPOUSE_EMAIL',
    display_name: 'SPOUSE_EMAIL',
    answered: false,
    answer: '',
  },
  [TAX_PROFILE_FIELDS.SPOUSE_DOB]: {
    question_id: TAX_PROFILE_FIELDS.SPOUSE_DOB,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'SPOUSE_DOB',
    display_name: 'SPOUSE_DOB',
    answered: false,
    answer: '',
  },
  [TAX_PROFILE_FIELDS.PHONE_NUMBER]: {
    question_id: TAX_PROFILE_FIELDS.PHONE_NUMBER,
    section: TAX_PROFILE_SECTION.OTHER,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'PHONE_NUMBER',
    display_name: 'PHONE_NUMBER',
    answered: false,
    answer: '',
  },
  [TAX_PROFILE_FIELDS.BUSINESS_TYPE]: {
    question_id: TAX_PROFILE_FIELDS.BUSINESS_TYPE,
    section: TAX_PROFILE_SECTION.PROFESSION,
    answer_type: ANSWER_TYPE.STRING,
    tax_profile_fields_key_name: 'BUSINESS_TYPE',
    display_name: 'BUSINESS_TYPE',
    answered: false,
    answer: '',
  },
};

export const parseTaxProfileBackendResponse = (
  backendResponse: Array<{question_id: TAX_PROFILE_FIELDS; answer: any}>,
) => {
  const clonedState: typeof TAX_PROFILE_STRUTURE = _.cloneDeep(
    TAX_PROFILE_STRUTURE,
  );

  try {
    backendResponse.forEach(({question_id, answer}) => {
      // @ts-ignore
      const questionObject = clonedState[question_id];
      if (questionObject === undefined) {
        // return alert(
        //   `ReviewDB frontend is not updated with latest question, this answer to some questions wont be visible (qid: ${question_id})`,
        // );
        return '';
      }
      questionObject.answered = true;
      questionObject.answer = answer;
      return questionObject;
    });
  } catch (e) {
    alert(e);
  }

  return _.groupBy(clonedState, 'section');
};
