import {Typography} from '@mui/material';
import React, {useState} from 'react';
import useCurrentTaxReturnId from 'src/CpaCenterV2/hooks/useCurrentTaxReturnId';
import DSButton from 'src/DesignSystem/Button/Button';
import ManualSignNowIdPopup from './components/ManualSignNowIdPopup';
import Threads from './components/Threads';
import ReviewReturnPlaceholderPopup from './components/ReviewReturnPlaceholderPopup';
import {useSelector} from 'react-redux';
import {selectThreads} from 'src/store/threads/threads.selector';
import {ThreadStatus} from 'src/store/threads/threads.reducer';
import useLayout from 'src/CpaCenterV2/hooks/useLayout';

const CpaReview = ({url}: {url: string | null}) => {
  const {returnId} = useCurrentTaxReturnId();
  const {threads} = useSelector(selectThreads);

  const pendintNotesCount = threads.filter(
    (thread) => thread.status === ThreadStatus.OPEN,
  ).length;

  const [isReviewReturnPopup, setIsReviewReturnPopup] = useState(false);
  const [isManualSingNowPopup, setIsManualSignNowPopup] = useState(false);
  const {spaceLeftBelowTaxReturnSection, TAX_RETURN_BOTTOM_BAR} = useLayout();

  const onCloseReviewReturnPopup = () => {
    setIsReviewReturnPopup(false);
  };

  const openReviewReturnPopup = () => {
    setIsReviewReturnPopup(true);
  };

  const openManualSignNowPopup = () => {
    setIsManualSignNowPopup(true);
    onCloseReviewReturnPopup();
  };
  const closeManualSingnowPopup = () => {
    setIsManualSignNowPopup(false);
  };
  if (!url) {
    return null;
  }

  return (
    <div>
      <div style={{display: 'flex'}}>
        <iframe
          title="Review-esign-return"
          src={url}
          style={{
            width: '70%',
            height: spaceLeftBelowTaxReturnSection - TAX_RETURN_BOTTOM_BAR,
          }}
        />
        <div
          style={{
            height: spaceLeftBelowTaxReturnSection - TAX_RETURN_BOTTOM_BAR,
            width: '30%',
          }}>
          <Threads />
        </div>
      </div>
      <div
        style={{
          height: TAX_RETURN_BOTTOM_BAR,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Typography style={{marginLeft: 4, fontWeight: 600}}>
          You can send the uploaded return to the user since there are no
          pending notes!
        </Typography>
        <DSButton
          type="primary"
          text="Prepare for Review & e-sign"
          style={{paddingInline: '7%', paddingBlock: 12}}
          disabled={pendintNotesCount > 0}
          onClick={openReviewReturnPopup}
        />
      </div>
      <ReviewReturnPlaceholderPopup
        url={url}
        isOpen={isReviewReturnPopup}
        onClose={onCloseReviewReturnPopup}
        onSendManually={openManualSignNowPopup}
      />
      <ManualSignNowIdPopup
        isOpen={isManualSingNowPopup}
        onClose={closeManualSingnowPopup}
        url={url}
      />
    </div>
  );
};

export default CpaReview;
