import React from 'react';
import {BrowserRouter, Route, Routes, useNavigate} from 'react-router-dom';
import axios from 'axios';
import MissingExpenses from './MissingExpenses/MissingExpenses';
import UserInfoRouter from './CpaCenterUserInfo/UserInfoRouter';
import ReactAdmin from './ReactAdmin/ReactAdmin';
import LoginPage from './LoginPage';
import CpaCenterList from './CpaCenterList/CpaCenterList';
import {APP_FLOWS, RouteName} from './constants/routeName';
import CpaCenterDrawer from './common/Drawer/CpaCenterDrawer';
import UserQueryRouter from './UserQueries/UserQueryRouter';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import UtilityProvider from './InternalUtilities/UtilityProvider/UtilityProvider';
import {pdfjs} from 'react-pdf';
import CpaCenterV2Router from './CpaCenterV2/CpaCenterV2Router';
import Notify from './DesignSystem/Notify/Notify';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Interceptor = () => {
  const navigate = useNavigate();
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      console.log(error);
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        localStorage.clear();
        navigate(RouteName.ReactAdmin);
      }
      return Promise.reject(error);
    },
  );
  return null;
};

const App = () => {
  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Notify />
        <Interceptor />
        <Routes>
          <Route
            path={`/${RouteName.TaxFiling}/*`}
            element={<UserInfoRouter />}
          />
          <Route path={RouteName.ReactAdmin} element={<ReactAdmin />} />
          <Route
            path={RouteName.MissingExpenses}
            element={<MissingExpenses />}
          />
          <Route path={RouteName.Login} element={<LoginPage />} />
          <Route element={<CpaCenterDrawer />}>
            <Route path={RouteName.TaxFiling} element={<CpaCenterList />} />
            <Route
              path={`/${RouteName.UserQueries.UserQueries}/*`}
              element={<UserQueryRouter />}
            />
          </Route>
          <Route
            path="/missingexpenses/:flyfin_user_id/*"
            element={<MissingExpenses />}
          />
          <Route
            path={`${APP_FLOWS.InternalUtilities}/*`}
            element={<UtilityProvider />}
          />
          <Route
            path={`${RouteName.CpaCenterV2}/*`}
            element={<CpaCenterV2Router />}
          />
        </Routes>
      </LocalizationProvider>
    </BrowserRouter>
  );
};

export default App;
