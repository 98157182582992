import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
  deleteCpaQueryMessage,
  getQueriesList,
  getTaxProfile,
  patchCpaQueryMessage,
  postDraftMessage,
  postMessage,
} from 'src/appApi';
import {
  AuthorType,
  CPAQuestionMessageType,
  LocalStorageKey,
  themmeColor,
} from 'src/constants/constants';
import {
  parseTaxProfileBackendResponse,
  sectionOrdering,
  TAX_PROFILE_FIELDS,
} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import RateConversationModal from 'src/UserQueries/components/RateConversationModal';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GenerateTaxProfileField, {
  GenerateTaxProfileFieldProps,
} from 'src/UserQueries/components/GenerateTaxProfileFields';
import {BackendQuery} from 'src/types/query.types';
import _ from 'lodash';
import CpaChat, {SendMessageHandler} from 'src/common/CpaChat/CpaChat';
import {getCurrentUserId} from 'src/authProvider';
import {makeStyles} from '@material-ui/core/styles';
import QueryPageHeader from 'src/UserQueries/QueryPageHeader';
import {RatingPermissionUserEmails, SupportedQueryTypes} from 'src/UserQueries/Queries.constants';
import QueryChatHeader from 'src/common/CpaChat/QueryChatHeader';

const useStyles = makeStyles({
  loader: {display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center'},
  rightContainer: {
    height: '100%',
    overflowY: 'auto',
  },
  chatContainer: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    border: `1px solid ${themmeColor.silver}`,
    borderRadius: 12,
  },
  otherQueryTitle: {
    fontWeight: 'bold',
    fontSize: 13,
    marginTop: 16,
  },
  otherQuery: {
    border: `1px solid ${themmeColor.silver}`,
    borderRadius: 12,
    overflow: 'hidden',
    marginTop: 12,
    cursor: 'pointer',
  },
});

const CpaQueryViewer = () => {
  const styles = useStyles();
  const [queryData, setQueryData] = useState<BackendQuery>({});
  const [otherQueries, setOtherQueries] = useState<Array<BackendQuery>>([]);
  const [taxProfile, setTaxProfile] = useState({});
  const [showRatingModal, setShowRatingModal] = useState(false);
  const {queryId} = useParams();
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(true);

  const userEmail = useMemo(() => localStorage.getItem(LocalStorageKey.UserEmail), []);

  const isRatingPermitted = process.env.REACT_APP_ENVIRONMENT === 'production'
    ? RatingPermissionUserEmails.includes(userEmail)
    : true;

  const fetchQuery = async () => {
    const queryResponse = await getQueriesList({id: queryId, is_conversation_required: true});
    const query = queryResponse.data.results[0];
    setQueryData(query);
    return query;
  };

  const fetchQueryAndTaxProfile = async () => {
    setPageLoading(true);
    try {
      const query = await fetchQuery();
      Promise.all([
        await getTaxProfile({
          fly_user_id: query.fly_user_id,
          years: query.app_year,
        }),
        await getQueriesList({
          fly_user_id: query.fly_user_id,
          app_year: query.app_year,
          type: SupportedQueryTypes
        }),
      ]).then(([taxProfileResponse, otherQueriesResponse]) => {
        const taxProfileData = taxProfileResponse.data[query.app_year];
        setTaxProfile(
          parseTaxProfileBackendResponse(taxProfileData),
        );
        setOtherQueries(
          otherQueriesResponse.data.results.filter(
            (otherQuery) => query.id !== otherQuery.id,
          ),
        );
      });
      setPageLoading(false);
    } catch (e) {
      alert(e);
    }
  };

  const handleEditDraft = async (draftId: number, message: string) => {
    await patchCpaQueryMessage(draftId, {message});
    await fetchQuery();
  };

  const handleDeleteDraft = async (draftId: number) => {
    await deleteCpaQueryMessage(draftId);
    await fetchQuery();
  };

  const handlePostQuery: SendMessageHandler = async (message, extra) => {
    if (!queryId) return;
    if (extra.isDraft) {
      if (!extra.draftId) {
        alert('draft id is not present');
        return;
      }
      await postDraftMessage({
        draft_id: extra.draftId,
      });
    } else {
      await postMessage({
        message,
        cpa_question_id: queryId,
        author_type: AuthorType.CPA,
        type: CPAQuestionMessageType.CHAT,
        is_draft: extra.asDraft,
      });
    }
    await fetchQuery();
  };

  const goToAllQueries = () => {
    navigate('..');
  };

  useEffect(() => {
    fetchQueryAndTaxProfile();
  }, [queryId]);

  return (
    <Grid container gridRow={2} height={'100vh'} columnSpacing={1} overflow={'hidden'}>
      {pageLoading ? (
        <div className={styles.loader}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Grid container item xs={12} height={'10%'} minWidth={'100%'}>
            <QueryPageHeader
              query={queryData}
              goBack={goToAllQueries}
            />
          </Grid>
          <Grid item xs={5} height={'90%'} style={{overflowY: 'auto'}}>
            {sectionOrdering.map((sectionName) =>
                taxProfile[sectionName] && (
                  <Accordion>
                    <AccordionSummary sx={{backgroundColor: themmeColor.lightBlue}} expandIcon={<ExpandMoreIcon />}>
                      <Typography fontWeight={'bold'} fontSize={20}>{sectionName}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {taxProfile[sectionName].map((questionObject: GenerateTaxProfileFieldProps) =>
                        <GenerateTaxProfileField {...questionObject} />)}
                    </AccordionDetails>
                  </Accordion>
                ),
            )}
          </Grid>
          <Grid item xs={7} height={'90%'} style={{overflow: 'hidden'}}>
            {!_.isEmpty(queryData) &&
              <div className={styles.rightContainer}>
                <div className={styles.chatContainer}>
                  <CpaChat
                    queryData={queryData}
                    onSendMessage={handlePostQuery}
                    onDoneEditDraft={handleEditDraft}
                    onDeleteDraft={handleDeleteDraft}
                    flyUserId={queryData.fly_user_id}
                    cpaId={getCurrentUserId()}
                  />
                  {isRatingPermitted && (
                    <>
                      <Divider />
                      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button variant={'text'} onClick={() => setShowRatingModal(true)}>RATE CONVERSATION</Button>
                      </div>
                      <RateConversationModal
                        queryId={queryId}
                        ratingId={queryData.cpa_resp_rating_id}
                        open={showRatingModal}
                        closeModal={() => setShowRatingModal(false)}
                      />
                    </>
                  )}
                </div>
                <div style={{marginBottom: 16}}>
                  <Typography className={styles.otherQueryTitle}>
                    OTHER QUERIES FROM THE USER
                  </Typography>
                  {otherQueries.map((query) => (
                    <div onClick={() => navigate(`../${query.id}`, {replace: true})} className={styles.otherQuery}>
                      <QueryChatHeader queryData={query} showStatus />
                    </div>
                  ))}
                </div>
              </div>
            }
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default CpaQueryViewer;
