import {ExpandMore} from '@mui/icons-material';
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import {themmeColor} from 'src/constants/constants';
import Tag from '../Tag/Tag';

interface DSAccordionProps {
  name: string;
  children: any;
  summaryStyle?: React.CSSProperties;
  defaultExpanded?: boolean;
}

const DSAccordion = ({
  name,
  children,
  summaryStyle = {},
  defaultExpanded = false,
}: DSAccordionProps) => {
  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary
        style={{backgroundColor: themmeColor.cpaCenterV2Bg, ...summaryStyle}}
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Tag
          text={name}
          textColor={themmeColor.white}
          backgroundColor={themmeColor.grey}
        />
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default DSAccordion;
