import React, {useContext, useState} from 'react';
import {
  ALL_DOCS_TYPE,
  CPAQuestionType,
  CPA_CENTER_ACTIVE_YEAR,
  VALID_DOCUMENT_REASON,
  themmeColor,
} from 'src/constants/constants';

import {useNavigate, useParams} from 'react-router-dom';
import DocumentsContext, {
  DocumentType,
} from 'src/CpaCenterUserInfo/CpaCenterDocumentsOCR/DocumentsContext';
import AddMoreDocumentsOverlay, {
  SelectedDocTypeProps,
} from 'src/CpaCenterUserInfo/CpaCenterDocumentsOCR/components/AddMoreDocumentsOverlay';
import {Typography} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PreviewIcon from '@mui/icons-material/Preview';
import Tooltip from '@mui/material/Tooltip';
import {BackendDocTypeStatus} from 'src/CpaCenterUserInfo/CpaCenterDocumentsOCR/CpaCenterDocumentOCR.utils';
import {Assignment, Block, CheckCircle, Error} from '@mui/icons-material';
import {postTaxQuery} from 'src/appApi';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles({
  addDocButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    border: 'solid',
    borderColor: themmeColor.offWhite,
    borderRadius: 9,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 15,
    paddingRight: 12,
    marginLeft: 4,
    marginRight: 4,
    alignItems: 'center',
  },
  addDocIcon: {
    color: themmeColor.darkBlue,
    borderWidth: 1,
    borderRadius: 100,
    border: 'solid',
    borderColor: themmeColor.darkBlue,
  },
  docTypeSwitcherContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    marginLeft: 4,
    marginTop: 6,
    maxWidth: '75vw',
  },
  docTypeButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    border: 'solid',
    borderRadius: 9,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 15,
    paddingRight: 12,
    marginLeft: 4,
    marginRight: 4,
    borderWidth: ({isSelected}) => (isSelected ? '2px' : '1px'),
    borderColor: ({isSelected}) =>
      isSelected ? themmeColor.darkBlue : themmeColor.offWhite,
    position: 'relative',
  },
  pendingForReview: {
    position: 'absolute',
    right: 2,
    top: 2,
    backgroundColor: 'red',
    borderRadius: 100,
    height: 16,
    width: 16,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  docTypeTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

interface DocumentTypeButtonProps extends DocumentType {
  selectedDocumentTypeId?: number;
  allowClick?: boolean;
  onClick?: () => void;
}

const DocumentTypeButton: React.FC<DocumentTypeButtonProps> = ({
  documentTypeId,
  documentTypeName,
  status,
  selectedDocumentTypeId,
  pendingForReviewCount,
  allowClick = true,
  noUploadReason,
  onClick = () => {},
}) => {
  const isSelected = selectedDocumentTypeId === documentTypeId && allowClick;

  const [isHovered, setIsHovered] = useState(false);

  const handleOnClick = () => {
    if (!allowClick) return;
    onClick();
  };

  const renderIcon = () => {
    switch (status) {
      case BackendDocTypeStatus.APPROVED:
        return (
          <CheckCircle
            style={{alignSelf: 'center', color: themmeColor.successGreen}}
          />
        );
      case BackendDocTypeStatus.CAN_REVIEW:
        return (
          <PreviewIcon
            style={{alignSelf: 'center', color: themmeColor.darkBlue}}
          />
        );
      case BackendDocTypeStatus.USER_ACTION_REQUIRED:
        return (
          <Error style={{alignSelf: 'center', color: themmeColor.errorRed}} />
        );
      case BackendDocTypeStatus.NOT_APPLICABLE:
        return (
          <Block style={{alignSelf: 'center', color: themmeColor.grey}} />
        );
      case BackendDocTypeStatus.NEW:
        return (
          <Assignment style={{alignSelf: 'center', color: themmeColor.grey}} />
        );
      default:
        return (
          <Assignment style={{alignSelf: 'center', color: themmeColor.grey}} />
        );
    }
  };

  const styles = useStyles({isSelected});

  return (
    <Tooltip
      title={
        status === BackendDocTypeStatus.NOT_APPLICABLE ? noUploadReason : ''
      }>
      <div
        className={styles.docTypeButtonContainer}
        style={{
          backgroundColor: allowClick
            ? isHovered || isSelected
              ? themmeColor.focusedBlue
              : themmeColor.white
            : themmeColor.offWhite,
        }}
        onMouseEnter={() => allowClick && setIsHovered(true)}
        onMouseLeave={() => allowClick && setIsHovered(false)}
        onClick={handleOnClick}>
        {pendingForReviewCount !== 0 && allowClick && (
          <div className={styles.pendingForReview}>
            <Typography color={themmeColor.white} fontSize={10}>
              {pendingForReviewCount}
            </Typography>
          </div>
        )}
        <div style={{marginRight: 8, display: 'flex'}}>{renderIcon()}</div>
        <div className={styles.docTypeTextContainer}>
          <Typography
            fontSize={13}
            width={160}
            fontWeight={'600'}
            textOverflow="ellipsis"
            noWrap>
            {documentTypeName}
          </Typography>
          <Typography
            fontSize={13}
            width={160}
            textOverflow="ellipsis"
            noWrap
            color={'black'}>
            {status}
          </Typography>
        </div>
      </div>
    </Tooltip>
  );
};

const DocumentTypeSwitcher = () => {
  const {docsData, requiredDocTypes, fetchDocuments} =
    useContext(DocumentsContext);
  const {flyfin_user_id, docTypeId} = useParams();
  const navigate = useNavigate();
  const parsedDocTypeId = docTypeId ? parseInt(docTypeId, 10) : undefined;

  const [isAddDocModalOpen, setIsAddDocModal] = useState(false);

  // why this ?
  const backendDocsRequiredList = docsData?.map((doc) => doc.documentTypeName);

  const toBeExcluded = docsData
    .filter((docType) => docType.documents.length > 0)
    .map((docType) => docType.documentTypeName);

  const addDocumentOptions = ALL_DOCS_TYPE.filter(
    (docType) => !toBeExcluded.includes(docType),
  ).map((docType) => ({
    docType,
    label: docType,
  }));

  const missingDocs = requiredDocTypes?.filter(
    (requiredDoc) => !backendDocsRequiredList.includes(requiredDoc),
  );

  const handleAskMoreDocuments = async (
    comment: string,
    selectedDocType: SelectedDocTypeProps | null,
  ) => {
    await postTaxQuery({
      // @ts-ignore
      fly_user_id: flyfin_user_id,
      query: comment,
      doc_type: selectedDocType ? selectedDocType.docType : '',
      type: CPAQuestionType.TAX_FILING,
      sub_type: VALID_DOCUMENT_REASON.ADDITIONAL_DOC,
      app_year: CPA_CENTER_ACTIVE_YEAR,
    });
    await fetchDocuments();
  };

  const handleAddDocModalClose = () => {
    setIsAddDocModal(false);
  };

  const styles = useStyles({});

  const AddDocButton = () => {
    return (
      <div
        className={styles.addDocButtonContainer}
        onClick={() => setIsAddDocModal(true)}>
        <div>
          <AddIcon className={styles.addDocIcon} />
        </div>
        <div>
          <Typography
            fontSize={13}
            width={160}
            fontWeight={'600'}
            textOverflow="ellipsis"
            marginLeft={'8px'}
            alignSelf="center"
            noWrap>
            Ask more documents
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className={styles.docTypeSwitcherContainer}>
        {docsData.map((doc) => (
          <DocumentTypeButton
            key={doc.documentTypeId}
            selectedDocumentTypeId={parsedDocTypeId}
            {...doc}
            allowClick={doc.documents.length !== 0}
            onClick={() => navigate(doc.documentTypeId.toString())}
          />
        ))}
        {missingDocs?.map((documentType) => (
          // @ts-ignore
          <DocumentTypeButton
            selectedDocumentTypeId={parsedDocTypeId}
            documentTypeName={documentType}
            allowClick={false}
          />
        ))}
        <AddDocButton />
        <AddMoreDocumentsOverlay
          onClose={handleAddDocModalClose}
          isOpen={isAddDocModalOpen}
          options={addDocumentOptions}
          title="Request Additional Documents"
          subTitle="Please select the document type that you want to request"
          showCommentInput={true}
          handleSend={handleAskMoreDocuments}
        />
      </div>
    </div>
  );
};

export default DocumentTypeSwitcher;
