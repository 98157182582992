import {CPA_CENTER_ACTIVE_YEAR} from 'src/constants/constants';
import {TAX_PROFILE_FIELDS} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import {SET_TAX_PROFILE} from '../actionNames';
import {ReduxActionType, REDUX_STATUS} from '../types';

export interface TaxProfileQuestionType {
  question_id: TAX_PROFILE_FIELDS;
  answer: any;
}

const initialState = {
  status: REDUX_STATUS.INIT,
  loaded: false,
  [CPA_CENTER_ACTIVE_YEAR]: [] as TaxProfileQuestionType[],
};

export const taxProfile = (state = initialState, action: ReduxActionType) => {
  switch (action.type) {
    case SET_TAX_PROFILE:
      return {
        ...state,
        ...action.payload,
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
      };
  }
  return state;
};

export type TaxProfileStateType = typeof initialState;
