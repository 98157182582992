import React, {useEffect, useMemo, useState} from 'react';
import {Button, Table, TableContainer} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {useOutletContext, useSearchParams} from 'react-router-dom';

import {
  CPA_CENTER_ACTIVE_YEAR,
  DEFAULT_PER_PAGE,
  themmeColor,
  VALID_CPA_EMAILS,
} from 'src/constants/constants';
import {getUsersList} from 'src/appApi';
import FilterBar, {SearchPanelData} from 'src/common/FilterBar/FilterBar';
import ListTableHeader from 'src/common/Table/ListTableHeader';
import ListTableBody from 'src/common/Table/ListTableBody';
import {
  ACTION_TYPES,
  BACKEND_RESPONSE_KEYS,
  CPA_LIST_COLUMNS,
  getDateForNextAction,
  getDateTimeFromMonthDayString,
  getMonthDateFromDate,
  Record,
  USER_ACTIVITY_TYPE,
} from 'src/CpaCenterList/components/cpaList.utils';
import CpaListContext from 'src/CpaCenterList/components/CpaListContext';
import CPAAssignCell from 'src/CpaCenterList/components/CPAAssignCell/CPAAssignCell';
import NormalReadOnlyCell from 'src/CpaCenterList/components/NormalReadOnlyCell/NormalReadOnlyCell';
import UpdateReturnStatusCell from 'src/CpaCenterList/components/UpdateReturnStatusCell/UpdateReturnStatusCell';
import ActionCell from 'src/CpaCenterList/components/ActionCell/ActionCell';
import UpdateNoteCell from 'src/CpaCenterList/components/UpdateNoteCell/UpdateNoteCell';
import ActivityCell from 'src/CpaCenterList/components/ActivityCell/ActivityCell';
import NextActionDatePickerCell from 'src/CpaCenterV2/common/UserSummaryBar/components/NextActionDatePickerCell';
import CpaListFilterModal from 'src/CpaCenterList/components/CpaListFiltersModal';
import ExportColumns from 'src/CpaCenterList/components/ExportColumns/ExportColumns';
import Pagination from 'src/common/Pagination/Pagination';
import {parse} from 'querystring';
import {ESIGN_MODE, USER_COMMS_MODE} from 'src/store/user/user.reducer';
import {Mail, Smartphone} from '@mui/icons-material';
import CpaAcknowledgementDrawer from './components/CpaAcknowledgementDrawer/CpaAcknowledgementDrawer';
import {filterFields} from './CpaCenterList.constants';
import DSCheckBox from 'src/CpaCenterV2/common/DSCheckBox/DSCheckBox';
import DSButton from 'src/DesignSystem/Button/Button';

const CpaCenterList: React.FC = () => {
  const {openDrawer} = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isCpaAssignmentSelected, setIsCpaAssignmentSelected] =
    useState(false);
  const [selectedUsersToAck, setSelectedUsersToAck] = useState<number[]>([]);
  const [showCpaAckMessageDrawer, setShowCpaAckMessageDrawer] =
    useState(false);

  const defaultReverse = searchParams.get('reverse');
  const defaultSortingKey = searchParams.get('ordering');
  const defaultPlansKey = searchParams.getAll('selectedPlan');
  const defaultSelectedReturnStatus = searchParams.getAll(
    'selectedReturnStatus',
  );
  const defaultUserId = searchParams.get('userId');
  const defaultEmail = searchParams.get('email');
  const defaultName = searchParams.get('name');
  const defaultPageCount = searchParams.get('pageCount');
  const pageCount = defaultPageCount ? parseInt(defaultPageCount) : 1;

  const defaultSelectedCpaActions = searchParams.getAll('selectedCpaActions');
  const defaultSelectedReviewerActions = searchParams.getAll(
    'selectedReviewerActions',
  );
  const defaultSelectedCPAs = searchParams.getAll('selectedCPAs');
  const defaultSelectedReviewers = searchParams.getAll('selectedReviewers');
  const defaultSelectedDates = searchParams.getAll('selectedDates');
  const defaultSelectedTaxExtension = searchParams.getAll(
    'selectedTaxExtension',
  );
  const defaultSelectedActivity = searchParams.get('selectedActivity');
  const defaultSelectedTaxQueryStatus = searchParams.get(
    'selectedTaxQueryStatus',
  );
  const defaultSelectedCommsMode = searchParams.get(filterFields.COMMS_MODE);
  const defaultSelectedEsignMode = searchParams.get(filterFields.ESIGN_MODE);

  const [isPrev, setIsPrev] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [recordsArray, setRecordsArray] = useState<Array<Record>>([]);
  const [totalPages, setTotalPages] = useState(1);

  const getOrderingKey = () => {
    if (defaultSortingKey) {
      if (defaultReverse === 'true') {
        return {ordering: `-${defaultSortingKey}`};
      }
      return {ordering: `${defaultSortingKey}`};
    }
    return {};
  };

  const getIdEmailAndNameFilteringKeys = () => {
    let filters: {fly_user_id?: string; email?: string; name?: string} = {};
    if (defaultUserId) filters['fly_user_id'] = defaultUserId;
    if (defaultEmail) filters['email'] = defaultEmail;
    if (defaultName) filters['name'] = defaultName;
    return filters;
  };

  const getActivityFilter = () => {
    if (
      Object.values(USER_ACTIVITY_TYPE).includes(
        defaultSelectedActivity as USER_ACTIVITY_TYPE,
      )
    ) {
      return {[BACKEND_RESPONSE_KEYS.ACTIVITY]: defaultSelectedActivity};
    }
    return {};
  };

  const getTaxQueryStatusFilter = () => {
    if (defaultSelectedTaxQueryStatus === 'true') {
      return {[BACKEND_RESPONSE_KEYS.TAX_QUERY_OPEN]: true};
    }
    if (defaultSelectedTaxQueryStatus === 'false') {
      return {[BACKEND_RESPONSE_KEYS.TAX_QUERY_OPEN]: false};
    }
    return {};
  };

  const getFilterParams = () => {
    return {
      per_page: DEFAULT_PER_PAGE,
      year: CPA_CENTER_ACTIVE_YEAR,
      page: pageCount,
      status: defaultSelectedReturnStatus,
      ...getOrderingKey(),
      payment_plan: defaultPlansKey,
      ...getIdEmailAndNameFilteringKeys(),
      assigned_cpa: defaultSelectedCPAs,
      assigned_reviewer: defaultSelectedReviewers,
      cpa_action_required: defaultSelectedCpaActions,
      reviewer_action_required: defaultSelectedReviewerActions,
      next_action_date: defaultSelectedDates,
      comms_mode: defaultSelectedCommsMode,
      [BACKEND_RESPONSE_KEYS.ESIGN_MODE]: defaultSelectedEsignMode,
      [BACKEND_RESPONSE_KEYS.EXTENSION_STATUS]: defaultSelectedTaxExtension,
      ...getActivityFilter(),
      ...getTaxQueryStatusFilter(),
    };
  };

  const fetchUserList = async () => {
    try {
      setIsLoading(true);
      const params = getFilterParams();
      const response = await getUsersList(params);
      setRecordsArray(response.data.results);
      setIsPrev(!!response.data.previous);
      setIsNext(!!response.data.next);
      setTotalPages(Math.ceil(response.data.count / DEFAULT_PER_PAGE));
      setIsLoading(false);
    } catch (e) {
      alert('Something went wrong!!!, will reset filters');
      onClearFilter();
    }
  };

  const onClearFilter = () => {
    setSearchParams({});
  };

  const onClickSearchHandler = (data: SearchPanelData) => {
    setSearchParams((prevSearchParams) => {
      if (data.userId) prevSearchParams.set('userId', data.userId);
      if (data.email) prevSearchParams.set('email', data.email);
      if (data.name) prevSearchParams.set('name', data.name);
      prevSearchParams.set('pageCount', '1');
      return prevSearchParams;
    });
  };

  const closeFilterModal = () => setIsFilterModalOpen(false);
  const openFilterModal = () => setIsFilterModalOpen(true);

  const getCpaListColumnComponentMapper = () => {
    const columns = [];

    if (isCpaAssignmentSelected) {
      columns.push({
        label: '',
        render: (record: Record) => (
          <DSCheckBox
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(checked) => {
              if (checked) {
                setSelectedUsersToAck((prev) => [
                  record[BACKEND_RESPONSE_KEYS.FLYFIN_USER_ID],
                  ...prev,
                ]);
              } else {
                setSelectedUsersToAck((prev) =>
                  prev.filter(
                    (id) =>
                      id !== record[BACKEND_RESPONSE_KEYS.FLYFIN_USER_ID],
                  ),
                );
              }
            }}
            isChecked={selectedUsersToAck.includes(
              record[BACKEND_RESPONSE_KEYS.FLYFIN_USER_ID],
            )}
            disabled={isLoading || showCpaAckMessageDrawer}
          />
        ),
        backendResponseKey: null,
      });
    }

    const defaultColumns = [
      {
        label: CPA_LIST_COLUMNS.USER_ID,
        render: (record: Record) => (
          <NormalReadOnlyCell
            value={`${record[BACKEND_RESPONSE_KEYS.FLYFIN_USER_ID]}`}
          />
        ),
        backendResponseKey: BACKEND_RESPONSE_KEYS.FLYFIN_USER_ID,
      },
      {
        label: CPA_LIST_COLUMNS.NAME,
        render: (record: Record) => (
          <NormalReadOnlyCell
            value={record[BACKEND_RESPONSE_KEYS.FLYFIN_USER_NAME]}
          />
        ),
        backendResponseKey: BACKEND_RESPONSE_KEYS.FLYFIN_USER_NAME,
      },
      {
        label: CPA_LIST_COLUMNS.COMMS_MODE,
        render: (record: Record) =>
          record[BACKEND_RESPONSE_KEYS.COMMS_MODE] ? (
            record[BACKEND_RESPONSE_KEYS.COMMS_MODE] ===
            USER_COMMS_MODE.APP ? (
              <Smartphone />
            ) : (
              <Mail />
            )
          ) : null,
        backendResponseKey: BACKEND_RESPONSE_KEYS.COMMS_MODE,
      },
      {
        label: CPA_LIST_COLUMNS.ESIGN_MODE,
        render: (record: Record) =>
          record[BACKEND_RESPONSE_KEYS.ESIGN_MODE] ? (
            record[BACKEND_RESPONSE_KEYS.ESIGN_MODE] === ESIGN_MODE.APP ? (
              <Smartphone />
            ) : (
              <Mail />
            )
          ) : null,
        backendResponseKey: BACKEND_RESPONSE_KEYS.ESIGN_MODE,
      },
      {
        label: CPA_LIST_COLUMNS.REVIEWER,
        render: (record: Record) => (
          <CPAAssignCell
            fieldId={CPA_LIST_COLUMNS.REVIEWER}
            value={record[BACKEND_RESPONSE_KEYS.CPA_REVIEWER]}
            userId={record[BACKEND_RESPONSE_KEYS.FLYFIN_USER_ID]}
            fetchList={fetchUserList}
            isAssigned={
              ![null, VALID_CPA_EMAILS.Unassigned].includes(
                record[BACKEND_RESPONSE_KEYS.CPA_REVIEWER],
              )
            }
          />
        ),
        backendResponseKey: BACKEND_RESPONSE_KEYS.CPA_REVIEWER,
      },
      {
        label: CPA_LIST_COLUMNS.RETURN_STATUS,
        render: (record: Record) => (
          <UpdateReturnStatusCell
            currentReturnStatusId={record[BACKEND_RESPONSE_KEYS.STATUS]}
            taxFilingId={record[BACKEND_RESPONSE_KEYS.TAX_FILING_ID]}
            onSuccess={fetchUserList}
          />
        ),
        backendResponseKey: BACKEND_RESPONSE_KEYS.STATUS,
      },
      {
        label: CPA_LIST_COLUMNS.EXTENSION_STATUS,
        render: (record: Record) => (
          <NormalReadOnlyCell
            value={record[BACKEND_RESPONSE_KEYS.EXTENSION_STATUS] ?? ''}
          />
        ),
        backendResponseKey: BACKEND_RESPONSE_KEYS.EXTENSION_STATUS,
      },
      {
        label: CPA_LIST_COLUMNS.CPA,
        render: (record: Record) => {
          return (
            <CPAAssignCell
              fieldId={CPA_LIST_COLUMNS.CPA}
              value={record[BACKEND_RESPONSE_KEYS.CPA]}
              userId={record[BACKEND_RESPONSE_KEYS.FLYFIN_USER_ID]}
              fetchList={fetchUserList}
              isAssigned={
                ![null, VALID_CPA_EMAILS.Unassigned].includes(
                  record[BACKEND_RESPONSE_KEYS.CPA],
                )
              }
            />
          );
        },
        backendResponseKey: BACKEND_RESPONSE_KEYS.CPA,
      },
      {
        label: CPA_LIST_COLUMNS.PLANS,
        render: (record: Record) => (
          <NormalReadOnlyCell
            value={record[BACKEND_RESPONSE_KEYS.PAYMENT_PLAN]}
          />
        ),
        backendResponseKey: BACKEND_RESPONSE_KEYS.PAYMENT_PLAN,
      },
      {
        label: CPA_LIST_COLUMNS.CPA_ACTION,
        render: (record: Record) => {
          const action: ACTION_TYPES = record[BACKEND_RESPONSE_KEYS.CPA_ACTION]
            ? ACTION_TYPES.YES
            : ACTION_TYPES.NO;
          return <ActionCell value={action} />;
        },
        backendResponseKey: BACKEND_RESPONSE_KEYS.CPA_ACTION,
      },
      {
        label: CPA_LIST_COLUMNS.REVIEWER_ACTION,
        render: (record: Record) => {
          const action: ACTION_TYPES = record[
            BACKEND_RESPONSE_KEYS.REVIEWER_ACTION
          ]
            ? ACTION_TYPES.YES
            : ACTION_TYPES.NO;
          return <ActionCell value={action} />;
        },
        backendResponseKey: BACKEND_RESPONSE_KEYS.REVIEWER_ACTION,
      },
      {
        label: CPA_LIST_COLUMNS.DOCS_SUBMITTED,
        render: (record: Record) => (
          <NormalReadOnlyCell
            value={
              record[BACKEND_RESPONSE_KEYS.DOCS_SUBMITTED]
                ? getMonthDateFromDate(
                    getDateTimeFromMonthDayString(
                      record[BACKEND_RESPONSE_KEYS.DOCS_SUBMITTED],
                    ) as Date,
                  )
                : 'null'
            }
          />
        ),
        backendResponseKey: BACKEND_RESPONSE_KEYS.LAST_STATUS_CHANGE,
      },
      {
        label: CPA_LIST_COLUMNS.NOTE,
        render: (record: Record) => (
          <UpdateNoteCell
            value={record[BACKEND_RESPONSE_KEYS.NOTE]}
            whom={'Varsha'}
            timeStamp={1678350076}
            isEmpty={
              !record[BACKEND_RESPONSE_KEYS.NOTE] ||
              record[BACKEND_RESPONSE_KEYS.NOTE].length === 0
            }
            taxFilingId={record[BACKEND_RESPONSE_KEYS.TAX_FILING_ID]}
            onSuccess={fetchUserList}
          />
        ),
        backendResponseKey: BACKEND_RESPONSE_KEYS.NOTE,
      },
      {
        label: CPA_LIST_COLUMNS.ACTIVITY,
        render: (record: Record) => {
          return (
            <ActivityCell
              activityStatus={
                record[BACKEND_RESPONSE_KEYS.ACTIVITY] as USER_ACTIVITY_TYPE
              }
              taxFilingId={record[BACKEND_RESPONSE_KEYS.TAX_FILING_ID]}
              userId={record[BACKEND_RESPONSE_KEYS.FLYFIN_USER_ID]}
              fetchUserList={fetchUserList}
            />
          );
        },
        backendResponseKey: BACKEND_RESPONSE_KEYS.ACTIVITY,
      },
      {
        label: CPA_LIST_COLUMNS.TAX_QUERY_OPEN,
        render: (record: Record) => (
          <NormalReadOnlyCell
            value={`${record[BACKEND_RESPONSE_KEYS.TAX_QUERY_OPEN]}`}
            // value={`${true}`}
          />
        ),
        backendResponseKey: BACKEND_RESPONSE_KEYS.TAX_QUERY_OPEN,
      },
      // {
      //   label: CPA_LIST_COLUMNS.NEXT_ACTION_DATE,
      //   render: (record: Record) => {
      //     return (
      //       <NextActionDatePickerCell
      //         value={
      //           record[BACKEND_RESPONSE_KEYS.NEXT_ACTION_DATE] ??
      //           getDateForNextAction(new Date())
      //         }
      //         onSuccess={fetchUserList}
      //         taxFilingId={record[BACKEND_RESPONSE_KEYS.TAX_FILING_ID]}
      //         isValid={!!record[BACKEND_RESPONSE_KEYS.NEXT_ACTION_DATE]}
      //       />
      //     );
      //   },
      //   backendResponseKey: BACKEND_RESPONSE_KEYS.NEXT_ACTION_DATE,
      // },
      {
        label: CPA_LIST_COLUMNS.OLDEST_OPEN_QUERY_DATE,
        render: (record: Record) => (
          <NormalReadOnlyCell
            value={
              record[BACKEND_RESPONSE_KEYS.OLDEST_OPEN_QUERY_DATE]
                ? getMonthDateFromDate(
                    getDateTimeFromMonthDayString(
                      record[BACKEND_RESPONSE_KEYS.OLDEST_OPEN_QUERY_DATE],
                    ) as Date,
                  )
                : 'NA'
            }
          />
        ),
        backendResponseKey: BACKEND_RESPONSE_KEYS.LAST_STATUS_CHANGE,
      },
      {
        label: CPA_LIST_COLUMNS.OPEN_SEND_QUERT_STATS,
        render: (record: Record) => (
          <NormalReadOnlyCell
            value={record[BACKEND_RESPONSE_KEYS.OPEN_SEND_QUERT_STATS]}
          />
        ),
        backendResponseKey: BACKEND_RESPONSE_KEYS.LAST_STATUS_CHANGE,
      },
    ];

    columns.push(...defaultColumns);

    return columns;
  };

  const cpaListColumnComponentMapper = getCpaListColumnComponentMapper();

  const defaultPlansString = useMemo(
    () => JSON.stringify(defaultPlansKey),
    [defaultPlansKey],
  );

  const defaultSelectedReturnStatusString = useMemo(
    () => JSON.stringify(defaultSelectedReturnStatus),
    [defaultSelectedReturnStatus],
  );

  const defaultSelectedCpaActionsString = useMemo(
    () => JSON.stringify(defaultSelectedCpaActions),
    [defaultSelectedCpaActions],
  );
  const defaultSelectedReviewerActionsString = useMemo(
    () => JSON.stringify(defaultSelectedReviewerActions),
    [defaultSelectedReviewerActions],
  );
  const defaultSelectedCPAsString = useMemo(
    () => JSON.stringify(defaultSelectedCPAs),
    [defaultSelectedCPAs],
  );
  const defaultSelectedReviewersString = useMemo(
    () => JSON.stringify(defaultSelectedReviewers),
    [defaultSelectedReviewers],
  );
  const defaultSelectedDatesString = useMemo(
    () => JSON.stringify(defaultSelectedDates),
    [defaultSelectedDates],
  );

  const defaultSelectedTaxExtensionString = useMemo(
    () => JSON.stringify(defaultSelectedTaxExtension),
    [defaultSelectedTaxExtension],
  );

  const incrementPageCount = () => {
    // @ts-ignore
    setSearchParams((prev) => ({
      ...parse(prev.toString()),
      pageCount: pageCount + 1,
    }));
  };

  const decrementPageCount = () => {
    if (pageCount <= 1) return;
    // @ts-ignore
    setSearchParams((prev) => ({
      ...parse(prev.toString()),
      pageCount: pageCount - 1,
    }));
  };

  const goToFirst = () => {
    if (pageCount <= 1) return;
    // @ts-ignore
    setSearchParams((prev) => ({
      ...parse(prev.toString()),
      pageCount: 1,
    }));
  };

  const goToLast = () => {
    if (pageCount >= totalPages) return;
    // @ts-ignore
    setSearchParams((prev) => ({
      ...parse(prev.toString()),
      pageCount: totalPages,
    }));
  };

  const onClickSelect = () => {
    setIsCpaAssignmentSelected((prev) => !prev);
    setSelectedUsersToAck([]);
    setShowCpaAckMessageDrawer(false);
  };

  useEffect(() => {
    fetchUserList();
  }, [
    defaultReverse,
    defaultSortingKey,
    defaultPlansString,
    defaultSelectedReturnStatusString,
    defaultSelectedCpaActionsString,
    defaultSelectedReviewerActionsString,
    defaultSelectedCPAsString,
    defaultSelectedReviewersString,
    defaultSelectedDatesString,
    defaultSelectedTaxExtensionString,
    defaultUserId,
    defaultEmail,
    defaultName,
    pageCount,
    defaultSelectedActivity,
    defaultSelectedTaxQueryStatus,
    defaultSelectedCommsMode,
    defaultSelectedEsignMode,
  ]);

  return (
    <CpaListContext.Provider
      value={{
        fetchUserList,
      }}>
      <div>
        {/* FILTERS */}
        <FilterBar
          title={`ReturnCentral - RC`}
          disableSearchPanel={isLoading}
          onClearFilter={onClearFilter}
          onClickHamburger={openDrawer}
          onClickSearch={onClickSearchHandler}
          init={{
            userId: defaultUserId ?? '',
            email: defaultEmail ?? '',
            name: defaultName ?? '',
          }}>
          <ExportColumns getFilterParams={getFilterParams} />
          <Button
            onClick={openFilterModal}
            variant="outlined"
            disabled={isLoading}
            style={{
              color: themmeColor.grey,
              borderColor: themmeColor.grey,
              borderWidth: 1,
              marginLeft: 10,
            }}>
            Filters
          </Button>
          {/* CPA ACKNOWLEDGEMENT */}
          <DSButton
            onClick={onClickSelect}
            type={isCpaAssignmentSelected ? 'primary' : 'secondary'}
            disabled={isLoading}
            text={isCpaAssignmentSelected ? 'Cancel selection' : 'Select'}
            style={{marginLeft: 20}}
          />
        </FilterBar>
        {/* MAIN CONTENT */}
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '88vh',
            }}>
            <CircularProgress />
          </div>
        ) : (
          <TableContainer style={{maxHeight: '88vh'}}>
            <Table stickyHeader>
              <ListTableHeader tableData={cpaListColumnComponentMapper} />
              <ListTableBody
                isUserView
                recordsArray={recordsArray}
                tableData={cpaListColumnComponentMapper}
                onRowClick={({flyfin_user_id}) => flyfin_user_id.toString()}
              />
            </Table>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: isCpaAssignmentSelected
                  ? 'space-between'
                  : 'flex-end',
                alignItems: 'center',
                padding: '0px 40px',
              }}>
              {isCpaAssignmentSelected && (
                <DSButton
                  type="primary"
                  text="Send acknowledgement"
                  disabled={isLoading || selectedUsersToAck.length === 0}
                  onClick={() => setShowCpaAckMessageDrawer(true)}
                />
              )}
              {/* PAGINATION */}
              <Pagination
                isPrev={isPrev}
                isNext={isNext}
                isLoading={isLoading}
                pageCount={pageCount}
                totalPages={totalPages}
                onDecrementPage={decrementPageCount}
                onGotoFirstPage={goToFirst}
                onGotoLastPage={goToLast}
                onIncrementPage={incrementPageCount}
              />
            </div>
          </TableContainer>
        )}
      </div>
      {isFilterModalOpen && (
        <CpaListFilterModal
          isOpen={isFilterModalOpen}
          onClose={closeFilterModal}
          tableData={cpaListColumnComponentMapper}
        />
      )}
      {showCpaAckMessageDrawer && (
        <div
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 100,
          }}>
          <CpaAcknowledgementDrawer
            isVisible={showCpaAckMessageDrawer}
            selectedUsers={selectedUsersToAck}
            onClose={() => setShowCpaAckMessageDrawer(false)}
            onSendSideEffect={onClickSelect}
          />
        </div>
      )}
    </CpaListContext.Provider>
  );
};

export default CpaCenterList;
