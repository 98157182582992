import {
  Checkbox,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, {useContext} from 'react';
import {
  FIELD_TYPES,
  FormFieldType,
} from 'src/CpaCenterUserInfo/CpaCenterDocumentsOCR/CpaCenterDocumentOCR.utils';
import ViewDocumentContext from '../ViewDocument.context';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.constants';
import {useSelector} from 'react-redux';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import {ReduxStateType} from 'src/store/store';
import {
  FIELD_ID,
  TAX_PROFILE_FIELDS,
} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import {selectBusinessDetails} from 'src/store/businessDetails/businessDetails.selector';
import {INCOME_EARNER_VALUES} from 'src/CpaCenterV2/TaxProfileSections/Income/Income.constants';
import {FILING_STATUS_ANSWER} from 'src/CpaCenterV2/TaxProfileSections/TaxProfileSections.constants';
import {ALL_FORM_TYPES} from 'src/constants/constants';
import {FORM_FIELDS_TO_HIDE} from '../useViewDocument';

interface FormFieldProps {
  isImage: boolean;
  fieldKey: string;
  field: FormFieldType;
  formId: number;
  page: number | null;
  setForms: any;
  handleFormValueChange: (
    formId: number,
    valueIndex: number,
    fieldValueIndex: number,
    value: any,
    fieldKey: string,
  ) => void;
  formType: string;
  formData: {
    [key: string]: FormFieldType;
  };
}

const FormField: React.FC<FormFieldProps> = ({
  isImage,
  fieldKey,
  field,
  formId,
  page,
  setForms,
  handleFormValueChange,
  formType,
  formData,
}) => {
  const {drawPDFBBox, drawImageBBox} = useContext(ViewDocumentContext);
  const legalName = useSelector((state: ReduxStateType) => {
    return selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.LEGAL_NAME);
  });
  const filingStatus = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.FILING_STATUS),
  );
  const spouseLegalName = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.SPOUSE_LEGAL_NAME),
  );
  const {loaded, businesses} = useSelector(selectBusinessDetails);

  const onValueChange = (
    value: any,
    fieldValueIndex: number,
    valueIndex: number,
  ) => {
    if (fieldKey === TaxFormFieldIds.INCOME_EARNER) {
      const incomeEarnerValue = value.split('-')[0];
      const businessId = Number(value.split('-')[1] ?? -1);
      handleFormValueChange(
        formId,
        valueIndex,
        fieldValueIndex,
        incomeEarnerValue,
        TaxFormFieldIds.INCOME_EARNER,
      );
      if (businessId !== -1) {
        handleFormValueChange(
          formId,
          valueIndex,
          fieldValueIndex,
          businessId,
          TaxFormFieldIds.BUSINESS_ID,
        );
      }
      return;
    }
    handleFormValueChange(
      formId,
      valueIndex,
      fieldValueIndex,
      value,
      fieldKey,
    );
  };

  const getInput = (
    type: string,
    value: any,
    fieldValueIndex: number,
    fieldKey: string,
    formType: string,
    index: number = -1,
  ) => {
    switch (type) {
      case FIELD_TYPES.NUM_INPUT:
        return (
          <TextField
            size="small"
            onChange={(e) =>
              onValueChange(e.target.value, fieldValueIndex, index)
            }
            value={value}
            key={index}
            style={{background: 'white'}}
            type="number"
          />
        );
      case FIELD_TYPES.STR_INPUT:
        return (
          <TextField
            size="small"
            onChange={(e) =>
              onValueChange(e.target.value, fieldValueIndex, index)
            }
            multiline={true}
            minRows={1}
            style={{background: 'white'}}
            value={value}
            key={index}
          />
        );
      case FIELD_TYPES.DROPDOWN:
        let dropdownChoices: {label: string; value: string | null}[] = [];
        if (fieldKey === TaxFormFieldIds.INCOME_EARNER) {
          if (
            [
              ALL_FORM_TYPES.FORM_1099_MISC,
              ALL_FORM_TYPES.FORM_1099_NEC,
              ALL_FORM_TYPES.FORM_1099_K,
            ].includes(formType)
          ) {
            dropdownChoices = businesses.map((business) => ({
              label: business.name,
              value: `${INCOME_EARNER_VALUES.BUSINESS}-${business.id}`,
            }));
          } else {
            dropdownChoices = [
              {
                label: `${legalName?.[FIELD_ID.FIRST_NAME]} ${
                  legalName?.[FIELD_ID.LAST_NAME]
                }`,
                value: INCOME_EARNER_VALUES.MYSELF,
              },
            ];

            if (filingStatus === FILING_STATUS_ANSWER.MARRIED_FILING_JOINTLY) {
              dropdownChoices.push({
                label: `${spouseLegalName?.[FIELD_ID.FIRST_NAME]} ${
                  spouseLegalName?.[FIELD_ID.LAST_NAME]
                }`,
                value: INCOME_EARNER_VALUES.MY_SPOUSE,
              });
            }
          }
        } else {
          dropdownChoices = [
            {label: '', value: null},
            ...(field.dropdown_choices?.map((choice) => ({
              label: choice,
              value: choice,
            })) ?? []),
          ];
        }
        return (
          <Select
            style={{background: 'white'}}
            size="small"
            onChange={(e) => {
              onValueChange(e.target.value, fieldValueIndex, index);
            }}
            value={value}
            renderValue={(value) =>
              value === INCOME_EARNER_VALUES.BUSINESS
                ? businesses.find(
                    (business) =>
                      business.id ===
                      formData[TaxFormFieldIds.BUSINESS_ID].field_values[0]
                        .value,
                  )?.name
                : dropdownChoices.find((choice) => choice.value === value)
                    ?.label
            }
            key={index}
            MenuProps={{style: {maxHeight: '400px'}}}>
            {dropdownChoices.map((choice) => {
              return (
                <MenuItem value={choice.value} style={{height: 30}}>
                  {choice.label}
                </MenuItem>
              );
            })}
          </Select>
        );
      case FIELD_TYPES.CHECK_BOX:
        return (
          <Checkbox
            checked={value}
            onChange={(e) =>
              onValueChange(e.target.checked, fieldValueIndex, index)
            }
            key={index}
          />
        );
      case FIELD_TYPES.DATE:
        return (
          <TextField
            size="small"
            onChange={(e) =>
              onValueChange(e.target.value, fieldValueIndex, index)
            }
            style={{background: 'white'}}
            type="date"
            value={value}
            key={index}
          />
        );
      default:
        return (
          <TextField
            size="small"
            onChange={(e) =>
              onValueChange(e.target.value, fieldValueIndex, index)
            }
            style={{background: 'white'}}
          />
        );
    }
  };

  if (!loaded || FORM_FIELDS_TO_HIDE.includes(fieldKey)) {
    return null;
  }

  return (
    <div style={{marginBottom: '10px'}}>
      <Typography fontWeight={500} fontSize={13} style={{marginBottom: '5px'}}>
        {field.label}
      </Typography>
      <div>
        {field.field_values.map((fieldValue, fieldValueIndex) => {
          return (
            <div
              style={{display: 'flex', justifyContent: 'space-between'}}
              onClick={() =>
                isImage
                  ? drawImageBBox([
                      fieldValue.key_bounding_box,
                      fieldValue.value_bounding_box,
                    ])
                  : drawPDFBBox(page ?? 1, [
                      fieldValue.key_bounding_box,
                      fieldValue.value_bounding_box,
                    ])
              }>
              {Array.isArray(field.field_type)
                ? field.field_type.map((type, valueIndex) => {
                    return getInput(
                      type,
                      fieldValue.value[valueIndex],
                      fieldValueIndex,
                      fieldKey,
                      formType,
                      valueIndex,
                    );
                  })
                : getInput(
                    field.field_type,
                    fieldValue.value,
                    fieldValueIndex,
                    fieldKey,
                    formType,
                  )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FormField;
