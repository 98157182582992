import React from 'react';
import {Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {
  DOCUMENT_TYPES,
  themmeColor,
  VALID_DOCUMENT_STATUS,
} from 'src/constants/constants';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import ErrorIcon from 'src/icons/ErrorIcon';
import IgnoredIcon from 'src/icons/IgnoredIcon';
import Skeleton from 'src/icons/Skeleton';
import StatusPendingIcon from 'src/icons/StatusPendingIcon';
import TickIcon from 'src/icons/TickIcon';
import {ReduxDocumentType} from 'src/store/documents/documents.reducer';
import {selectDocuments} from 'src/store/documents/documents.selector';

const DocumentsList = () => {
  const {documents, loaded} = useSelector(selectDocuments);

  const documentCount = documents.length;

  const ocrDocuments = documents.filter((doc) => doc.ocrRequired);
  const nonOcrDocuments: any = documents.filter(
    (doc) => !doc.ocrRequired && doc.docType !== DOCUMENT_TYPES.OTHER,
  );
  const additionalDocuments = documents.filter(
    (doc) => doc.docType === DOCUMENT_TYPES.OTHER,
  );

  const getIcon = (doc: ReduxDocumentType) => {
    const {docStatus} = doc;
    switch (docStatus) {
      case VALID_DOCUMENT_STATUS.IGNORED:
        return <IgnoredIcon />;
      case VALID_DOCUMENT_STATUS.PENDING_FOR_REVIEW:
        return <StatusPendingIcon />;
      case VALID_DOCUMENT_STATUS.ACTION_REQUIRED:
        return <ErrorIcon />;
      case VALID_DOCUMENT_STATUS.ACCEPTED:
      case VALID_DOCUMENT_STATUS.REVIEWED_OCR_DATA:
        return <TickIcon />;
    }
    return <Skeleton height={20} width={20} />;
  };

  const sections = [
    {
      title: 'OCR documents',
      docsList: ocrDocuments,
    },
    {
      title: 'Non- OCR documents',
      docsList: nonOcrDocuments,
    },
    {
      title: 'Additional documents',
      docsList: additionalDocuments,
    },
  ];

  if (!loaded) {
    return (
      <div style={{marginTop: 20}}>
        <Skeleton width={100} height={24} />
        <Skeleton width={60} height={20} />
        <Skeleton width={'55vw'} height={56} />
        <Skeleton width={'55vw'} height={56} />
        <Skeleton width={'55vw'} height={56} />
        <Skeleton width={'55vw'} height={56} />
        <Skeleton width={'55vw'} height={56} />
      </div>
    );
  }

  return (
    <div style={{overflowY: 'auto', height: '100%'}}>
      <h1 style={{fontSize: 20}}>Document Review ({documentCount})</h1>
      <div>
        {sections.map(({title, docsList}) => {
          return (
            <div>
              <Typography
                style={{
                  color: themmeColor.black60,
                  fontWeight: 600,
                  fontSize: 16,
                  marginBottom: 8,
                }}>
                {title} ({docsList.length})
              </Typography>
              {docsList.map((doc: ReduxDocumentType) => {
                return (
                  <div style={{marginBottom: 8}}>
                    <DocumentPointer key={doc.docId} doc={doc} />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DocumentsList;
