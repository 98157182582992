import {useState} from 'react';
import {Button, CircularProgress, Modal} from '@mui/material';
import {themmeColor} from 'src/constants/constants';
import JSONPretty from 'react-json-pretty';
import {getQtcInputs} from 'src/appApi';

interface QtcInputsOverlayProps {
  onClose: () => void;
  isOpen: boolean;
  flyfinUserId: number;
  year: number;
}

const DEDUCTION_OPTIONS = {
  SCHEDULE_A: 'SCHEDULE_A',
  STANDARDIZE: 'STANDARDIZE',
} as const;

var JSONPrettyMon = require('react-json-pretty/dist/monikai');

const QtcInputsOverlay = ({
  onClose,
  isOpen,
  flyfinUserId,
  year,
}: QtcInputsOverlayProps) => {
  const [loading, setLoading] = useState(false);
  const [qtcInputData, setQtcInputData] = useState<any>(null);
  const [deductionOption, setDeductionOption] = useState<
    keyof typeof DEDUCTION_OPTIONS | null
  >(null);

  const fetchQtcInput = async (
    dedeductionOption: keyof typeof DEDUCTION_OPTIONS,
  ) => {
    try {
      setLoading(true);
      const response = await getQtcInputs({
        fly_user_id: flyfinUserId,
        year: year,
        deduction_option: dedeductionOption,
      });
      setQtcInputData(response.data);
      setDeductionOption(dedeductionOption);
    } catch (e) {
      alert(`Something went wrong ${e}`);
    } finally {
      setLoading(false);
    }
  };

  console.log('qtcInputData', qtcInputData);

  return (
    <Modal
      onClose={onClose}
      open={isOpen}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <div
        style={{
          backgroundColor: themmeColor.white,
          padding: 20,
          borderRadius: 12,
          width: '80%',
          height: '80%',
          overflow: 'scroll',
        }}>
        {!loading ? (
          <>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <Button
                onClick={() => fetchQtcInput(DEDUCTION_OPTIONS.SCHEDULE_A)}
                variant={
                  deductionOption === DEDUCTION_OPTIONS.SCHEDULE_A
                    ? 'contained'
                    : 'outlined'
                }>
                SCHEDULE A
              </Button>
              <Button
                onClick={() => fetchQtcInput(DEDUCTION_OPTIONS.STANDARDIZE)}
                variant={
                  deductionOption === DEDUCTION_OPTIONS.STANDARDIZE
                    ? 'contained'
                    : 'outlined'
                }>
                STANDARDIZED
              </Button>
            </div>
            <div>
              <JSONPretty
                id={`json-pretty-${flyfinUserId}`}
                data={qtcInputData ?? undefined}
                theme={JSONPrettyMon}
                style={{fontSize: 20}}
              />
            </div>
          </>
        ) : (
          <CircularProgress />
        )}
      </div>
    </Modal>
  );
};

export default QtcInputsOverlay;
