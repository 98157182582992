import {getScheduleWiseSummary} from 'src/appApi';
import {CPA_CENTER_ACTIVE_YEAR} from 'src/constants/constants';
import {SET_DEDUCTION_SUMMARY} from '../actionNames';
import {setAppState} from '../app/app.actions';
import {AppReducerStates, NotificationType} from '../app/app.reducer';
import {
  DeductionSummaryData,
  SchCCategoryWiseData,
} from './deductionSummary.reducer';

const setDeductionSummary = (payload: DeductionSummaryData) => {
  return {
    type: SET_DEDUCTION_SUMMARY,
    payload,
  };
};

export const fetchDeductionSummary = (userId: number) => {
  return async (dispatch: any) => {
    try {
      const {data} = await getScheduleWiseSummary({
        fly_user_id: userId,
        year: CPA_CENTER_ACTIVE_YEAR,
      });
      const sch_c_category_wise_data_map: Record<
        string,
        SchCCategoryWiseData
      > = {};
      data.sch_c_category_wise_data.forEach((item: SchCCategoryWiseData) => {
        sch_c_category_wise_data_map[item.category_display_name] = item;
      });
      dispatch(setDeductionSummary({...data as DeductionSummaryData, sch_c_category_wise_data_map}));
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to fetch deduction summary (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};
