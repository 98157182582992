import React from 'react';
import {
  ALL_FORM_TYPES,
  CPA_CENTER_ACTIVE_YEAR,
  TaxProfileSubsection,
  themmeColor,
} from 'src/constants/constants';
import SubSectionBox from '../../common/SubSectionBox';
import {Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import {TAX_PROFILE_FIELDS} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import SimpleKeyValueField from '../../common/SimpleKeyValueField';
import CommonDeductionTable from './CommonDeductionTable';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {selectDocuments} from 'src/store/documents/documents.selector';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.constants';
import {selectBusinessDetails} from 'src/store/businessDetails/businessDetails.selector';
import SingleBusinessDetail from '../../BasicDetails/components/BusinessDetails/components/SingleBusinessDetail';
import {
  TaxProfileSummaryVariant,
  useTaxProfileSummaryFilter,
} from '../../common/TaxProfileSummaryFilter/useTaxProfileSummaryFilter';

const BusinessLoans = () => {
  const {taxForms} = useSelector(selectTaxFormsReducer);
  const {documents} = useSelector(selectDocuments);

  const {businesses} = useSelector(selectBusinessDetails);
  const {summaryVariant} = useTaxProfileSummaryFilter();

  const businessLoanTF = taxForms.filter(
    (tf) => tf.formType === ALL_FORM_TYPES.BUSINESS_LOAN,
  );
  const tookBusinessLoan = useSelector((state) =>
    selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.TOOK_BUSINESS_LOAN),
  );

  return (
    <SubSectionBox
      name="Business loans"
      taxprofileSubsection={TaxProfileSubsection.BusinessLoans}>
      <div
        style={
          summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
            ? {}
            : {display: 'flex', flex: 1, justifyContent: 'space-between'}
        }>
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {}
              : {flex: 0.48}
          }>
          <SimpleKeyValueField
            name={`Did you take any loans for your business in ${CPA_CENTER_ACTIVE_YEAR}?`}
            value={tookBusinessLoan}
          />
          {businessLoanTF.map((tf, index) => {
            const relatedBusinessEntitiy = businesses.find(
              (business) =>
                business.id === tf.formData[TaxFormFieldIds.BUSINESS_ID],
            );
            return (
              <>
                <SimpleKeyValueField
                  name="This loan belongs to which business?"
                  value={relatedBusinessEntitiy?.name}
                />
                {relatedBusinessEntitiy && (
                  <SingleBusinessDetail
                    business={relatedBusinessEntitiy}
                    index={index}
                  />
                )}
                <Typography
                  style={{
                    fontSize: 11,
                    color: themmeColor.black60,
                    marginBottom: 4,
                  }}>
                  Related document
                </Typography>
                <DocumentPointer
                  doc={documents.find((doc) => doc.docId === tf.docId)}
                  taxform={tf}
                  dontShowStatus
                  border
                />
              </>
            );
          })}
        </div>
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {marginTop: 20}
              : {flex: 0.48}
          }>
          <CommonDeductionTable
            taxProfileSubsection={TaxProfileSubsection.BusinessLoans}
          />
        </div>
      </div>
    </SubSectionBox>
  );
};

export default BusinessLoans;
